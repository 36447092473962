import { NounInternal } from "habor-sdk";
import * as React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';

interface TestViewProps extends PrimitiveProps {
  userProps: {
    nouns: NounInternal[], propTest: string
  }
}

//  NOTE:  EVERY Habor Primitive must accept a "config" prop, which is used internally to pass config info, INCLUDING an onPress event handler.  So, even if the component DOESN'T respond to a press event, it should be wrapped in a container that can for the editor.
export const TestView = ({ userProps: { nouns, propTest }, frameworkProps: { children, config, componentId } }: TestViewProps) => {
  return (
    <TouchableOpacity onPress={ config.editMode ? () => config.onPress(componentId) : () => null }>
      <Text>{ JSON.stringify(nouns) }</Text>
      <Text>{ propTest }</Text>
      {[ children ]}
    </TouchableOpacity>
  );
};
