import { Color } from 'habor-sdk';
import * as React from 'react';
import { View } from "react-native";
import { medSpacer, kelpStyles } from "./styles";

export interface ContainerProps extends React.Props<any> {
  [key: string]: any;
}
export const Container = (props: ContainerProps) => (
  <View { ...props } style={[ { padding: medSpacer, backgroundColor: Color.white }, props.style ]}>
    { props.children }
  </View>
);

export const Page = (props: ContainerProps) => (
  <View { ...props } style={[ kelpStyles.flexColumn, kelpStyles.fullSize, { flex: 1 }, props.style ]}>
    { props.children }
  </View>
);
