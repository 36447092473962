import { Platform, StyleSheet, ViewStyle } from 'react-native';

//
//  Style Constants
//

//  iPhone 11 Styles
//  REFERENCE:  https://ivomynttinen.com/blog/ios-design-guidelines
//  TODO:  Customize these styles for several iPhones?
export const statusBarHeight = Platform.OS == 'ios' ? 44 : 0;
export const headerHeight = 60;
export const layourMargin = 16;
export const tabBarHeight = 49;
export const homeIndicator = 34;

//  Spacers
export const extraSmallSpacer = 5;
export const smallSpacer = 10;
export const medSpacer = 16;
export const largeSpacer = 30;
export const extraLargeSpacer = 50;

export const defaultBorderRadius = 5;

//  Text
export const primaryFontFamily = 'Inter-SemiBold';
export const primaryFontFamilyHeavy = 'Inter-Bold';
export const primaryFontFamilyExtraHeavy = 'Inter-ExtraBold';

//
//  StyleSheet
//
export const kelpStyles = StyleSheet.create({

  listElement: {
    marginBottom: medSpacer
  },

  //  Style Attributes
  rounded: {
    borderRadius: defaultBorderRadius
  },
  roundedBottom: {
    borderBottomRightRadius: defaultBorderRadius,
    borderBottomLeftRadius: defaultBorderRadius
  },
  roundedTop: {
    borderTopRightRadius: defaultBorderRadius,
    borderTopLeftRadius: defaultBorderRadius
  },

  //  Shadows
  shadowLow: {
    shadowColor: 'black',
    shadowRadius: 5,
    shadowOpacity: 0.03,
    shadowOffset: {
      width: 2,
      height: 2
    },
  },
  shadowMed: {
    shadowColor: 'black',
    shadowRadius: 10,
    shadowOpacity: 0.04,
    shadowOffset: {
      width: 2,
      height: 2
    },
  },
  shadowHi: {
    shadowColor: 'black',
    shadowRadius: 7,
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 2,
      height: 2
    },
  },

  //  Flex Elements
  growConstant: {
    flexBasis: 0,
    flexGrow: 1
  },
  grow: {
    flex: 1
  },

  //  Flex Containers
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  centerItems: {
    alignItems: 'center',
    justifyContent: 'center'
  },

  //  Size
  fullSize: {
    width: '100%',
    height: '100%'
  }
});

//
//  Style Mixins
//

//  Raised Height
export enum RaisedHeight {
  none, low, med, high
}

export interface RaisedProps {
  raisedHeight?: RaisedHeight;
}

const heightToStyle = {
  [RaisedHeight.none]: {},
  [RaisedHeight.low]: kelpStyles.shadowLow,
  [RaisedHeight.med]: kelpStyles.shadowMed,
  [RaisedHeight.high]: kelpStyles.shadowHi
};

export const getRaisedStyle = (props: RaisedProps) => {
  const { raisedHeight = RaisedHeight.none } = props;
  const style = heightToStyle[raisedHeight];
  if (!style) { throw new Error(`The specified z-height (${raisedHeight}) is invalid.`); }
  return style as ViewStyle;
};

//  TODO:  Consider creating a function for aggregate all style mixins and then apply these to the style on the first React component.
