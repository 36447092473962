import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Davel from 'davel';
import { CallStack, HFunction, HFunctionContext, HParamMap, HPrimitiveFunction, HPrimitiveParam, HPrimitiveParamMap, HRequestContext, HStatement, HUserFunction, HVariableParam } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { FlatList, ScrollView, Text, TextInput, TouchableOpacity, View } from "react-native";
import { ExtensibleFunction } from "../../../packages/tunic/tunic";
import { Hessia2Context, Hessia2Plugin, System2 } from "../hessia2-plugin";
import { HSDTUserFunctionParser } from "./function-type";
import { HComparatorFunction } from "./primitive-functions/general-functions/comparator";
import { HIfFunction } from "./primitive-functions/general-functions/conditional";
import { HHelloWorldFunction } from "./primitive-functions/general-functions/hello-world";
import { HCombineFunction } from "./primitive-functions/general-functions/map";
import { HSumFunction } from "./primitive-functions/general-functions/sum";
import { HTestFunction } from "./primitive-functions/general-functions/test";
import { Icon } from "react-native-elements";
import { SystemHeader } from "../../../packages/kelp-bar/system-header";
import { GroupCard } from "../../../packages/kelp-bar/group-card";
import { Entity2Plugin, EntityContext, EntityPluginContext, EntityTable } from "../entity-plugin";
import { HUserFunctionSchema } from "./function-model";
import { Primitive2Plugin, PrimitivePluginContext } from "../object-system";
import { Entity } from "../entity-service";
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
const uuidv4 = require("uuid/v4");

export const FunctionPluginContext = React.createContext<FunctionPlugin | undefined>(undefined);

const ComposerNav = () => {
  const Nav = createStackNavigator();
  const navigation = useNavigation();
  return (
    <Nav.Navigator initialRouteName="List">
      <Nav.Screen
        name="Composer"
        options={{ headerShown: false }}
        component={({ route }) => {

          //  TODO:  Save Function
          //  TODO:  Save Button
          //  TODO:  Show as ENTITY List
          //  TODO:  CUSTOMIZE the Entity List with a CUSTOM View for this type
          //      NOTE:  Do it as a param first, but ALSO consider this CONTEXT.  Use RULES (which are JUST encodings) to determine how to display

          const functionPlugin = React.useContext(FunctionPluginContext);
          const entity = React.useContext(EntityPluginContext);
          const entityContext = React.useContext(EntityContext);
          const primitive = React.useContext(PrimitivePluginContext);
          const [functions, setFunctions] = React.useState([]);
          const [activeEntity, setEntity] = React.useState<NounServiceInstanceInternal<Entity> | undefined>(undefined);
          const [activeFunction, setActiveFunction] = React.useState<HUserFunction | undefined>(undefined);

          const entities = entityContext.entities;

          const userFunctions = entities.filter(entity => {
            if (!functionPlugin) { alert("Missing Function Plugin"); return; }
            return primitive?.hasReferenceType(entity, functionPlugin?.userFunctionModelId)
          });

          //  CONSIDER:  A FUNCTION like this is BASICALLY a TEMPLATE.  It's LIMITING to be able to have a Register and inject a certain thing... we want to be able to make modifications in a more natural way.  How?  Give a more accessible interface like "left of", "next to", and other things WOULD help... above, etc... this is a predicate extension system for this mm!!!  BUT it MAY still be limiting compared to usign AI to make a BEST representaiton copy etc mm!  But FASTER.
          const FunctionRepresentation = ({ func }: { func: HFunction }) => {
            return <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#eeeeee', borderRadius: 10 }}>
              <Text>{func.name}</Text>
              <View style={{ flex: 1 }} />

              {/* Test Function */}
              <Icon name="play" type="feather" onPress={() => functionPlugin?.invokeFunction(func, undefined, undefined, [])} />

              {/* Insert Function */}
              {
                activeFunction && (
                  <Icon name="plus" type="feather" onPress={() => setActiveFunction({ ...activeFunction, definition: [...activeFunction.definition, { functionName: func.name }] })} />
                )
              }
            </View>
          }


          //  NOTE:  We ALREADY have an "Entity Editor" screen, and we CAN use that to create this thing!  The POINT is, this is a CUSTOM view we can register for it.

          const loadFunction = async (entity: NounServiceInstanceInternal<Entity<HUserFunction>>) => {
            setEntity(entity);
            const value = await primitive?.getValue<HUserFunction>(entity.payload);
            if (value) {
              setActiveFunction(value);
            }
          }
          const saveFunction = async () => {
            //  CONSIDER:  SHOULD be able to set rules around what's required for a function declaratively, like the fact that this depends on "FunctionPlugin".  
            if (!functionPlugin) { console.log("Missing FunctionPlugin."); return; }
            if (!activeEntity) { alert("No Active Entity"); return; }
            await primitive?.attachObject(activeEntity, { type: 'reference', entityId: functionPlugin?.userFunctionModelId! }, activeFunction);
            entityContext.loadEntities();
          }

          const deleteFunction = async () => {
            //  CONSIDER:  SHOULD be able to set rules around what's required for a function declaratively, like the fact that this depends on "FunctionPlugin".  
            if (!functionPlugin) { console.log("Missing FunctionPlugin."); return; }
            if (!activeEntity) { alert("No Active Entity"); return; }
            await entity?.entityService.entityNounService.delete(activeEntity.id);
            setActiveFunction(undefined);
            setEntity(undefined);
            entityContext.loadEntities();
          }

          const createFunction = async () => {
            await primitive?.createObject({ id: uuidv4(), owners: [FunctionSystem], name: "New Function" }, { type: 'reference', entityId: functionPlugin?.userFunctionModelId! }, { type: "user", definition: [], name: "My Function", returns: { type: "keyword" } } as HUserFunction);
            entityContext.loadEntities();
          }

          const updateName = (_name: string) => {
            if (!activeEntity) { alert("No Active Entity"); return; }
            setEntity({ ...activeEntity, payload: { ...activeEntity.payload, name: _name } });
          }

          return <View style={{ flex: 1, backgroundColor: 'white' }}>
            <SystemHeader system={FunctionSystem}>
              <Icon name="plus" type="feather" onPress={() => createFunction()} />
            </SystemHeader>

            <View style={{ padding: 30, flexDirection: 'row', flex: 1 }}>

              {/* User Function Library */}
              <View style={{ flex: 1, borderRightWidth: 2, borderRightColor: '#eeeeee' }}>
                <Text>User Functions</Text>
                <EntityTable entities={userFunctions} onPress={loadFunction} />
              </View>

              {/* Function Builder */}
              <ScrollView style={{ flex: 3 }}>
                <Text>This is a function editor that we'll use to MERGE the work done in Habor Functions, HComponents, Chaining, and Compose (Blocks) to a cohesive whole.  For exampole, Blocks are JUST an example of a FUNCTION which is interpreted each time an event occurs by a FRAMEWORK.</Text>

                {/* NOTE:  Think of this like building a rube-goldberg machine! */}
                {
                  activeEntity && activeFunction && (
                    <GroupCard>
                      <Text>Make a Function</Text>
                      <Text>Name</Text>
                      <TextInput placeholder="Name" onChangeText={updateName} />

                      <Text>Statements</Text>
                      <>
                        <FlatList data={activeFunction.definition} renderItem={({ item }) => <Text>{item.functionName}</Text>} />
                      </>

                      <Icon name="play" type="feather" onPress={() => functionPlugin?.invokeFunction(activeFunction, undefined, undefined, [])} />
                      <Icon name="save" type="feather" onPress={() => saveFunction()} />
                      <Icon name="trash" type="feather" onPress={() => deleteFunction()} />
                    </GroupCard>
                  )
                }


                {/* Primitive Function Library */}
                {/* NOTE:  We SHOULD be able to inject these as "Entities" and use the SAME explorer we use for everything!!! */}
                <GroupCard>
                  <Text>Primitive Functions</Text>
                  <FlatList
                    data={Object.keys(functionPlugin?.primitiveFunctions || []).map(key => functionPlugin?.primitiveFunctions[key])}
                    renderItem={({ item }) => (
                      item ?
                        <FunctionRepresentation func={item.func} /> :
                        <Text>Missing Function</Text>

                    )}
                  />
                  <Text>{JSON.stringify(Object.keys(functionPlugin?.primitiveFunctions || {}))}</Text>
                </GroupCard>
              </ScrollView>






            </View>
          </View>
        }}
      />
      {/* <Nav.Screen name="List" component={() => <InstanceList headerButtons={() => <RoundIconButton
        onPress={() => { navigation.navigate("Composer" as never) }}
        iconSelection={{ name: 'plus', type: 'font-awesome' }}
        backgroundColor="#25ced2"
        iconColor='white'
      />} onItemPressed={({ instance }) => { navigation.navigate("Composer" as never, { block: instance } as never) }} nounId={{ nounId: serializedUserBlockNoun.id, type: EntityType.Noun }} />} /> */}
    </Nav.Navigator>
  );
}

const FunctionSystem: System2 = {
  id: "composer2-system",
  name: "Functions",
  description: "Composer Primitive System",
  component: ComposerNav,
  emoji: "",
  icon: { name: "list", type: "feather" },
  backgroundColor: 'blue',
  primaryColor: 'black',
  color: "black"
};


export class FunctionPlugin extends CorePluginClass {

  public invokeFunctionTunic!: ExtensibleFunction<{ func: any, params: any, context?: HRequestContext }, any>;

  public userFunctions: { [name: string]: HUserFunction } = {};
  public primitiveFunctions: { [name: string]: { func: HPrimitiveFunction, imports: any } } = {};

  public static details = {
    name: "Function Plugin",
    description: "Handles Habor Function evaluation.",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id, Primitive2Plugin.details.id] as string[],
    id: "functions"
  };

  public userFunctionModelId = "user-function-model";

  public install = async (program: Program, { hessia2, entity2, primitive2 }: { hessia2: Hessia2Plugin, entity2: Entity2Plugin, primitive2: Primitive2Plugin }) => {

    //  Primitive Functions
    this.registerPrimitiveFunction("test", HTestFunction, {});
    this.registerPrimitiveFunction("if", HIfFunction, {});
    this.registerPrimitiveFunction("compare", HComparatorFunction, {});
    this.registerPrimitiveFunction("hello_world", HHelloWorldFunction, {});
    this.registerPrimitiveFunction("sum", HSumFunction, {});
    this.registerPrimitiveFunction("combine", HCombineFunction, {});

    //  Build the User Function Model (Register the SDTObject)
    //  NOTE:  We do NOT need a new Davel Type for this!  It's going to be stored as an "Object".
    const userFunctionModelEntity = await entity2.entityService.getEntityById(this.userFunctionModelId);
    if (!userFunctionModelEntity) {
      //  TODO:  Instead of console logs, use a meta-model to log these as entities that we can browse and attach to!!!
      console.log("Creating the User Function Model")
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = HUserFunctionSchema;
      const entity = { id: this.userFunctionModelId, name: "User Function", description: "User Function Schema", owners: [FunctionSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("The User Function Model Already Exists");
    }

    this.invokeFunctionTunic = new ExtensibleFunction(async ({ func, params, context }) => {
      const imports = this.primitiveFunctions[func.name].imports;
      const res = await func.definition({ params, imports, context });
      return res;
    });


    hessia2.registerHOC(({ children }) => {

      const hessiaContext = React.useContext(Hessia2Context);

      React.useEffect(() => {
        hessiaContext.installSystem(FunctionSystem)
      }, []);

      return (
        <FunctionPluginContext.Provider value={this}>
          {children}
        </FunctionPluginContext.Provider>
      )
    });

    //  Register Davel Types
    //  TODO:  Update Davel to take this as a GENERIC parameter so we can avoid type casting here.
    Davel.registerSDTDeserializer("user-function", HSDTUserFunctionParser as Davel.SDTDeserializer);

    return this;
  };

  /**
   * Converts "Variable" param objects to primitive param objects at this level.
   */
  private async realizeParams(params: HParamMap, context: HFunctionContext): Promise<HPrimitiveParamMap | undefined> {

    //  Resolve the Params
    if (!params) { return undefined; }
    const resolvedParams: HPrimitiveParamMap = {};
    const paramNames = Object.keys(params);
    for (let i = 0; i < paramNames.length; i++) {
      const paramName = paramNames[i];
      const param: HPrimitiveParam | HVariableParam = params[paramName];
      if (param.type === "variable") {
        //  Resolve the Variable in the current context
        const { varName } = param;
        const value = context.variables[varName];
        //  NOTE:  Commented below, because sometimes vars are intentionally undefined.
        // if (value == undefined) { throw new Error(`Attempted to access undefined variable '${varName}'`); }
        resolvedParams[paramName] = { type: "primitive", value };
      } else if (param.type === "primitive") {
        resolvedParams[paramName] = param;
      } else {
        //  NOTE:  TS type guard knows that 'param' is not of either HVariableParam or HPrimitiveParam.
        //  REFERENCE:  https://github.com/Microsoft/TypeScript/issues/10934
        throw new Error(`Unknown variable type specified`);
      }
    }
    return resolvedParams;
  }

  public async registerPrimitiveFunction(funcName: string, func: HPrimitiveFunction, imports: any): Promise<void> {

    //  Check if a function exists by the same name
    const existingFunc = await this.getFunction(funcName);
    if (existingFunc) { throw new Error(`Function ${funcName} already exists.`); }

    //  Register the function
    this.primitiveFunctions[funcName] = { func, imports };
  }

  private async registerUserFunction(funcName: string, func: HUserFunction): Promise<void> {

    //  Check if a function exists by the same name
    const existingFunc = await this.getFunction(funcName);
    if (existingFunc) { throw new Error(`Function '${funcName}' already exists.`); }

    //  Register the function
    this.userFunctions[funcName] = func;
  }

  //  NOTE:  This is for globally scoped functions.
  public async getFunction(funcName: string): Promise<HFunction | undefined> {

    //  Try the Primitive Functions.
    const primitiveFunc = await this.getPrimitiveFunction(funcName);
    if (primitiveFunc) { return primitiveFunc.func; }

    //  Try the User Functions.
    const userFunc = await this.getUserFunction(funcName);
    if (userFunc) { return userFunc; }

    //  Return undefined because the function is not registered
    return undefined;
  }

  private async getUserFunction(funcName: string) {
    const func = this.userFunctions[funcName];
    return func;
  }

  private async getPrimitiveFunction(funcName: string) {
    const func = this.primitiveFunctions[funcName];
    return func;
  }

  public async invokeFunction(func: HFunction, params: HPrimitiveParamMap = {}, reqContext?: HRequestContext, callStack: CallStack = []) {

    //  Unpack Func Args
    const { name, type } = func;

    //  Convert the Primitive Params to Variables
    const variables: any = this.convertPrimitiveParamsToVariables(params);

    //  Create a new Context for this Function
    const currentContext = { functionName: name, variables };
    callStack.push(currentContext);

    //  Invoke the function
    let res;
    if (type === "primitive") {
      res = await this.invokePrimitiveFunction(func as any, params, reqContext);
    } else if (type === "user") {
      res = await this.invokeUserFunction(func as any, reqContext, callStack);
    } else {
      throw new Error("Cannot invoke unknown function type.");
    }

    callStack.pop();
    return res;
  }

  private async invokePrimitiveFunction(func: HPrimitiveFunction, params: HPrimitiveParamMap = {}, reqContext?: HRequestContext) {

    if (func == undefined) {
      throw new Error("Cannot invoke an undefined function.");
    }

    //  Convert the Primitive Params to Variables
    const input: { [name: string]: any } = {};
    Object.keys(params).forEach((key: string) => {
      const param = params[key];
      input[key] = param.value;
    });

    const res = await this.invokeFunctionTunic.invoke({ func, params: input, context: reqContext });

    return res;
  }

  private async invokeUserFunction(func: HUserFunction, reqContext?: HRequestContext, callStack: CallStack = []): Promise<any> {

    //  Get the Statements
    const statements = func.definition;

    //  Get the Current Context
    const context = callStack[callStack.length - 1];

    //  Process each Statement
    let res;
    for (let i = 0; i < statements.length; i++) {
      const statement = statements[i];

      //  Unpack Params
      const { params = {}, functionName } = statement;

      //  Realize the Params
      //  NOTE:  This creates a POJO from the Params (which may refer to variables in the current context)
      const realizedParams = await this.realizeParams(params, context);

      //  Get the function
      const globalFunc = await this.getFunction(functionName);
      if (globalFunc == undefined) {
        throw new Error(`Unregistered Function: '${functionName}'.`);
      }

      //  Invoke the Function
      res = await this.invokeFunction(globalFunc, realizedParams, reqContext, callStack);

      //  Check Variables
      if (statement.assign) {
        const { name, constant = false } = statement.assign;
        const existingVariable = context.variables[name];
        if (existingVariable) {
          if (existingVariable.constant) {
            throw new Error(`Cannot redefine constant variable '${name}'.`);
          }
        }
        context.variables[name] = res;
      }

      //  Check Return
      if (statement.return) {
        return res;
      }
    }

    //  NOTE:  By default the value of the last statement is returned.
    return res;
  }

  private convertPrimitiveParamsToVariables(params: HPrimitiveParamMap = {}) {
    const variables: { [key: string]: any } = {};
    Object.keys(params).forEach((key: string) => {
      const param = params[key];
      variables[key] = param.value;
    });
    return variables;
  }
}
