import * as React from 'react';
import { ViewStyle } from 'react-native';
import { Icon } from 'react-native-elements';
import { IconType } from 'react-native-elements/dist/icons/Icon';
// import { performAction } from '../habor-component-core';
import { ActionSelection, HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';

//
//  HaborIconButton Component
//

export interface HaborIconButtonProps extends PrimitiveProps {
  userProps: {
    name: string;
    type: IconType;
    actionSelection: ActionSelection;
    style: ViewStyle;
  }
}
export interface HaborIconButtonState {}
export class HaborIconButtonPrimitive extends React.Component<HaborIconButtonProps, HaborIconButtonState> {
  public render() {
    const { userProps: { style, type, name, actionSelection }, frameworkProps: { context } } = this.props;
    // performAction(actionSelection, context)
    const handlePress = () => alert("pressed");
    return (
      <HaborContainer frameworkProps={ this.props.frameworkProps } onPress={ handlePress } style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', ...style }}>
        <Icon name={ name } type={ type } />
      </HaborContainer>
    );
  }
}
