import { CorePluginClass } from "halia";
import React, { useState } from "react";
import { Modal, Text, View } from "react-native";
import { ICorePlugin } from "./core-plugin";
import { Edge, Entity, EntityTable, Edge2Plugin } from "./edge2-plugin";
import { Type2Plugin } from "./type-system";
import { GroupCard } from "../../packages/kelp-bar/group-card";
import { IconButton } from "react-native-paper";
import { NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
const uuid = require('uuid/v4');

const OWNEDBY_EDGETYPE_NODE_ID = "ownedBy_EdgeType";
const OWNEDBY_EDGETYPE_TYPEDEF_EDGE_ID = "ownedBy_EdgeType_TypeDef";

export function OwnershipMixin<T extends new (...args: any[]) => CorePluginClass>(Base: T) {

  return class OwnerMixin extends Base implements ICorePlugin {

    public mixinInstallers!: any[];
    public graph!: Edge2Plugin;
    public type!: Type2Plugin;

    constructor(...args: any[]) {
      super(...args);
      this.mixinInstallers.push(this.installOwnership)
    }

    public registerOwner = async (childId, ownerId, id?: string) => {
      await this.graph.createEdge({ id: id || uuid(), src: childId, type: OWNEDBY_EDGETYPE_NODE_ID, dest: ownerId, name: "Owned By", description: "Owner Relationship", entityType: "edge", emoji: '🏠' })
    }
    public removeOwner = async (childId, ownerId, id?: string) => {
      const allEntities = await this.graph.graphService.retrieveAll();
      const edges = allEntities.filter(entity => entity.payload.entityType === 'edge') as NounServiceInstanceInternal<Edge>[];
      const typeOfEdges = edges.filter(edge => (edge.payload.src === childId) && (edge.payload.type === OWNEDBY_EDGETYPE_NODE_ID) && (edge.payload.dest === ownerId));
      for (const edge of typeOfEdges) {
        await this.graph.graphService.delete(edge.id);
      }
    }

    public getOwners = async (entity: Entity) => {
      const allEntities = await this.graph.graphService.retrieveAll();
      const edges = allEntities.filter(entity => entity.payload.entityType === 'edge').map(entity => entity.payload) as Edge[];
      const ownerIds = edges.filter(edge => (edge.src === entity.id) && edge.type === OWNEDBY_EDGETYPE_NODE_ID).map(edge => edge.dest);
      const owners = allEntities.filter(entity => ownerIds.includes(entity.payload.id)).map(owner => owner.payload);
      return owners;
    }

    public getOwned = async (entity: Entity) => {
      const allEntities = await this.graph.graphService.retrieveAll();
      const edges = allEntities.filter(entity => entity.payload.entityType === 'edge').map(entity => entity.payload) as Edge[];
      const ownedIds = edges.filter(edge => (edge.dest === entity.id) && edge.type === OWNEDBY_EDGETYPE_NODE_ID).map(edge => edge.src);
      const owned = allEntities.filter(entity => ownedIds.includes(entity.payload.id)).map(owned => owned.payload);
      return owned;
    }

    public installOwnership = async () => {

      const ownedByEdgeType = await this.graph.getNodeById(OWNEDBY_EDGETYPE_NODE_ID);
      if (!ownedByEdgeType) {
        await this.graph.createNode({ id: OWNEDBY_EDGETYPE_NODE_ID, entityType: "node", name: "Owned By", description: "Indicates that the source node is 'owned' by the destination node.", emoji: "⤵️" });
        await this.type.registerEdgeType(OWNEDBY_EDGETYPE_NODE_ID, OWNEDBY_EDGETYPE_TYPEDEF_EDGE_ID);
      }
      this.graph.registerEntityExtension({
        id: "owner-entity-extension",
        name: "Ownershp Extension",
        description: "Displays Ownership Information",
        DetailComponent: ({ entity }) => {

          const [ownedList, setOwnedList] = React.useState<Entity[]>([]);
          const [ownerList, setOwnerList] = React.useState<Entity[]>([]);

          const [ownerSelector, setOwnerSelector] = useState(false);
          const [childSelector, setChildSelector] = useState(false);
          const [allEntities, setAllEntities] = React.useState<Entity[]>([]);

          const loadOwnership = async () => {
            const _allEntities = await this.graph.graphService.retrieveAll();
            const _owned = await this.getOwned(entity);
            const _owners = await this.getOwners(entity);
            setAllEntities(_allEntities.map(entity => entity.payload));
            setOwnedList(_owned);
            setOwnerList(_owners);
          }

          React.useEffect(() => {
            loadOwnership();
          }, []);


          const addOwner = async (typeEntity: Entity) => {
            await this.registerOwner(entity.id, typeEntity.id);
            await loadOwnership();
            setOwnerSelector(false);
          }

          const addChild = async (childEntity: Entity) => {
            await this.registerOwner(childEntity.id, entity.id);
            await loadOwnership();
            setChildSelector(false);
          }

          const removeOwner = async (childEntity: Entity) => {
            await this.removeOwner(childEntity.id, entity.id);
            await loadOwnership();
          }

          const removeChild = async (ownerEntity: Entity) => {
            await this.removeOwner(entity.id, ownerEntity.id);
            await loadOwnership();
          }

          return (
            <>
              <GroupCard>
                <Modal visible={ownerSelector}>
                  <EntityTable onPress={addOwner} entities={allEntities} />
                </Modal>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333", flex: 1 }}>Owners</Text>
                  <IconButton
                    icon='plus'
                    onPress={() => setOwnerSelector(true)}
                    style={{
                      backgroundColor: '#F5F5F5',
                      marginHorizontal: 4,
                      borderRadius: 8
                    }}
                    iconColor="#555555"
                    size={20}
                  />
                </View>
                <EntityTable entities={ownerList} />
              </GroupCard>

              <View style={{ height: 20 }} />

              <GroupCard>
                <Modal visible={childSelector}>
                  <EntityTable onPress={addChild} entities={allEntities} />
                </Modal>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333", flex: 1 }}>Children</Text>
                  <IconButton
                    icon='plus'
                    onPress={() => setChildSelector(true)}
                    style={{
                      backgroundColor: '#F5F5F5',
                      marginHorizontal: 4,
                      borderRadius: 8
                    }}
                    iconColor="#555555"
                    size={20}
                  />
                </View>
                <EntityTable onDelete={removeChild} entities={ownedList} />
              </GroupCard>

            </>
          );
        }
      })
    }
  }
}