import { Color } from 'habor-sdk';
import { History } from 'history';
import * as React from 'react';
import { ImageBackground, StatusBar, Text, TouchableOpacity, View } from "react-native";
import { Icon } from 'react-native-elements';
import { getRaisedStyle, medSpacer, RaisedHeight, kelpStyles } from "./styles";

interface HeaderProps extends React.Props<any> {
  color?: string;
  title: string;
  [ key: string ]: any;
  // showMenu?: any;
  showRightButton?: boolean;
  onRightButtonPress?: any;
  rightIconName?: string;
  rightIconType?: string;
  showBackButton?: boolean;
  history?: History;
}

export const Header = (props: HeaderProps) => (
  // NOTE:  High zIndex so drop shadow shows above the underlying components.
  <View style={{ zIndex: 5000 }}>
    <StatusBar barStyle="light-content" />
    {/* <ImageBackground { ...props } imageStyle={{ resizeMode: "center" }} source={ require("../../assets/Header.png") } style={[ styles.flexRow, { alignItems: 'center', justifyContent: 'flex-start', paddingLeft: largeSpacer, paddingRight: largeSpacer, height: 100, paddingTop: 20, paddingBottom: 0 }, getRaisedStyle({ raisedHeight: RaisedHeight.low }), props.style ]} > */}
    <View { ...props } style={[ kelpStyles.flexRow, { flexDirection: 'row', backgroundColor: props.color ? props.color : Color.primary, paddingLeft: medSpacer, paddingRight: medSpacer, height: 100, paddingTop: 20, paddingBottom: 0, alignItems: 'center' }, getRaisedStyle({ raisedHeight: RaisedHeight.low }), props.style ]} >
      
      {/* TODO:  Remove the header in favor AirBnB / AppStore style. */}
      <View style={{ width: 25, height: 25, display: 'flex', flexDirection: 'row', alignItems: "center", flexShrink: 0, flexGrow: 0 }}>
        {/* <TouchableOpacity onPress={ props.showMenu }>
          <ImageBackground source={ require("../../assets/icons/MenuWhite.png") } imageStyle={{ resizeMode: "contain" }} style={{ width: 22, height: 22 }} />
        </TouchableOpacity> */}
        { props.showBackButton && props.history ?
          <TouchableOpacity onPress={ () => { if (props.history) { props.history.goBack(); } } }>
            <Icon name="chevron-left" type="material" size={ 32 } color={ Color.white } />
          </TouchableOpacity> : 
          null
        }
      </View>
      
      <View style={{ display: 'flex', flexDirection: 'row', flex: 1, flexBasis: 0, alignItems: "center", justifyContent: 'center' }}>
        <Text style={{ color: 'white', alignSelf: 'center', fontFamily: 'Poppins-Bold', fontSize: 16, letterSpacing: 3 }}>{ props.title.toUpperCase() }</Text>
      </View>

      <View style={{ width: 25, height: 25, display: 'flex', flexDirection: 'row', alignItems: "center", flexShrink: 0, flexGrow: 0 }}>
        {
          props.showRightButton ? 
            <TouchableOpacity onPress={ props.onRightButtonPress }>
              {
                props.rightIconName && props.rightIconType ?
                  <Icon name={ props.rightIconName } type={ props.rightIconType } size={ 27 } color={ Color.white } /> :
                  // TODO-IMPORTANT:  We need to find a way to decouple AND do this dynamically.  It's an example of two encodings that the framework is forcing us to couple.  That's not great and limits future expansion.
                  //                  Looks like there are some options:  https://stackoverflow.com/questions/44991669/react-native-require-with-dynamic-string
                  <ImageBackground source={ require("../../assets/icons/PlusWhite.png").default } imageStyle={{ resizeMode: "contain" }} style={{ width: 22, height: 22 }} />
              }
            </TouchableOpacity>
          : null
        }
      </View>

      {/* <View style={{ display: 'flex', flexDirection: 'row', flex: 0, flexBasis: 0, alignItems: "center", justifyContent: 'flex-end' }}>
        <ImageBackground source={ require("../../assets/icons/PlusWhite.png") } imageStyle={{ resizeMode: "contain" }} style={{ width: 22, height: 22 }} />
      </View> */}
    </View>
  </View>
);
