import Amplify from 'aws-amplify';
import * as React from 'react';
import { ScrollView, View } from 'react-native';
import { awsConfig } from '../../config';
import { AuthenticationWidget } from '../../packages/kelp-bar/auth';
import { CenteredView } from '../../packages/kelp-bar/utils';
import { useSizes } from '../gallery/sizes-helper';


export const Authenticate = ({ mode }: { mode: "login" | "register" }) => {

  const { isDesktop } = useSizes();
  // Amplify.configure(awsConfig);

  return (
    <View style={{ flex: 1, display: 'flex', backgroundColor: 'white' }}>
      {/* <SystemHeader breadcrumbs={ false } /> */}
      <ScrollView contentContainerStyle={{ minHeight: '100%' }} style={{ flex: 1 }}>
        <CenteredView containerProps={{ style: { flex: 1 } }} style={{ alignItems: isDesktop ? 'center' : 'stretch', justifyContent: isDesktop ? 'center' : undefined, flex: 1, paddingHorizontal: undefined, height: '100%' }}>
          <View style={{ flex: 1 }} />
          <View style={{ borderRadius: 5, width: isDesktop ? 800 : '100%', borderWidth: isDesktop ? 2 : 0, borderColor: '#eeeeee' }}>
            {/* <Authenticator */}
            {/* CONSIDER:  We COULD swap this out for Authenticator Amplify component... SHOULD be able to dress it up enough to be good enough I figure */}
            <AuthenticationWidget mode={mode} />
          </View>
          <View style={{ flex: 1 }} />
        </CenteredView>

      </ScrollView>

    </View>
  )
}
