
//  CONSIDER:  Should be able to quickly build a system to 
//  TODO:  Re-create the Habor filter thing here ugh!!!  SO much work re-creating EVERYTHING!!!
//  TODO:  Catalog ALL the existing work so I can explore it ugh!

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SDTObject } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import { HOCRegister } from 'halia-native';
import * as React from 'react';
import { Text, TextInput, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { Chiclet } from '../../../packages/kelp-bar/chiclet';
import { Paragraph } from '../../../packages/kelp-bar/constants';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { ColumnSelection, Entity2Plugin, EntityContext, EntityEditor, EntityTable, FilterComponent } from '../../hessia2/entity-plugin';
import { Entity } from '../../hessia2/entity-service';
import { Primitive2Plugin, TypeSelection } from '../../hessia2/object-system';
import { AaroContext, AaroPlugin, AaroSystem } from '../aaro-core';
import { AaroHeader } from '../utils';
const uuidv4 = require('uuid/v4');

//
//  Data Model
//

//  Davel Schemas

//  TODO:  Move these Task Parts to separate systems?  I DO want "Task" to be a COMOPNENT though.  This way anything can be "Completable" ? Hmm... but the SEMANTICS are important!!!  A TASK is different from COMPLETABLE, because it has that concept of a WORK item too hm!
//  TODO:  Build more advanced Davel types (like priority and 1-10 score)
//  ABSTRACTION:  THIS can be done within Hessia, EASILY.  Just a Model / Type.
const TaskSchema: SDTObject = {
  type: "object",
  properties: {
    //  REALIZATION:  All of these are JUST associations with the task in a consistent way.  The POINT is that we can use this FOR NOW.  But, we can use other systems eventually, for example another system which tracks status of LOTS of things.  Etc.  This is SPECIFIC to this Davel Type.
    status: { type: "option", options: ['backlog', 'in-progress', 'done', 'icebox', 'blocked', 'in-review', 'in-testing'], required: false },
    dueDate: { type: "date", required: false },
    priority: { type: "number", required: false },
    difficulty: { type: "string", required: false },
    project: { type: "string", required: false },
  }
};


//  NOTE:  These are JUST another encoding which is ASSOCIATED with the Task Properties via the shared label!
//  IDEA:  I LOVE the idea of being able to decorate these things but hide / show them.. like adding comments to "Lines" and stuff as first class citizens.

//  NOTE:  This can ALSO be done easily by adding additional data to the davel type pieces.  This MAY be why it would be helpful to have THEM be identifiable as well though.  But the contextual reference is good too.
export const TaskLabels = {
  completed: "Completed",
  dueDate: "Due Date",
  priority: "Priority"
}


export interface Task {
  status?: 'backlog' | 'in-progress' | 'done' | 'icebox' | 'blocked' | 'in-review' | 'in-testing';
  dueDate?: string;
  priority?: number;
  difficulty?: string;
  project?: string;
}

export interface TaskEntity extends Entity {
  value?: Task;
}

//
//  React Context
//
export interface ITaskContext {
  tasks: NounServiceInstanceInternal<TaskEntity>[];
  taskPlugin?: TaskAaroExtension;
  createTask: () => Promise<void>;
}
export const TaskContext = React.createContext<ITaskContext>({ taskPlugin: undefined, tasks: [], createTask: async () => { return; } });

//
//  Screens
//
const TaskStackNav = createStackNavigator();
const TaskTabNav = createBottomTabNavigator();

export type TaskStatus = 'backlog' | 'in-progress' | 'done' | 'icebox' | 'blocked' | 'in-review' | 'in-testing';

export interface TaskFilterConfig {
  status: string;
  dueDate: string;
  priority: TaskStatus;
  projectName: string;
}

//  CONSIDER:  This is NOT a "Component", but I STILL want to be able to get access to ALL ENTITIES!  This means I have functions which SHOULD be able to access these resources ugh!!!  CAN make sense to 
//  CONSIDER:  MOUNT this as a component or make it have access to those subscriptions.

const TaskFilter: FilterComponent<TaskFilterConfig> = {
  id: "task-filter",
  name: "Task Filter",
  description: "Task Filter",
  filter: (filteredEntities, allEntities, { status, dueDate, priority, projectName }) => {
    let myFilteredEntities = [...filteredEntities];
    return myFilteredEntities.filter(entity => {

      //  TODO:  NEED to be able to add ANY association as a filter rule mm!
      if (projectName) {
        const entityProjectId = entity.payload.value?.project;
        // console.log("Project ID: " + JSON.stringify(entityProjectId));
        if (entityProjectId) {
          // console.log("Entities Length: " + allEntities.length);
          const projectEntity = allEntities.find(_entity => {
            // console.log("Entity ID: " + JSON.stringify(_entity.payload.id));
            return _entity.payload.id == entityProjectId;
          });
          // console.log("Project Entity: " + JSON.stringify(projectEntity));
          if (!projectEntity?.payload.name?.includes(projectName)) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (status && !entity?.payload.value?.status?.includes(status)) { return false; }
      if (priority && !entity?.payload.value?.priority?.includes(priority)) { return false; }
      return true;
    });
  },
  component: ({ setConfig, config }) => {
    return (
      <>
        <Text>Status</Text>
        <TextInput value={config.status} onChangeText={status => setConfig({ ...config, status })} />
        <Text>Priority</Text>
        <TextInput value={config.priority} onChangeText={(priority: any) => setConfig({ ...config, priority })} />
        <Text>Project Name</Text>
        <TextInput value={config.projectName} onChangeText={(projectName: any) => setConfig({ ...config, projectName })} />
      </>
    );

  }
};

export const TaskList = () => {
  const navigation = useNavigation<any>();
  const { tasks, createTask } = React.useContext(TaskContext);

  const columnSelectors: ColumnSelection<Task>[] = [
    {
      name: "Name",
      minWidth: 250,
      extractor: e => e.payload.name,
      maxWidth: 250,
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {
        return (
          <Paragraph>{entity?.payload.name}</Paragraph>
        );
      }
    },
    {
      name: "Status",
      minWidth: 100,
      maxWidth: 100,
      extractor: e => e.payload.value?.status,
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {
        const status = entity?.payload.value?.status;
        const backgroundColor = status === "backlog" ? "#40cfff" : status === "blocked" ? "#ff78c0" : status === "done" ? "#e7ffde" : status === "icebox" ? "#defeff" : status === "in-progress" ? "#407cff" : status === "in-review" ? "pink" : status === "in-testing" ? "#8fffa3" : "purple";
        return (
          <Chiclet textStyle={{ color: 'white' }} containerStyle={{ backgroundColor }}>{status}</Chiclet>
        );
      }
    },
    {
      name: "Project",
      minWidth: 100,
      maxWidth: 100,
      extractor: e => 'TBD',
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {

        const { entities } = React.useContext(EntityContext);
        const projectId = entity?.payload.value?.project;

        const projectEntity = entities.find(_entity => _entity.payload.id === projectId);
        return (
          <Text>{projectEntity?.payload?.name}</Text>
        );
      }
    },
    {
      name: "Difficulty",
      minWidth: 100,
      maxWidth: 100,
      extractor: e => e.payload.value?.difficulty,
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {
        return (
          <Text>{entity?.payload.value?.difficulty}</Text>
        );
      }
    },
    {
      name: "Due Date",
      minWidth: 100,
      maxWidth: 100,
      extractor: e => e.payload.value?.dueDate,
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {
        return (
          <Text>{entity?.payload.value?.dueDate}</Text>
        );
      }
    },
    {
      name: "Priority",
      minWidth: 100,
      maxWidth: 100,
      extractor: e => e.payload.value?.priority,
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {
        return (
          <Text>{entity?.payload.value?.priority}</Text>
        );
      }
    },
    {
      name: "Description",
      minWidth: 300,
      maxWidth: 300,
      extractor: e => e.payload.description,
      component: ({ entity }: { entity: NounServiceInstanceInternal<TaskEntity> }) => {
        return (
          <Text>{entity?.payload.description}</Text>
        );
      }
    },
  ];

  return (
    <>

      {/* Header */}
      <AaroHeader title="Tasks">
        <TouchableOpacity style={{ backgroundColor: '#fbfbfb', borderRadius: 25, width: 50, height: 50, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }} onPress={createTask}>
          <Icon color="#888888" name="plus" type="font-awesome" size={19} />
        </TouchableOpacity>
      </AaroHeader>

      {/* Entry Table */}
      <EntityTable columns={columnSelectors} entities={tasks} onPress={entity => navigation.navigate("TaskEditor", { entity })} />
    </>
  );
}


//
//  Navigators
//

export const TaskHome = () => {
  return (
    <TaskStackNav.Navigator initialRouteName='Tasks' screenOptions={{ headerShown: false }}>
      {/* TODO:  Extract the route mapper it's a common pattern. */}
      <TaskStackNav.Screen name="TaskEditor" component={({ route }) => {
        const { selectEntity } = React.useContext(EntityContext);
        React.useEffect(() => {
          selectEntity(route.params.entity);
        }, []);
        return <EntityEditor />
      }} />
      <TaskStackNav.Screen name="Tasks" component={TaskList} />
    </TaskStackNav.Navigator>
  );
}


export class TaskAaroExtension extends CorePluginClass {

  public static details = {
    name: 'Task Plugin',
    description: 'Task App',
    dependencies: [Entity2Plugin.details.id, AaroPlugin.details.id, Primitive2Plugin.details.id],
    id: 'task',
    image: require("../../../assets/stickers/tasks.png")
  }

  //  ID for the Habit Model Primitive
  public taskModelId = "task-model";

  public primitive2!: Primitive2Plugin;

  public install = async (program: Program, { entity2, aaro, primitive2 }: { entity2: Entity2Plugin, aaro: AaroPlugin, primitive2: Primitive2Plugin }) => {

    this.primitive2 = primitive2;

    //  Build the Task Entry Model
    const taskEntryModelEntity = await entity2.entityService.getEntityById(this.taskModelId);
    if (!taskEntryModelEntity) {
      console.log("Building Task Entry Model");
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = TaskSchema;
      const entity = { id: this.taskModelId, name: "Task Entry Model", description: "Task Model Entity", owners: [AaroSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("Task Entry Model Exists!");
    }

    aaro.registerHOC(({ children }) => {

      const { entities } = React.useContext(EntityContext);
      const isTaskEntity = (entity: NounServiceInstanceInternal<Entity>) => {
        return (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === this.taskModelId);
      };

      const tasks = entities.filter(entity => isTaskEntity(entity)) as NounServiceInstanceInternal<TaskEntity>[];

      const { installRoute } = React.useContext(AaroContext);
      const { registerFilterComponent } = React.useContext(EntityContext);

      React.useEffect(() => {

        installRoute({
          name: "Tasks",
          component: TaskHome,
          icon: { type: 'ionicon', name: 'checkmark-done' },
          emoji: "📝",
          logo: require("../../../assets/stickers/tasks.png")
        });

        registerFilterComponent(TaskFilter);
      }, []);

      const navigation = useNavigation<any>();

      const createTask = async () => {

        //  TODO:  Bring up a PANEL to fill in the entity!
        //  TODO:  This is COMMON for systems.  Make it easier to do this!
        const task: Task = {};
        const entryEntity: Entity = { id: uuidv4(), name: "New Task", owners: [AaroSystem] };
        const entryType: TypeSelection = { type: "reference", entityId: this.taskModelId }
        const newTask = await this.primitive2.createObject(entryEntity, entryType, task);
        navigation.navigate("TaskEditor", { entity: newTask });
      }

      return (
        <TaskContext.Provider value={{ taskPlugin: this, tasks, createTask }}>
          <HOCRegister id="habit">
            {children}
          </HOCRegister>
        </TaskContext.Provider>
      );

    });
    return this;
  }
}


