import { CorePluginClass } from "halia";
import { ICorePlugin } from "./core-plugin";
import { Edge2Plugin } from "./edge2-plugin";
import { Type2Plugin } from "./type-system";

const STRING_NODETEYPE_NODE_ID = "string_Nodetype";
const NUMBER_NODETEYPE_NODE_ID = "number_Nodetype";
const OBJECT_NODETEYPE_NODE_ID = "object_Nodetype";
const DATE_NODETEYPE_NODE_ID = "date_Nodetype";
const BOOLEAN_NODETEYPE_NODE_ID = "boolean_Nodetype";

export function PrimitiveMixin<T extends new (...args: any[]) => CorePluginClass>(Base: T) {

  return class PrimitiveMixin extends Base implements ICorePlugin {

    public mixinInstallers!: any[];
    public graph!: Edge2Plugin;
    public type!: Type2Plugin;

    constructor(...rest: any[]) {
      super();
      this.mixinInstallers.push(this.installPrimitives)
    }

    public installPrimitives = async () => {

      const _me = this as any;

      //  String Primitive
      const stringType = await this.graph.getNodeById(STRING_NODETEYPE_NODE_ID);
      if (!stringType) {
        await this.type.createType({ id: STRING_NODETEYPE_NODE_ID, entityType: "node", name: "String", description: "String Type", emoji: "📚" });
        await _me.registerSystem(STRING_NODETEYPE_NODE_ID);
      }

      //  Number Primitive
      const numberType = await this.graph.getNodeById(NUMBER_NODETEYPE_NODE_ID);
      if (!numberType) {
        await this.type.createType({ id: NUMBER_NODETEYPE_NODE_ID, entityType: "node", name: "Number", description: "Number Type", emoji: "📚" });
        await _me.registerSystem(NUMBER_NODETEYPE_NODE_ID);
      }

      //  Object Primitive
      const objectType = await this.graph.getNodeById(OBJECT_NODETEYPE_NODE_ID);
      if (!objectType) {
        await this.type.createTypeIfNeeded({ id: OBJECT_NODETEYPE_NODE_ID, entityType: "node", name: "Object", description: "Object Type", emoji: "📚" });
        await _me.registerSystem(OBJECT_NODETEYPE_NODE_ID);
      }

      //  Date Primitive
      const dateType = await this.graph.getNodeById(DATE_NODETEYPE_NODE_ID);
      if (!dateType) {
        await this.type.createTypeIfNeeded({ id: DATE_NODETEYPE_NODE_ID, entityType: "node", name: "Date", description: "Date Type", emoji: "📚" });
        await _me.registerSystem(DATE_NODETEYPE_NODE_ID);
      }

      //  Boolean Primitive
      const booleanType = await this.graph.getNodeById(BOOLEAN_NODETEYPE_NODE_ID);
      if (!booleanType) {
        await this.type.createTypeIfNeeded({ id: BOOLEAN_NODETEYPE_NODE_ID, entityType: "node", name: "Boolean", description: "Boolean Type", emoji: "📚" });
        await _me.registerSystem(BOOLEAN_NODETEYPE_NODE_ID);
      }

    }
  }
}