
//  TODO:  This is BASICALLY the same pattern as what we have with Halia, AND it's simlar to Components / Blocks / Functions.  We WANT to register a single thing, likely a function (which has criteria for a component / app) and TAG it as an app.

import { CorePluginClass } from "halia";
import * as React from 'react';
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { SystemHeader } from "../../packages/kelp-bar/system-header";
import { Entity2Plugin } from "./entity-plugin";
import { Hessia2Context, Hessia2Plugin, System2 } from "./hessia2-plugin";
import { useSizes } from "../gallery/sizes-helper";

//  NOTE:  It's the SAME as a "System"... but it's just "tagged" as an app???
export interface App extends System2 {
}

export interface AppsContext {
  apps: App[];
  installApp: (app: App) => void;
}
export const AppPluginContext = React.createContext<Apps2Plugin | undefined>(undefined);
export const AppsContext = React.createContext<{ apps, installApp }>({ apps: [], installApp: () => null });

export const AppsHome = () => {
  const { apps } = React.useContext(AppsContext);

  //  TODO:  I'd REALLY like apps to be THEMSELVES modeled as entities.. otherwise we can't REFERENCE the app in a consistent way.  Also might make it a bit more explicit to show what plugins which introduce apps actually do.

  const [selectedApp, selectApp] = React.useState<App | undefined>();
  const Comp = selectedApp?.component;

  const { isDesktop } = useSizes();

  return (
    <>
      {
        !selectedApp && (
          <>
            <SystemHeader system={AppSystem} breadcrumbs={false} />
            <FlatList data={apps} renderItem={({ item }) =>
              <TouchableOpacity onPress={() => selectApp(item)}>
                <Text>{JSON.stringify(item)}</Text>
              </TouchableOpacity>
            } />
          </>
        )
      }
      {
        selectedApp && (
          <>
            {isDesktop && <SystemHeader system={AppSystem} breadcrumbs={false} />}
            <View style={{ flex: 1, padding: isDesktop ? 30 : 0, backgroundColor: 'white' }}>
              <View style={{ flex: 1, backgroundColor: 'white', borderRadius: isDesktop ? 12 : 0, overflow: 'hidden', borderColor: '#eeeeee', borderWidth: isDesktop ? 2 : 0 }}>
                <Comp />
              </View>
            </View>
          </>
        )
      }
    </>
  );
}

const AppSystem: System2 = {
  id: "app-system",
  color: "#aaaaaa",
  name: "Apps",
  description: "Apps System",
  emoji: "🔗",
  component: AppsHome,
  icon: { type: 'ionicon', name: 'apps-outline' },
  primaryColor: "#e31b54",
  backgroundColor: "#fff7f9",
}





export class Apps2Plugin extends CorePluginClass {

  public static details = {
    name: "Apps",
    description: "Apps Plugin",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id],
    id: "apps"
  }

  protected entity2!: Entity2Plugin;

  public install = async (program: any, { hessia2, entity2 }: { hessia2: Hessia2Plugin, entity2: Entity2Plugin }) => {

    const _me = this;

    this.entity2 = entity2;

    //  TODO:  Register "Apps" as Hessia Entities
    //  NOTE:  I'm choosing NOT to register the Hessia Core systems (like Entities, etc) as "Systems"... but I MAY want to do that at some point, ESPEICALLY because we have Plugins which register pieces!!!  We need to be able to see what they do!

    hessia2.registerHOC(({ children }) => {

      //  NOTE:  An "App" is JUST a React Component.
      //  CONSIDER:  We could allow this to use Hessia Functions which return UI.

      const [apps, setApps] = React.useState<App[]>([]);
      const hessia2Context = React.useContext(Hessia2Context);
      const installApp = (app: App) => {
        setApps(prev => ([...prev, app]));
      }
      React.useEffect(() => {
        hessia2Context?.installSystem(AppSystem, true);
      }, []);

      return (
        <AppPluginContext.Provider value={this}>
          <AppsContext.Provider value={{ apps, installApp }}>
            {children}
          </AppsContext.Provider>
        </AppPluginContext.Provider>
      );
    });


    return this;
  }
}

