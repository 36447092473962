
//  CONSIDER:  Should be able to quickly build a system to 
//  TODO:  Re-create the Habor filter thing here ugh!!!  SO much work re-creating EVERYTHING!!!
//  TODO:  Catalog ALL the existing work so I can explore it ugh!

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SDTObject } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import { HOCRegister } from 'halia-native';
import * as React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { ColumnSelection, Entity2Plugin, EntityContext, EntityEditor, EntityTable } from '../../hessia2/entity-plugin';
import { Entity } from '../../hessia2/entity-service';
import { Primitive2Plugin, TypeSelection } from '../../hessia2/object-system';
import { AaroContext, AaroPlugin, AaroSystem } from '../aaro-core';
import { AaroHeader } from '../utils';
const uuidv4 = require('uuid/v4');

//
//  Data Model
//

//  Davel Schemas

//  TODO:  Move these Checkin Parts to separate systems?  I DO want "Checkin" to be a COMOPNENT though.  This way anything can be "Completable" ? Hmm... but the SEMANTICS are important!!!  A CHECKIN is different from COMPLETABLE, because it has that concept of a WORK item too hm!
//  TODO:  Build more advanced Davel types (like priority and 1-10 score)
//  ABSTRACTION:  THIS can be done within Hessia, EASILY.  Just a Model / Type.
//  REALIZATION:  All of these are JUST associations with the checkin in a consistent way.  The POINT is that we can use this FOR NOW.  But, we can use other systems eventually, for example another system which tracks status of LOTS of things.  Etc.  This is SPECIFIC to this Davel Type.
//  TODO:  SHOULD be able to build this as a FORM or separate things hmmm... not crazy about doing them all in a model like this... but it's a START.  It just makes them harder to reference and extend.

const CheckinSchema: SDTObject = {
  type: "object",
  properties: {
    date: { type: "date", required: true },
    time: { type: "date", required: true },
    checkinNumber: { type: "number", required: true },
    lifeSatisfaction: { type: "number", required: true },
    depression: { type: "number", required: true },
    energy: { type: "number", required: true },
    suicidal: { type: "number", required: true },
    confidence: { type: "number", required: true },
    satAppearance: { type: "number", required: true },
    loneliness: { type: "number", required: true },
    jealousy: { type: "number", required: true },
    romanticLove: { type: "number", required: true },
    resistance: { type: "number", required: true },
    anxiety: { type: "number", required: true },
    centered: { type: "number", required: true },
    guilt: { type: "number", required: true },
    fear: { type: "number", required: true },
    anger: { type: "number", required: true },
    shame: { type: "number", required: true },
    childish: { type: "number", required: true },
    competence: { type: "number", required: true },
    tinnitus: { type: "number", required: true },
    pain: { type: "number", required: true },
    discomfort: { type: "number", required: true },
    trackedDiet: { type: "number", required: true },
    prevPerformance: { type: "number", required: true },
    periodGoals: { type: "text", required: true },
    journal: { type: "text", required: true }
  }
};


//  NOTE:  These are JUST another encoding which is ASSOCIATED with the Checkin Properties via the shared label!
//  IDEA:  I LOVE the idea of being able to decorate these things but hide / show them.. like adding comments to "Lines" and stuff as first class citizens.

//  NOTE:  This can ALSO be done easily by adding additional data to the davel type pieces.  This MAY be why it would be helpful to have THEM be identifiable as well though.  But the contextual reference is good too.
export const CheckinLabels = {
  date: "Date",
  time: "Time",
  checkinNumber: "Checkin #",
  lifeSatisfaction: "Life Satisfaction",
  depression: "Depression",
  energy: "Energy",
  suicidal: "Suicidal",
  confidence: "Confidence",
  satAppearance: "Sat. Appearance",
  loneliness: "Loneliness",
  jealousy: "Jealousy",
  romanticLove: "Romantic Love",
  resistance: "Resistance",
  anxiety: "Anxiety",
  centered: "Centered",
  guilt: "Guilt",
  fear: "Fear",
  anger: "Anger",
  shame: "Shame",
  childish: "Childish",
  competence: "Competence",
  tinnitus: "Tinnitus",
  pain: "Pain",
  discomfort: "Discomfort",
  trackedDiet: "Tracked Diet",
  prevPerformance: "Prev. Performance",
  periodGoals: "Perdiod Goals",
  journal: "Journal"
}


export interface Checkin {
  date: string;
  time: string;
  checkinNumber: number;
  lifeSatisfaction: number;
  depression: number;
  energy: number;
  suicidal: number;
  confidence: number;
  satAppearance: number;
  loneliness: number;
  jealousy: number;
  romanticLove: number;
  resistance: number;
  anxiety: number;
  centered: number;
  guilt: number;
  fear: number;
  anger: number;
  shame: number;
  childish: number;
  competence: number;
  tinnitus: number;
  pain: number;
  discomfort: number;
  trackedDiet: number;
  prevPerformance: number;
  periodGoals: string;
  journal: string;
}

export interface CheckinEntity extends Entity {
  value: Checkin;
}

//
//  React Context
//
export interface ICheckinContext {
  checkins: NounServiceInstanceInternal<CheckinEntity>[];
  checkinPlugin?: CheckinAaroExtension;
  createCheckin: () => Promise<void>;
}
export const CheckinContext = React.createContext<ICheckinContext>({ checkinPlugin: undefined, checkins: [], createCheckin: async () => { return; } });

//
//  Screens
//
const CheckinStackNav = createStackNavigator();
const CheckinTabNav = createBottomTabNavigator();

export const CheckinList = () => {
  const navigation = useNavigation<any>();
  const { checkins, createCheckin } = React.useContext(CheckinContext);

  //  TODO:  Remove this.. just a type guard, but it's static...
  if (!CheckinSchema.properties) { return null; }

  const columnSelectors: ColumnSelection[] = Object.keys(CheckinSchema.properties)
  .filter(prop => ["date", "time", "checkinNumber", "journal" ]
  .includes(prop)).map(prop => {
    return {
      name: CheckinLabels[prop],
      minWidth: 150,
      component: ({ entity }) => {
        return (
          <Text>{entity.payload.value[prop]}</Text>
        )
      }
    };
  })
  return (
    <>

      {/* Header */}
      <AaroHeader title="Checkins">
        <TouchableOpacity style={{ backgroundColor: '#fbfbfb', borderRadius: 25, width: 50, height: 50, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }} onPress={createCheckin}>
          <Icon color="#888888" name="plus" type="font-awesome" size={19} />
        </TouchableOpacity>
      </AaroHeader>

      {/* Entry Table */}
      <EntityTable columns={columnSelectors} entities={checkins} onPress={entity => navigation.navigate("CheckinEditor", { entity })} />
    </>
  );
}


//
//  Navigators
//

export const CheckinHome = () => {
  return (
    <CheckinStackNav.Navigator initialRouteName='Checkins' screenOptions={{ headerShown: false }}>
      {/* TODO:  Extract the route mapper it's a common pattern. */}
      <CheckinStackNav.Screen name="CheckinEditor" component={({ route }) => {
        const { selectEntity } = React.useContext(EntityContext);
        React.useEffect(() => {
          selectEntity(route.params.entity);
        }, []);
        return <EntityEditor />
      }} />
      <CheckinStackNav.Screen name="Checkins" component={CheckinList} />
    </CheckinStackNav.Navigator>
  );
}


export class CheckinAaroExtension extends CorePluginClass {

  public static details = {
    name: 'Checkin Plugin',
    description: 'Checkin App',
    dependencies: [Entity2Plugin.details.id, AaroPlugin.details.id, Primitive2Plugin.details.id],
    id: 'checkin',
    image: require("../../../assets/stickers/checkins.png")
  }

  //  ID for the Habit Model Primitive
  public checkinModelId = "checkin-model";

  public primitive2!: Primitive2Plugin;

  public install = async (program: Program, { entity2, aaro, primitive2 }: { entity2: Entity2Plugin, aaro: AaroPlugin, primitive2: Primitive2Plugin }) => {

    this.primitive2 = primitive2;

    //  Build the Checkin Entry Model
    const checkinEntryModelEntity = await entity2.entityService.getEntityById(this.checkinModelId);
    if (!checkinEntryModelEntity) {
      console.log("Building Checkin Entry Model");
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = CheckinSchema;
      const entity = { id: this.checkinModelId, name: "Checkin Entry Model", description: "Checkin Model Entity", owners: [AaroSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("Checkin Entry Model Exists!");
    }

    aaro.registerHOC(({ children }) => {

      const { entities } = React.useContext(EntityContext);
      const isCheckinEntity = (entity: NounServiceInstanceInternal<Entity>) => {
        return (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === this.checkinModelId);
      };

      const checkins = entities.filter(entity => isCheckinEntity(entity)) as NounServiceInstanceInternal<CheckinEntity>[];

      const aaroContext = React.useContext(AaroContext);

      React.useEffect(() => {
        aaroContext.installRoute({
          name: "Checkins",
          component: CheckinHome,
          icon: { type: 'ionicon', name: 'checkbox-outline' },
          emoji: "✅",
          logo: require("../../../assets/stickers/checkins.png")
        });
      }, []);

      const navigation = useNavigation<any>();

      const createCheckin = async () => {

        //  TODO:  Bring up a PANEL to fill in the entity!
        //  TODO:  This is COMMON for systems.  Make it easier to do this!
        const checkin: Checkin = {
          date: new Date().toISOString(),
          time: new Date().toISOString(),
          checkinNumber: 1,
          lifeSatisfaction: 5,
          depression: 5,
          energy: 5,
          suicidal: 5,
          confidence: 5,
          satAppearance: 5,
          loneliness: 5,
          jealousy: 5,
          romanticLove: 5,
          resistance: 5,
          anxiety: 5,
          centered: 5,
          guilt: 5,
          fear: 5,
          anger: 5,
          shame: 5,
          childish: 5,
          competence: 5,
          tinnitus: 5,
          pain: 5,
          discomfort: 5,
          trackedDiet: 5,
          prevPerformance: 5,
          periodGoals: "Perdiod Goals",
          journal: "Journal"
        };
        const entryEntity: Entity = { id: uuidv4(), name: "New Checkin", owners: [AaroSystem] };
        const entryType: TypeSelection = { type: "reference", entityId: this.checkinModelId }
        const newCheckin = await this.primitive2.createObject(entryEntity, entryType, checkin);
        navigation.navigate("CheckinEditor", { entity: newCheckin });
      }

      return (
        <CheckinContext.Provider value={{ checkinPlugin: this, checkins, createCheckin }}>
          <HOCRegister id="habit">
            {children}
          </HOCRegister>
        </CheckinContext.Provider>
      );

    });
    return this;
  }
}


