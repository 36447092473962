import { SDTBinarySerializedSchema } from "davel/dist/src/parsers/binary";
import { AllTMSDTs, HSDTDerivedSchema, HSDTRelationshipSerializedSchema, SDT, SDTAnySerializedSchema, SDTArraySerializedSchema, SDTBooleanSerializedSchema, SDTDateSerializedSchema, SDTKeywordSerializedSchema, SDTNumberSerializedSchema, SDTObject, SDTObjectSerializedSchema, SDTOptionSerializedSchema, SDTSDT, SDTTextSerializedSchema, SDTTypeSerializedSchema } from "habor-sdk";
import * as React from 'react';
import { Modal, ScrollView, Text, TextInput, View } from "react-native";
import { GroupCard } from "../../../kelp-bar/group-card";
import { DavelForm, DavelFormButton, davelTypeRegister } from "../../davel-ui";
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

const SDTFieldStyle = {
  borderRadius: 20,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 12,
  paddingLeft: 12,
  fontFamily: 'Poppins-Medium',
  borderColor: 'black',
  borderWidth: 1,
  height: 120
};

export const JSONField = (props: any) => {
  const { input, ...rest } = props;
  return (
    <View>
      <TextInput
        {...rest}
        onChangeText={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        multiline={true}
        autoCapitalize="none"
      />
    </View>
  );
};

//  TODO:  Move this to Davel and support a hook for custom types?
//  TODO:  I called them "SerializedSchema", but is that redundant?  Is a schema already serialized?
//  TODO:  Register with a function / API as done in Habor / Davel.  This way registration can be done with Feature APIs / Halia!
const typeToSchemaMap: { [type: string]: SDTObject } = {
  text: SDTTextSerializedSchema,
  any: SDTAnySerializedSchema,
  array: SDTArraySerializedSchema,
  boolean: SDTBooleanSerializedSchema,
  date: SDTDateSerializedSchema,
  keyword: SDTKeywordSerializedSchema,
  number: SDTNumberSerializedSchema,
  object: SDTObjectSerializedSchema,
  option: SDTOptionSerializedSchema,
  sdt: SDTTypeSerializedSchema,
  relationship: HSDTRelationshipSerializedSchema,  //  TODO:  Remove this from Davel!  This is a Habor concept.
  derived: HSDTDerivedSchema,
  binary: SDTBinarySerializedSchema,
};

export const registerSDTSchema = (name: string, sdtSchema: SDTObject) => {
  typeToSchemaMap[name] = sdtSchema;
}


export const getSchemaFromType = (type: string) => {
  return typeToSchemaMap[type];
}

//  TODO-CRITICAL:  When we update a davel form WITHIN a davel form, we should NOT need to press SUBMIT... Actually, I'm thinking the Submit / Cancel operations should be invoked externally... I don't believe we need the buttons rendered IN the form itself.

//  CONCERN:  PERHAPS SDTs should NOT support a Default value from within Davel!? Hmmm???

/**
 * An Editor used to define an SDT.  Given an SDT, we obtain the SDTObject Schema associated with its config options and use that to update the SDT.
 * TODO:  Instead of building an "SDT Editor", build an "TypeView" which lets you view a type and edit it... when you want to edit a TYPE itself, then pass in the type schema.
 * 
 * Each DT has an associated "Options" object which can be used to modify the validation process for objects of that type.  Given an SDT (Type Template) we retrieve the Schema for the options object and let the user define its value.  But it also includes the type itself.  So it's both the options and the type.  This is why we call it an SDT and not just an Options Constructor, etc.
 * 
 * TODO:  Deprecate in favor of a SINGLE "SDTComponent" component and just toss in the "SDT" type as the type!
 * 
 * @param param0 
 * @returns 
 */
export const SDTEditor = ({ sdt, onChange, getSchemaFromType: getSchemaFromTypeOverride }: { getSchemaFromType?: (type: string) => SDTObject | undefined, sdt?: AllTMSDTs, onChange: (value: any, schema: SDTObject) => void }) => {

  //  Handle Undefined
  if (!sdt) { return null }

  //  Get the Type Schema (schema used to generate a type) - Think of this as a tempatle used to write a rule book or instructions!
  const typeSchema = getSchemaFromTypeOverride ? getSchemaFromTypeOverride(sdt.type) : getSchemaFromType(sdt.type);
  if (!typeSchema) {
    //  TODO:  Standad errror handling with file output in debug / local mode! HM!
    throw `No schema found for type '${sdt.type}'.`;
  }

  return (
    <GroupCard>
      <Text style={{ marginVertical: 10 }}>This is the template we'll use to build your '{sdt.type}' schema:  {JSON.stringify(typeSchema)}</Text>
      <Text style={{ marginVertical: 10 }}>This is your current '{sdt.type}' schema:  {JSON.stringify(sdt)}</Text>
      <DavelForm debug={false} autoSubmit={true} schema={typeSchema} onSubmit={onChange} value={sdt} />
    </GroupCard>
  );
};

export interface SDTRendererProps {
  name: string;
  sdt: SDTSDT;
  value: AllTMSDTs;
  update: (value: AllTMSDTs) => void;
  getSchemaFromType?: (type: string) => SDTObject | undefined;
}

export interface SDTRendererState {
  open: boolean;
}

// NOTE:  This type is used to select a type.  SO, "sdt" is *this* SDT (which is of type SDT), and "value" is the *selected* SDT.  
const SDTRenderer = ({ name, sdt, value, update }: SDTRendererProps) => {

  const [open, setOpen] = React.useState<boolean>(false);

  const setSDT = (_value: AllTMSDTs) => {
    update(_value);
    setOpen(false);
  }

  return (
    <View>

      {/* SDT Field */}
      {
        value ?
          <>
            <Text style={{ marginVertical: 10 }}>You've selected the '{value?.type}' type.</Text>
            <Text style={{ marginVertical: 10 }}>Your schema is: {JSON.stringify(value)}</Text>
          </> :
          <Text style={{ marginVertical: 10 }}>You have not selected a type.</Text>
      }

      <SDTEditor getSchemaFromType={getSchemaFromType} sdt={value} onChange={setSDT} />

      {/* Type Selector */}
      <Modal visible={open}>
        <ScrollView>
          {
            davelTypeRegister.map(davelType => <DavelFormButton title={davelType.name} onPress={() => setSDT(davelType.defaultSDT as any)} />)
          }
        </ScrollView>
      </Modal>
      <DavelFormButton onPress={() => setOpen(true)} title='Select a Type' />

    </View >
  );
}



//  TODO:  Make a cool graphical schema editor, maybe with crypto-like movable, translucent blobs!
export const sdtSDTRenderer = ({ sdt, name, key, getSchemaFromType, update, value }: SDTRendererParams) => {
  return (
    <DavelField name={name} required={sdt.required}>
      <Text style={{ marginVertical: 10 }}>Use this form to build a schema (Serialized Davel Type aka. SDT)</Text>
      <SDTRenderer key={key} name={name} update={update} value={value} sdt={sdt} getSchemaFromType={getSchemaFromType} />
    </DavelField>
  );

};


