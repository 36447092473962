import { getInstanceSchemaFromProperties, HaborComponent, Instance, NounInternal } from 'habor-sdk';
import * as React from 'react';
import { ActivityIndicator, ScrollView, Text, View } from 'react-native';
import { Card } from '../../../../packages/kelp-bar/card';
import { Container } from '../../../../packages/kelp-bar/containers';
import { Header } from '../../../../packages/kelp-bar/header';
import { haborSDK } from '../../hessia-plugin/config';
import { withRouter } from '../../../../packages/isomorphic-router/react-router';
import { DavelForm, transformFormValues } from '../../../../packages/davel-ui/davel-ui';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { PageComponentProps } from './habor-primitive-app-elements';

interface CreateInstanceProps extends PageComponentProps {
  noun: NounInternal;
}

interface CreateInstanceState {
  spinner: boolean;
  status: string;
  createInstanceForm: any;
}

export class CreateInstanceBase extends React.Component<CreateInstanceProps, CreateInstanceState> {

  constructor(props: any) {
    super(props);
    this.state = {
      spinner: false,
      status: 'Initial',
      createInstanceForm: <Text>Loading Instance Form...</Text>
    };
  }

  public createInstance = async (values: any) => {
    const { noun } = this.props;
    if (!noun) { throw new Error('Cannot create an instance without a valid noun.'); }
    const { token } = this.props;
    if (!token) { throw new Error('Cannot call the SDK without a token.'); }
    const { name, properties } = noun;
    const schema = await getInstanceSchemaFromProperties(properties);
    const nestedValues: any = transformFormValues(values, schema, name);
    const payload = (nestedValues[name] == undefined) ? {} : nestedValues[name];  //  Covers the case where NO fields were rendered.
    const inst: Instance = { nounId: noun.id, payload };
    await haborSDK.createInstance(inst, token);
    this.props.history.goBack();
  }

  public async componentDidMount() {
    const { id: nounId } = this.props.noun;
    const { token } = this.props;
    if (!token) { throw new Error('Cannot call the SDK without a token.'); }
    const noun = await haborSDK.retrieveSerializedNoun(nounId, token);
    //  TODO:  To simplify the code, don't get a schema from the create function, just use the template.  We can work on first-class support for overrides in the future.
    if (noun.properties && noun.properties.create && noun.properties.create.value.params) {
      const createSchema = noun.properties.create.value.params;
      // const instanceProperties = await getInstanceSchemaFromProperties(createSchema.properties);
      // const updatedCreateSchema = { ...createSchema, properties: instanceProperties };
      this.setState({ createInstanceForm: <DavelForm schema={ createSchema } metadata={ { token } } onSubmit={this.createInstance} onSubmitFail={this.onFail} onSubmitSuccess={this.onSuccess} onCancel={ this.onCancel } /> });
    } else {
      alert('No Creation Params Specified!');
    }
  }

  public onCancel = async () => {
    this.onComplete();
    this.setState({ status: 'CANCELED' });
    // this.props.history.push('/dashboard');
  }

  public onSuccess = async () => {
    this.onComplete();
    this.setState({ status: 'SUCCESS' });
    // this.props.history.push('/dashboard');
  }

  public onFail = async (err: any) => {
    this.onComplete();
    // this.setState({ status: JSON.stringify(err) });
  }

  public onComplete = async () => {
    this.setState({ spinner: false });
  }


  public render = () => {

    const { noun } = this.props;
    const { createInstanceForm } = this.state;
    if (!noun) { return null; }

    const { history } = this.props;
    // const metadata = this.props.metadata;

    return (
      <View style={{ flex: 1 }}>
        <Header title={'Create ' + noun.name} />
        <Card>
          <Container>
            <ScrollView>
              { this.state.spinner ? <ActivityIndicator /> : undefined }
              { createInstanceForm }
              <Text>{ this.state.status }</Text>
            </ScrollView>
          </Container>
        </Card>
      </View>
    );
  }
}

const CreateInstance = withRouter(CreateInstanceBase);

//
//  HaborCreateInstance
//


export class HaborCreateInstancePrimitive extends React.Component<PrimitiveProps, any> {
  public render() {
    const { frameworkProps: { context: { workspace, token, user } }, userProps } = this.props;
    if (workspace == undefined) { throw new Error("HaborCreateInstance requires a Workspace object."); }
    return (
      <HaborContainer frameworkProps={ this.props.frameworkProps } style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CreateInstance workspace={ workspace } token={ token } user={ user } noun={ userProps.metadata.instance } />
      </HaborContainer>
    );
  }
}

export const HaborCreateInstance: HaborComponent = {
  name: "HaborCreateInstance",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "HaborCreateInstancePrimitive",
    props: { metadata: { type: "symbol", scopePath: ["props", "metadata"] } },
    children: []
  }
};
