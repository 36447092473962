import "react-native-get-random-values";
import "react-native-url-polyfill/auto";
import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { EventEmitter } from "events";
import { InstanceInternal as HaborInstanceInternal, HaborSDK, NamedObject, NounInternal, NounSearchParams, searchObjects } from "habor-sdk";
import React from "react";
import { Identifiable } from "../../plugins/hessia-plugins/entity-plugin";

const uuid = require("uuid/v4");

//  The idea IS to register things across the app.. hmmm.. MAYBE systems can STILL have their OWN storage, BUT we'll ALSO have an app-wide / context specific storage? HM!  THIS way we'll be able to do things like associate between systems instead of just yeah... hmmm.. FOR NOW, let's keep pushing with what we've got I suppose.

//  Start with a SIMPLE Noun service which allows us to create a new symbol associated with JS Objects.  This DOES have a representation in a fundamental ontology which we could interpret, BUT the encoding is specific ALTHOUGH it can be represented more generally... hmmmm... like.. we'll have the objects associated with each "type", BUT the TYPE / KEY is ITSELF an "Object" which we can reference perhaps?  Hmmm!  BUT not in THIS encoding.. it's JUST a KEY.  It is NOT a uniquely identifiable thing perhaps? HM!  We don't NEED to represent things as a GRAPH, as tempting and fundamental as it seems, the truth is, it's just ONE example of an encoding.  BUT it DOES make it more challenging to associate MULTIPLE classes then with a thing.  This is where MetaMesh and all that comes in where LOTS of things can be associated... hmmmm... For this Weave demo, let's skip that FOR NOW.

//
//  Task Service
//

//  NOTE:  Be defining a Class, we create a SYMBOL which is LINKED to a set of functions.  We can then reference these.

//  NOTE:  It IS nice to havea  unique ID for each thing... even in a simple app.  That's why I want to bring the Entity Service back.  Hmm.. I'm hesitant for now.

//  TODO:  Batch this up for creating Nouns with a given name?

//  NOTE:  A "Class" is a nice way to batch FUNCTIONS which can then be re-used with different configuration!

//  NOTE:  ALL this is, is a way to store a TYPE in the AsyncStorage... that's it... it's just an ENCODING, an ENTITY graph that will be interpreted.. hmmm... PERHAPS even entity graph isn't fundamental though... with the idea of experience and stuff.. hmm DUAL experience in the brain through MULTIPLE networks? H!
//  NOTE:  ALLL it is, is a namespace for STRINGS... nothing more.. it's a way to encode stuff UNDER a particular thing as a string... WHY not use a Graph DB instead?  MAY make sense to do what I was originally going to do with the Entities and the varrious systems... this way, we add DIRECTLY to the key/value store and the SYSTEMS keep their own state for things like order? Hmm...

export interface NounServiceInstanceInternal<T> {
  created: string;
  updated?: string;
  id: string;
  payload: T;
}

//  NOTE:  AGAIN, this "modifier", "abstrract" is JUST an exmaple of STAKING on a thing?? HM!  We can do the SAME in hessia. hmm... This DOES start to make soe sese HM! THEN we have UIs for these pieces.. AND it's less coupled and hard-coded, ALL well-organized from the bottom up?? HM

//  THe POINT is this is just a GROUP of things.. this "modifier" is INTERPRETED and that changes the RULES.. like whether it COMPILES and stuff.. it's ALL just intepreted thooo...
//  SO, we can do things like "extend" on the WHOLE group.. hell, we can add ANYTHING to the group and have a GENERIC system to say whether or not it's CONFORMANT.. this is the kind of thing I WANT in Hessia? HM!  THE have CONTEXTS.. like a "Program" or.. idk.. something.. maybe the interpreter doesn't care? Hmm...

// export const
//  TODO:  Build a generator for the generic encodings which can have LOTS of "language features" and stuff..   Hmm...tbd by their selections and stuff.. their context etc? Hmmm

// export const NounServiceFactory = {
//   return {

//   }
// }

//  NOTE:  I COULD do injection with descriptor, OR I can build an array.. hmm  ONE may be more maneagable? Hmm...  feel like I'm re-writing a language USING a language.. I think that's what I'm doing. hm

//  We can make an ISOMORPHIC noun service that uses the model system? Hm...  That kinda works for now I suppose...  But.. we already have the two noun?  Hmm.. no we don't have one for the other hmm.. MAYBE we just make one for that then and have the REMOTE one use model.  It's the SAME shit just without a shcmea. hmm... just weird becausee then the Local Model uses Local Noun, while Remote Noun uses Remote Model.  It's an artifact of the Habor implementaiton hmm... I'd like to be able to mess with these things MUCH faster using VISUAL tools! UGH!  It's essentially JUST a knowledge graph that you have on yoru devicd ena dhi thm!  Then you can do whateveer with Plugins and stuff hmm... not much different than apps and stuff? Hmm.. BUT all self-contained and like.. composable and stuff... The idea of linking together was from looking at the world, and Javascript and ... then I noticed it's KINDA Like the EAV model, and it reminded me of yeah.. but.. that's fine because it's public, I was suggesting somerhing VERY similar with dynamic trackers!  Ugh...

export class HaborNounService<T> implements INounService<T> {
  public emitter = new EventEmitter();

  protected noun?: NounInternal;
  protected token!: string;
  constructor(protected name: string, protected haborSDK: HaborSDK, public id?: string) {}

  public async init(token: string) {
    this.token = token;

    let noun;
    try {
      noun = await this.haborSDK.retrieveNoun(this.name, this.token);
    } catch (err) {
      noun = await this.haborSDK.createNoun(
        {
          name: this.name,
          description: "Habor Noun Service Noun",
          id: this.name,
          properties: {},
        },
        this.token
      );
    }

    this.noun = noun;

    this.emitter.emit("init");
  }

  //  WHY have ONE encoding which we INJECT pieces INTO when we COULD have NO encoding and EVERYTHING is injected? Hmm... maybe we can KINDA already think of it like that... we have the thing createed by interpretation of the first encoding, THEN the updaated encodings which are added and to be interpreted.. hmm..   LIke a world without explicit Functions Hmm.. idk man... Maybe each "neuron" in a neural net is staked by its "weights"? Hmm...

  public async create(obj: T, id?: string): Promise<NounServiceInstanceInternal<T>> {
    //  Make the NounInternal
    const objId = id || uuid();
    try {
      const instInternal: HaborInstanceInternal<T> = await this.haborSDK.createInstance({ nounId: this.name, id: objId, payload: obj }, this.token);
      this.emitter.emit("create", instInternal);

      console.log("Created Instance: " + JSON.stringify(instInternal));
      return instInternal;
    } catch (err) {
      console.log("Habor Noun Create Fail: " + JSON.stringify(err));
      throw err;
    }
  }

  public async retrieveObj(): Promise<{
    [id: string]: NounServiceInstanceInternal<T>;
  }> {
    const instsInternal = await this.haborSDK.searchInstances(this.token, {
      nounId: this.name,
    });
    const obj = {};
    instsInternal.forEach((inst) => (obj[inst.id] = inst));
    this.emitter.emit("retrieveObj", obj);
    return obj;
  }

  public async retrieveAll(): Promise<NounServiceInstanceInternal<T>[]> {
    const instsInternal = await this.haborSDK.searchInstances(this.token, {
      nounId: this.name,
    });
    this.emitter.emit("retrieveAll", instsInternal);
    return instsInternal;
  }

  public async search(searchParams: NounSearchParams): Promise<NounServiceInstanceInternal<T>[]> {
    const instances = await this.haborSDK.searchInstances(this.token, {
      nounId: this.name,
      ...searchParams,
    });
    this.emitter.emit("search", instances);
    return instances;
  }

  public retrieveHook(identifier: any) {
    const [item, setItem] = React.useState<NounServiceInstanceInternal<T> | undefined>(undefined);

    const getItem = async () => {
      if (!identifier) {
        return undefined;
      }
      const _item = await this.retrieve(identifier);
      setItem(_item);
    };
    React.useEffect(() => {
      getItem();
    }, []);

    React.useEffect(() => {
      getItem();
    }, [identifier]);

    return item;
  }

  public async retrieve(
    identifier: any //  Can be either a string (ID) or a JSON object address (with an ID) or a stringified JSON object with an ID.
  ): Promise<NounServiceInstanceInternal<T> | undefined> {
    //  Check if it's an address
    let id = undefined;
    try {
      id = JSON.parse(identifier).id;
    } catch (err) {
      id = identifier?.id || identifier;
    }

    const instsInternal = await this.haborSDK.searchInstances(this.token, {
      nounId: this.name,
    });
    //  TODO:  Use the Search Method
    // const instsInternal = await this.haborSDK.searchInstances(this.token, { nounId: this.name, search: { match: { id: objId } } });
    const inst = instsInternal.find((inst) => inst.id === id);
    this.emitter.emit("retrieve", inst);
    return inst;
  }

  public async update(objId: string, obj: T) {
    const instInternal: HaborInstanceInternal<T> = await this.haborSDK.updateInstance(objId, { nounId: this.name, id: objId, payload: obj }, this.token);
    this.emitter.emit("update", instInternal);
    return instInternal;
  }

  public async delete(objId: string) {
    const inst = this.retrieve(objId);
    await this.haborSDK.deleteInstance(this.name, objId, this.token);
    alert("Deleted " + this.name + ": " + JSON.stringify(inst));
    this.emitter.emit("delete", inst);
  }
}

export type ClassEntity = NounEntity | InstanceEntity<any>;

//  NOTE:  These are the "Entity" types that we get from the "Class" system.  It's up to the CLASS-ENTITY coupling system to define ontological structure that works for IT!
export interface NounEntity {
  type: "noun";
  noun: NounInternal;
}

export interface InstanceEntity<T> {
  type: "instance";
  instance: NounServiceInstanceInternal<T>;
}

export abstract class INounService<T> {
  public abstract init;
  public abstract create(obj: T): NounServiceInstanceInternal<T> | Promise<NounServiceInstanceInternal<T>>;

  public abstract retrieveObj(): { [id: string]: NounServiceInstanceInternal<T> } | Promise<{ [id: string]: NounServiceInstanceInternal<T> }>;

  public abstract retrieveAll(): NounServiceInstanceInternal<T>[] | Promise<NounServiceInstanceInternal<T>[]>;

  public abstract retrieve(objId: string): (NounServiceInstanceInternal<T> | undefined) | Promise<NounServiceInstanceInternal<T> | undefined>;

  public abstract update(objId: string, obj: T): Promise<NounServiceInstanceInternal<T>>;

  public abstract search(searchTerms?: NounSearchParams): NounServiceInstanceInternal<T>[] | Promise<NounServiceInstanceInternal<T>[]>;

  public abstract delete(objId: string);
}

export interface NounService<T> extends NamedObject, Identifiable {
  service: INounService<T>;
}

export interface MultiNounAddress {
  type: "multi-noun-address";
  serviceId: string;
  address: any;
}

export interface MultiNounSearchParams extends NounSearchParams {
  //  TODO:  SUpport system predicate match, and a system search swith the compliexicy of ANY ontological searcn mm!
  //  CONSIDER:  This is just ONE more abstraction.  We should NOT be doing this expliclty, because thre's ALWAYS another abstraction!!!
  systemIds?: string[];
}

//  CONSIDER:  How can we GENERALIZE this pattern.. JUST an ontological strucure / mapping.. EVEN when we go "out" to "extenral" servers, it's STILL just getting an ENCODING that it can navigate as an ontological structure! hmm THEY just hold them hm!
export class MultiNounService<T> implements INounService<T> {
  public emitter = new EventEmitter();
  public services: NounService<T>[] = [];

  //  CONSIDER:  IF the same ID exists in MULTIPLE stores, do we COLLAPSE?  Do we automatically sync data? Hmm... interesting.
  constructor(protected name: string, protected initialServices: NounService<T>[], protected defaultWriteServiceId?: string) {
    initialServices.forEach((service) => this.services.push(service));
  }

  public registerService = (service: NounService<T>) => {
    this.services.push(service);
  };

  public setDefaultWriteService = (serviceId: string) => {
    this.defaultWriteServiceId = serviceId;
  };

  public async init() {
    for (const service of this.services) {
      await service.service.init();
    }
    this.emitter.emit("init");
  }

  private getDefaultWriteService = () => {
    const service = this.services.find((_service) => _service.id === this.defaultWriteServiceId);
    if (!service) {
      throw `No registered NounService was foudn with ID: ${this.defaultWriteServiceId}`;
    }
    return service;
  };
  public async create(obj: T, id?: string): Promise<NounServiceInstanceInternal<T>> {
    const writeService = this.getDefaultWriteService();
    const instInternal = await writeService?.service.create(obj);
    this.emitter.emit("create", instInternal);
    console.log("Created Instance: " + JSON.stringify(instInternal));
    return instInternal;
  }

  public async retrieveObj(): Promise<{
    [id: string]: NounServiceInstanceInternal<T>;
  }> {
    let allObj: { [id: string]: NounServiceInstanceInternal<T> } = {};
    for (const service of this.services) {
      const obj = await service.service.retrieveObj();
      //  TODO:  Consider duplicates / over-writes!
      allObj = { ...allObj, ...obj };
    }
    this.emitter.emit("retrieveObj", allObj);
    return allObj;
  }

  //  TODO:  Support Paging.  MAYBE we can START by supporting it on the SEARCH endpoint, THEN just use THAT for htis hm!
  public async retrieveAll(): Promise<NounServiceInstanceInternal<T>[]> {
    let allInstances: NounServiceInstanceInternal<T>[] = [];
    for (const service of this.services) {
      const instances = await service.service.retrieveAll();
      //  TODO:  Consider duplicates!
      allInstances.push(...instances);
    }
    this.emitter.emit("retrieveAll", allInstances);
    return allInstances;
  }

  //  CONSIDER:  We CAN use something like GraphQL to search over all.  BUT we may want MORE than can JUST fit in the one thing.. we MAY have shared CONTEXT between the systems.  Though... the idea is reducing state with REST, but I don't think we NEED to transfer state like that hmm.... the state CAN be highly complex hmm!
  //  TODO:  Consider limiting search to certain systems?
  //  TODO:  Consider a system to HANDLE search between the systems.. hm... like Google? Hm... fuck.  I DO like that we have an ABSTRACTION for these thigns though hm!  Wwant to keep pushing mm!!  I think just DOING things is a huge part of it.. and SO many people, Darwin, Edison, freaking... SO many and I disagree with a lot of Darwin's stuff and ugh!  I think LOTS of people have belief in themselves and background and the right combination hmm.... I think it's about belief.  Train it, foster it, BELIEVE you can mke your own luck hm...  Plus lots of people who did it without this ugh!  Jobs to s degree, and probably lots of other people lie that.  The point is... those people were drivena nd had purpose and yeah hm!  Now... I'm driven too, for y own reasons hm!
  //  CONSIDER:  We might have SYSTEM level search!  LIKE GraphQL where THEY can abstract the concepts hmm... I THINK we DO want something like this ugh!
  public async search(searchParams?: MultiNounSearchParams): Promise<NounServiceInstanceInternal<T>[]> {
    let allInstances: NounServiceInstanceInternal<T>[] = [];

    const systemIds = searchParams?.systemIds;

    for (const service of this.services) {
      if (systemIds && !systemIds.includes(service.id)) {
        continue;
      }

      try {
        const instances = await service.service.search(searchParams);
        allInstances.push(...instances);
      } catch (err) {
        console.log("Failed to load instances from '" + service.name + "' service.  If undefined, it should return an empty array instead of throwing an error.  Here's the error: " + (err as any).message);
      }
    }
    //  TODO:  Make this a STANDARD thing ahh!!!  It's JUST another pattern!  It's the pattern of a THING being initiated or finished eetc hm!  We CAN have "state" and the idea is... it FOLLOWS somethin glike a process or INDICATES soem thing ugh! FUCK I miss Taylor so much ughH!!!!
    this.emitter.emit("search", allInstances);
    return allInstances;
  }

  //  NOTE:  In the "Multi" noun service, the ID is a stringified version of a JSON MultiNounAddress object.  This is used to determine which service to route to.
  //         If it does NOT meet that form with the specified type field or fail to validate, then we iterate each.
  //  TODO-GENERAL:  Be more "declarative" about the types.  The KEY IDEA is... abstraction.  It's JUST a way to specify a thing and let an INTERPRETER figure it out.
  public async retrieve(
    identifier?: any //  ID (for any service to handle), or a MultiNounAddress (stringified or parsed)
  ): Promise<NounServiceInstanceInternal<T> | undefined> {
    //  Guard Undefined
    if (!identifier) {
      return undefined;
    }

    //  Get MultiNounAddress (if available)
    let multiAddress: MultiNounAddress | undefined = undefined;

    //  Check for a parsed MultiNounAddress
    //  TODO:  Put an explicit type on the entity!!!
    if (identifier.systemId) {
      multiAddress = identifier;

      //  Check for a stringified MultiNounAddress
    } else {
      try {
        multiAddress = JSON.parse(identifier);
      } catch (err) {
        console.log("Could not parse multi-address.  It's likely an ID");
        alert(JSON.stringify(identifier));
      }
    }

    //  Retrieve by MultiAddress
    if (multiAddress) {
      let designatedService = this.services.find((service) => service.id === multiAddress?.serviceId);
      if (!designatedService) {
        console.log(`No service found for '${multiAddress?.serviceId}'.  Falling back to 'entity' service - which is the entity cache.`);
        designatedService = this.services.find((service) => service.id === "entity");
      }
      const instance = await designatedService?.service.retrieve(multiAddress?.address);
      this.emitter.emit("retrieve", instance);
      return instance;
    }

    // //  Fallback to Global Serach
    // else {
    //   let allInstances: (NounServiceInstanceInternal<T> | undefined)[] = [];
    //   for (const service of this.services) {
    //     try {
    //       const instance = await service.service.retrieve(identifier);
    //       if (instance) {
    //         allInstances.push(instance);
    //       }
    //     } catch (err) {
    //       alert(
    //         "Failed to load noun from " +
    //           service.name +
    //           " service.  If undefined, it should return undefined instead of throwing an error."
    //       );
    //     }
    //   }
    //   if (allInstances.length > 1) {
    //     console.warn("Multiple services reported a noun with the same ID.");
    //   }

    //   const instance = allInstances.length ? allInstances[0] : undefined;
    //   this.emitter.emit("retrieve", instance);
    //   return instance;
    // }
  }

  public async update(objId: string, obj: T) {
    const writeService = this.getDefaultWriteService();
    const instInternal = await writeService?.service.update(objId, obj);
    this.emitter.emit("update", instInternal);
    return instInternal;
  }

  public async delete(objId: string) {
    const writeService = this.getDefaultWriteService();
    await writeService?.service.delete(objId);
    this.emitter.emit("delete", objId);
  }
}

//  TODO:  INSTEAD of making an "AsyncStorageNounService", we INJECT into the "NounService"... we do this instead of inheriting.  WHY?  Because then we can make several combinations without a weird new symbol and duplicate stuff?? Hmm... it's somethin we can do at runtime, and not just static? Hmm.. we COULD do it beforre? Hmm it's possible to add multiple functions, not just a chain? hmm...   MAYB we can have system-wide injection? Hmm SO when a sytem matches some expecationas we can inject the whole thing without calling the pieces.  This is differece from the class thing, where it internally makes an overrriding object that can "invoke" parents.. maybe say service.use() hmm... maybee... perhaps we can support extension too? hmm.. serrvice.extend().. then within thaat we can still "call" the children? Hmm.. MAYBE..   MAYBE this whole THING is pluggbale so we can add the featurres we need, ... the POINT is it's used to build an ENCODING?? HM!  That's the point os ALL of this? HM!  So... we do that in a way that makes sense o us? Hm.. WITHOUT convlutig the LANGUAGE with ew primitives? Hmm Maye... but I can see peole wrritting wrsppers and stuff.. hmm fuck.  Classes don't make it easy to add DECORATORS, which is essentially what we'll be doing? Hmm... different from extension.. hmm Fuck again.  it's ALL about interpetation.  WHY though, should I bother with ANY of that instead of just using the system as is?  MAYBE I want to give that to users? Hmm definitly something to keep thinking abotu..

//  The GENERAL idea is to make "Entities", and then "Associations"... hmm...

//  TODO:  Generalize the NounService, AND the EntityService to pull from EITHER local or Remote hm!  MAYBE I can do both now? HM!

//  NOTE:  A "class" is JUST a FUNCTION (constructor) along with some functions and properties that will be associated with the constructed object.  SO much of life is JUST mapping!!!
//  CONSIDER:  This is for an existing thing that's being edited externally? Hmm...
export class MemoryNounService<T> implements INounService<T> {
  public emitter = new EventEmitter();

  constructor() {}

  public items: NounServiceInstanceInternal<T>[] = [];

  public init() {
    this.emitter.emit("init");
  }

  public search(searchParams: NounSearchParams) {
    const items = this.items;
    const filteredItems: NounServiceInstanceInternal<T>[] = searchObjects<NounServiceInstanceInternal<T>>(searchParams.search || { match: {} }, items);
    this.emitter.emit("search", filteredItems);
    return filteredItems;
  }

  public create(obj: T, id?: string): NounServiceInstanceInternal<T> {
    const existing = id ? this.retrieve(id) : undefined;
    if (existing) {
      throw new Error(`Object exists error.`);
    }

    const objId = id || uuid();
    const nounInternal: NounServiceInstanceInternal<T> = {
      created: new Date().toISOString(),
      updated: undefined,
      id: objId,
      payload: obj,
    };
    this.items.push(nounInternal);
    this.emitter.emit("create", nounInternal);
    return nounInternal;
  }

  public retrieveObj(): {
    [id: string]: NounServiceInstanceInternal<T>;
  } {
    const obj = {};
    this.items.forEach((item) => {
      obj[item.id] = item;
    });
    this.emitter.emit("retrieveObj", obj);
    return obj;
  }

  public retrieveAll(): NounServiceInstanceInternal<T>[] {
    this.emitter.emit("retrieveAll", this.items);
    return this.items;
  }

  public retrieve(objId: string): NounServiceInstanceInternal<T> | undefined {
    const item = this.items.find((item) => item.id === objId);
    this.emitter.emit("retrieve", item);
    return item;
  }

  public async update(objId: string, obj: T) {
    //  Get the Original
    const item = this.retrieve(objId);
    if (!item) {
      throw `Object not found: '${objId}' by memory noun service.`;
    }

    item.updated = new Date().toISOString();
    item.payload = obj;

    this.emitter.emit("update", item);
    return item;
  }

  public delete(objId: string) {
    const index = this.items.findIndex((item) => item.id === objId);
    this.items.splice(index, 1);
    this.emitter.emit("delete", objId);
  }
}

export class AsyncStorageNounService<T> implements INounService<T> {
  //  NOTE:  The "constructor" configures the set of functions with config!  It's ALSO just a way to GROUP code elements!
  //  AGAIN, this is an example of a bunch of THINGS which are ASSOCIATED with another, THEN we CONFIGURE an "instance" of this with an OBJECT and use it!  We can perhaps use this pattern in LOTS of other ways too? HM!  It's got a visualizeaion in the FO.
  //  TODO:  IF we had a Graph DB, we could keep this "metadata" separated in another object!  Hmm!  I THINK I like that idea better perhaps... hmmm...There are no rules though... better to write ON the pink paper, OR attach a piece of pink paper? Hmmm... BOTH give what we want, BUT the attachment seems easierr to swap out!  BUT the other is perhaps easier to manage and see if we're SURE...  BUT, all of this DOES make me ask the question, WHY aren't we using something like a Graph DB?  BUT, where multiple systems are registered which have a stake? Hmm.. MAYBE we can use a "Relation" as a primitive thing, and even the NODES will still be stored in their RESPECTIVE systems.  BUT that means things have a stake and it's NOT coupled.. hmm EVEN in ONE app, this CAN be helpful!  THIS way, everything has a UNIQUE ID within that space!? HM!  THEN we can associate with multiple systems.  I DO like that idea... LOL... going in circles??? BUT the problem is complexity? Hmm... BUT make a simple way to register "nodes" then a simple way to register "contexts" which can associate with them and build their OWN meaning with nodes / relations.  Hmmm... MABE we can support typed relations... idk... I'm not sure HOW complex I want to make that... It COULD just be PATTERNS after all... PLUS, do I REALLY just want to NAME a context... hmmm

  //  CONSIDER:  Should this be injected?  IF all things have an injectable pattern, then it can be.. and I SUPPOSE they do, with the PROPERY DESCRIPTOR? Hmm..
  public emitter = new EventEmitter();
  constructor(protected name: string) {}

  //  CONSIDER:  MAYBE the idea is instead of REGisTERING we DO want to do it like Plugins... we want to use the dependency network.. hmm This way we can inject at the right point? Hmm.. maybe...  This way, it's like. hmm..

  public async clear() {
    await AsyncStorage.setItem(this.name, JSON.stringify({}));
  }

  public async init() {
    // await AsyncStorage.clear();
    const entries = await AsyncStorage.getItem(this.name);
    if (!entries) {
      await AsyncStorage.setItem(this.name, JSON.stringify({}));
    }
    this.emitter.emit("init");
    return this;
  }

  //  WHY have ONE encoding which we INJECT pieces INTO when we COULD have NO encoding and EVERYTHING is injected? Hmm... maybe we can KINDA already think of it like that... we have the thing createed by interpretation of the first encoding, THEN the updaated encodings which are added and to be interpreted.. hmm..   LIke a world without explicit Functions Hmm.. idk man... Maybe each "neuron" in a neural net is staked by its "weights"? Hmm...

  public async search(searchParams: NounSearchParams): Promise<NounServiceInstanceInternal<T>[]> {
    //  TODO:  Support this without returning all.
    const items = await this.retrieveAll();
    const filteredItems = searchObjects(searchParams.search || { match: {} }, items);
    return filteredItems;
  }

  public async create(obj: T, id?: string): Promise<NounServiceInstanceInternal<T>> {
    //  Make the NounInternal
    const objId = id || uuid();
    const nounInternal: NounServiceInstanceInternal<T> = {
      created: new Date().toISOString(),
      updated: undefined,
      id: objId,
      payload: obj,
    };

    //  Retrieve
    const objs = await this.retrieveObj();
    objs[objId] = nounInternal;

    //  Update
    await AsyncStorage.setItem(this.name, JSON.stringify(objs));

    this.emitter.emit("create", nounInternal);

    console.log("Created Model: " + JSON.stringify(nounInternal));
    return nounInternal;
  }

  public async retrieveObj(): Promise<{
    [id: string]: NounServiceInstanceInternal<T>;
  }> {
    const sObj = await AsyncStorage.getItem(this.name);
    const objs = sObj ? JSON.parse(sObj) : {};
    //  CONCERN:  Shoudl this be mutable? hmm  This is a LOT like Habor!!!
    this.emitter.emit("retrieveObj", objs);
    return objs;
  }

  //  TODO:  Cache!
  public async retrieveAll(): Promise<NounServiceInstanceInternal<T>[]> {
    const sObj = await AsyncStorage.getItem(this.name);
    const objs = sObj ? JSON.parse(sObj) : {};
    const list = Object.keys(objs).map((key) => objs[key]);
    this.emitter.emit("retrieveAll", list);
    return list;
  }

  public async retrieve(objId: string): Promise<NounServiceInstanceInternal<T> | undefined> {
    //  TODO:  Slow operation because it needs to deserialize EACH time... not good.
    const objs = await this.retrieveAll();
    const element = objs.find((obj) => obj.id === objId);
    //  TODO:  Pass as an object so it can be mutated?
    this.emitter.emit("retrieve", element);
    return element;
  }

  public async update(objId: string, obj: T) {
    //  Get the Original
    const original = await this.retrieve(objId);
    if (!original) {
      throw `Object not found: '${objId}' for Noun: '${this.name}'`;
    }

    //  Make the NounInternal
    const nounInternal: NounServiceInstanceInternal<T> = {
      ...original,
      updated: new Date().toISOString(),
      id: objId,
      payload: obj,
    };

    //  Retrieve
    const objs = await this.retrieveObj();
    objs[objId] = nounInternal;

    //  Update
    await AsyncStorage.setItem(this.name, JSON.stringify(objs));

    //  TTODO:  We ARE duplicating concepts.. if someotn ELSE wated to implement NounService WITHOUT async storrage, we'd need to reintroduce these emits...  shit, but I knew that.. I think? Hmm so, once we follow the other notes, should be able to avoid that.
    this.emitter.emit("update", nounInternal);
    return nounInternal;
  }

  public async delete(objId: string) {
    const objs = await this.retrieveObj();
    delete objs[objId];
    await AsyncStorage.setItem(this.name, JSON.stringify(objs));
    this.emitter.emit("delete", objId);
  }
}

export interface S3Config {
  key: string;
  secret: string;
  region: string;
  bucket: string;
  bucketKey: string;
}

//  REFERENCE:  ChatGPT
export async function saveToS3(data: any, config: S3Config) {

  const client = new S3Client({
    region: config.region,
    credentials: {
      accessKeyId: config.key,
      secretAccessKey: config.secret,
    },
  });

  const params = {
    Bucket: config.bucket,
    Key: config.bucketKey,
    Body: JSON.stringify(data),
  };

  const uploadCommand = new PutObjectCommand(params);

  try {
    const res = await client.send(uploadCommand);
    console.log("Saved to S3: " + JSON.stringify(res));
    return res;
  } catch (err: any) {
    console.log(err);
    alert("S3 Upload Error!!!: " + err.message);
  }
}

export async function getFromS3(config: S3Config) {
  // const s3 = new S3({
  //   accessKeyId: config.key,
  //   secretAccessKey: config.secret,
  //   region: config.region,
  // });

  // alert(JSON.stringify(config));

  const client = new S3Client({
    // The AWS Region where the Amazon Simple Storage Service (Amazon S3) bucket will be created. Replace this with your Region.
    region: config.region,
    credentials: {
      accessKeyId: config.key,
      secretAccessKey: config.secret,
    },
  });

  // const params = {
  //   Bucket: config.bucket,
  //   Key: config.bucketKey,
  //   CacheControl: "no-cache, no-store, must-revalidate",
  //   Expires: new Date(0),
  // };

  const params = {
    Bucket: config.bucket,
    Key: config.bucketKey,
    ResponseCacheControl: "no-cache, no-store, must-revalidate"
  };

  const readCommand = new GetObjectCommand(params);

  try {
    const res = await client.send(readCommand);
    const jsonString = await res.Body?.transformToString();
    const jsonObject = JSON.parse(jsonString || "{}");
    return jsonObject;
  } catch (err: any) {
    console.log(err);
    alert("S3 Read Error!!!: " + err.message);
  }
}

const AWS = require("aws-sdk");

//  REFERENCE:  ChatGPT
async function ensureObjectExists(config: S3Config) {
  const s3 = new AWS.S3({
    accessKeyId: config.key,
    secretAccessKey: config.secret,
    region: config.region,
  });

  try {
    // Check if object exists
    await s3
      .headObject({
        Bucket: config.bucket,
        Key: config.bucketKey,
      })
      .promise();

    console.log("Object already exists.");
  } catch (error: any) {
    if (error.code === "NotFound") {
      // If object does not exist, create it
      try {
        await s3
          .putObject({
            Bucket: config.bucket,
            Key: config.bucketKey,
            Body: "{}",
          })
          .promise();

        console.log("Object created successfully.");
      } catch (putError) {
        console.error("Error creating object:", putError);
      }
    } else {
      console.error("Error checking object existence:", error);
    }
  }
}

export class S3NounService<T> implements INounService<T> {
  public emitter = new EventEmitter();
  public config: S3Config;

  constructor(protected name: string, config: S3Config) {
    this.config = config;
  }

  public async clear() {
    const allData = await getFromS3(this.config);
    allData[this.name] = {};
    await saveToS3(allData, this.config);
  }

  public async init() {
    await ensureObjectExists(this.config);
    this.emitter.emit("init");
    return this;
  }

  public async search(searchParams: NounSearchParams): Promise<NounServiceInstanceInternal<T>[]> {
    const items = await this.retrieveAll();
    const filteredItems = searchObjects(searchParams.search || { match: {} }, items);
    return filteredItems;
  }

  //  TODO:  Store all VERSIONS of each object.  CAN take a lot of storage space for objects which change a lot of large objects?

  public async create(obj: T, id?: string): Promise<NounServiceInstanceInternal<T>> {
    //  Make the NounInternal
    const objId = id || uuid();
    const nounInternal: NounServiceInstanceInternal<T> = {
      created: new Date().toISOString(),
      updated: undefined,
      id: objId,
      payload: obj,
    };

    //  Update All Data
    const allData = await getFromS3(this.config);
    const nounData = allData[this.name];
    if (!nounData) {
      allData[this.name] = {};
    }
    allData[this.name][objId] = nounInternal;
    await saveToS3(allData, this.config);

    this.emitter.emit("create", nounInternal);
    console.log("Created S3 Instance: " + JSON.stringify(nounInternal));
    return nounInternal;
  }

  public async retrieveObj(): Promise<{ [id: string]: NounServiceInstanceInternal<T> }> {
    const allData = await getFromS3(this.config);
    const nounData = allData[this.name] || {};
    this.emitter.emit("retrieveObj", nounData);
    return nounData;
  }

  public async retrieveAll(): Promise<NounServiceInstanceInternal<T>[]> {
    const nounData = await this.retrieveObj();
    const list = Object.keys(nounData).map((key) => nounData[key]);
    this.emitter.emit("retrieveAll", list);
    return list;
  }

  public async retrieve(objId: string): Promise<NounServiceInstanceInternal<T> | undefined> {
    const objs = await this.retrieveObj();
    const element = objs[objId];
    this.emitter.emit("retrieve", element);
    return element;
  }

  public async update(objId: string, obj: T) {
    const original = await this.retrieve(objId);
    if (!original) {
      alert(`Object not found: '${objId}' for Noun: '${this.name}'`);
      throw `Object not found: '${objId}' for Noun: '${this.name}'`;
    }

    //  Make the NounInternal
    const nounInternal: NounServiceInstanceInternal<T> = {
      ...original,
      updated: new Date().toISOString(),
      id: objId,
      payload: obj,
    };

    const delay = async (n: number) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, n);
      });
    };

    //  Update All Data
    const allData = await getFromS3(this.config);

    const nounData = allData[this.name];
    if (!nounData) {
      allData[this.name] = {};
    }
    allData[this.name][objId] = nounInternal;
    await saveToS3(allData, this.config);

    this.emitter.emit("update", nounInternal);
    return nounInternal;
  }

  public async delete(objId: string) {
    const existing = await this.retrieve(objId);
    if (!existing) {
      alert("Object does not exist: " + objId);
      throw new Error("The object does not exist");
    }
    //  Update All Data
    const allData = await getFromS3(this.config);
    const nounData = allData[this.name];
    if (!nounData) {
      allData[this.name] = {};
    }
    delete allData[this.name][objId];
    await saveToS3(allData, this.config);
    this.emitter.emit("delete", objId);
  }
}

/**
 *  Caches the data in AsyncStorage and syncs to S3 as well.
 *  TODO:  Consider using the multi-service or abstracting the need to cache. 
 */
export class CachedS3NounService<T> implements INounService<T> {
  public emitter = new EventEmitter();
  public config: S3Config;
  public s3Service: S3NounService<T>;
  public asyncStorageService: AsyncStorageNounService<T>;

  constructor(protected name: string, config: S3Config) {
    this.s3Service = new S3NounService(name, config);
    this.asyncStorageService = new AsyncStorageNounService(name);
    this.config = config;
  }

  public async clear() {
    await this.s3Service.clear();
    await this.asyncStorageService.clear();
    this.s3Service.clear();
  }

  public async init() {
    await this.s3Service.init();
    //  Load from S3
    //  NOTE:  This will be a list of IDs
    const data = await this.s3Service.retrieveObj();
    await AsyncStorage.setItem(this.name, JSON.stringify(data));
    await this.asyncStorageService.init();
    this.emitter.emit("init");
    return this;
  }

  public async search(searchParams: NounSearchParams): Promise<NounServiceInstanceInternal<T>[]> {
    return await this.asyncStorageService.search(searchParams);
  }

  public async create(obj: T, id?: string): Promise<NounServiceInstanceInternal<T>> {
    try {
      const s3Result = await this.s3Service.create(obj, id);
      const localResult = await this.asyncStorageService.create(obj, id);
      console.log("S3 Result: " + JSON.stringify(s3Result));
      return localResult;
    } catch (err) {
      console.error(err);
      alert(err);
      throw err;
    }
  }

  public async retrieveObj(): Promise<{ [id: string]: NounServiceInstanceInternal<T> }> {
    return await this.asyncStorageService.retrieveObj();
  }

  public async retrieveAll(): Promise<NounServiceInstanceInternal<T>[]> {
    const list = await this.asyncStorageService.retrieveAll();
    this.emitter.emit("retrieveAll", list);
    return list;
  }

  public async retrieve(objId: string): Promise<NounServiceInstanceInternal<T> | undefined> {
    const element = await this.asyncStorageService.retrieve(objId);
    this.emitter.emit("retrieve", element);
    return element;
  }

  public async update(objId: string, obj: T) {
    this.s3Service.update(objId, obj);
    const nounInternal = await this.asyncStorageService.update(objId, obj);
    this.emitter.emit("update", nounInternal);
    return nounInternal;
  }

  public async delete(objId: string) {
    this.s3Service.delete(objId);
    return await this.asyncStorageService.delete(objId);
  }
}


//  NOTE:  FOR NOW, let's use use the Habor Noun sstem haha hmm... VERY interseitng.. it's essnetially the MODEL system hm!
//  TODO:  HOW can I do this withotu a schema???  Maybe it's fine? Hmm...  Fuck
//  THOUGHT:  Maybe we can just use the Model service hm!  THIS way, even the Noun Service can be implememted with the model servicie? Hmmm.. not sure.

// export class HaborNounService<T> implements INounService<T> {

//   public emitter = new EventEmitter();
//   constructor (protected name: string, protected params: { token: string }) {}

//   public async init () {

//     const nounServiceNoun: Noun = {
//       name: "noun_service_noun",
//       id: "noun_service_noun",
//       properties: {
//       },
//       description: 'Noun Service Noun'
//     };
//     await haborSDK.createNoun(nounServiceNoun, this.params.token);
//   }

//   public async create (obj: T, id?: string): Promise<InstanceInternal<T>> {

//     //  Make the Noun
//     //  TODO:  Make sure the custom ID works!
//     //  TODO:  Ability to EXTEND these systems with Plugins, MAYBE even changing the set of TYPESCRIPT types and all that hm!  This way we can add new system features and have it change EVEN in the IDE andstuff hm!  MULTIPLE systems interacting hm!

//     const objId = id || uuid();

//     const instance: Instance = { nounId: "noun_service_noun", id: objId, payload: obj };
//     const haborNounInternal: HaborInstanceInternal<T> = await haborSDK.createInstance(instance, this.params.token);

//     const otherNounInternal: InstanceInternal<T> = {  }
//     this.emitter.emit("create", nounInternal);

//     console.log("Created Model: " + JSON.stringify(nounInternal));
//     return nounInternal;
//   };

//   public async retrieveObj(): Promise<InstanceInternal<T>[]> {
//     const sObj = await AsyncStorage.getItem(this.name);
//     const objs= sObj ? JSON.parse(sObj) : {};
//     //  CONCERN:  Shoudl this be mutable? hmm  This is a LOT like Habor!!!
//     this.emitter.emit("retrieveObj", objs);
//     return objs;
//   }

//   //  TODO:  Cache!
//   public async retrieveAll (): Promise<InstanceInternal<T>[]> {
//     const sObj = await AsyncStorage.getItem(this.name);
//     const objs = sObj ? JSON.parse(sObj) : {};
//     const list = Object.keys(objs).map(key => objs[key]);
//     this.emitter.emit("retrieveAll", list);
//     return list;
//   }

//   public async retrieve (objId: string): Promise<InstanceInternal<T> | undefined> {
//     //  TODO:  Slow operation because it needs to deserialize EACH time... not good.
//     const objs = await this.retrieveAll();
//     const element = objs.find(obj => obj.id === objId);
//     //  TODO:  Pass as an object so it can be mutated?
//     this.emitter.emit("retrieve", element);
//     return element;
//   }

//   public async update (objId: string, obj: T) {

//     //  Get the Original
//     const original = await this.retrieve(objId);
//     if (!original) { throw `Object not found: '${ objId }' for Noun: '${ this.name }'` }

//     //  Make the NounInternal
//     const nounInternal: InstanceInternal<T> = { ...original, updated: new Date().toISOString(), id: objId, payload: obj };

//     //  Retrieve
//     const objs = await this.retrieveObj();
//     objs[objId] = nounInternal;

//     //  Update
//     await AsyncStorage.setItem(this.name, JSON.stringify(objs));

//     //  TTODO:  We ARE duplicating concepts.. if someotn ELSE wated to implement NounService WITHOUT async storrage, we'd need to reintroduce these emits...  shit, but I knew that.. I think? Hmm so, once we follow the other notes, should be able to avoid that.
//     this.emitter.emit("update", nounInternal);
//     return nounInternal;
//   }

//   public async delete (objId: string) {
//     const objs = this.retrieveObj();
//     delete objs[objId];
//     await AsyncStorage.setItem(this.name, JSON.stringify(objs));
//     this.emitter.emit("delete", objId);
//   }
// }

// //  NORRMALLY when we build a system we start by building an ENCODING.  This is how we'll store our state and OFTEN we'll use a model builder or something for that.
// //  To keep things simple, I'll do it MANUALLY here.  BUT the idea is, in the future, it SHOULD be possible to SYNC with Hessia AND even use those APIs and stuff... EVENTUALLY even injecting to extend and work on the detail view and stuff..  BUT this will help me get my head around the Weave model and show people some simple examples.

// //  MAYBE everuything is THINGS and INTERPETERS> HM!

// //  NOTE:  INSTEAD of storing types and stuff as SEPARATE, leave that to another Caching layer? hmm...

// // MAYBE though, the pattern of being able to show all thigns ASSOCIATED with a node is USEFUL...  LIke.. We COULD perhaps store entities in a giant list, BUT when we want to know ASSOCIATIONS.. hmm..The whole point is it's up to us to interpret? Hmm.. AND perhaps this idea of a "relation" IS conceptually a separate thing?  We CAN model it with entitiesz.. hmm.... like DIFFERRENT places.. hmmm... MULTIPLE lists? Hmm.. but CONCEPTUALLY? HMmmmmmm.. EVEN the placement into another group makes an IMPLIED association.  It's a PATTERN that we can recognize. hmmm... EVEN the ID thing and DICTIONARY is a thing.. we COULD just make a giant BLOB... the problem is then we need to like interpret the WHOLE blob... so instead, we break it up in ways that make sense to us... hmmm...  thinkg about what the blob interpreter would be DOING.  If it's finding specific entities or whatever, identifying PATTERNS, perhaps we can separate it out??? Hmmm...  when we do that, we're thing in MULTIPLE places.  Those PLACES have MEANING to the system... hmmm... it's something that COULD be done in one.  BUT the idea is, the INTERPRETER is going to do that ANYWAYS, so this is kinda the first optimization? Hmmm.. MAYBE we can build that with the framework? Hmmm...  MAYBE we call this a "Group"? Hmm... Then it's up to us what we do with that and its meaning? Hmmm.... PERHAPS even the GROUPS are THINGS / ENTITIES, and we associate stuff with the group? Hmm...
// //  AH!!  MAYBE we don't always need an "thing".  A SYSTEM MAY not have an "thing"? Hmm... it MAY still have a name and shit though.  BUT perhaps the system has its OWN entities? Hmm... does that mean it needs to use the same physical space / thing as the others? Hmm... well part of the thing is, trying to figure out the SHARED "trueh".. ugh, behind all of them?
// //  Maybe the point is the false dichotomy of thing / decoding.  In the physical world outside of bits, i can use colored paper and stuff.. thigns htat have POSITION, COLOR, attributes, etc... in bits, EVERYTHIGN is the same.. just a string of bits, so that stuff is ENCODED.  Now... I COULD GROUP them separately like that and stuff, BUT there's a lot of systems that could have a stake in a thing.. hmm... MAYBE I should start JUST by defining the THING!  YES!  That's what I wanted to do.  THEN, "systems" have their OWN entities and breathe their OWN meaning into it... hm!  This way, I can do entities like store Associations using the Association system.  BUT, each association is ITSELF a thing. hmmmm....!

// //  ONE big point though, is I want to make a BUNCH of systems... I want them to work TOGETHER... Hmm.. MAYBE we can use something like "parent" WITHOUT a shared ontology, ONLY Parent being the shared, and REFERENCING the individual systems which THEn indicate what protocols they support.. hmm.. maybe.
// //  I want to be able to build QUICKLY though.  BUT there are DIFFERENVES between these things... hmm...

// //  Let's start with Tasks.  I think I have my head around the progression:

// //  IF we need a shared ontology, then PERHAPS we can register systems.
// //  IF we need to be able to INTROSPECT the systems themselves, LIKE if we use CLI or a Visual IDE, THEN we'll want to be able to encode them with API access... We COULD just do code gen, BUT I like the idea of systems which make theirr OWN encodings.. hmmm..  I THINK.  Pros and Cons... That way it's not "Code Gen", but it IS still building the SAME stuff with registers!

// //  AGAIN, a system needs to be able to INTERPRET soemthing... we need SOME truth there... fuck.

// //  The POINT is, up to us to encode and decode.  Then make it possible for OTHER systems we want to use to use that? Hmm...

// //
// //  Entity System
// //

// //  NOTE:  Stores the CREATOR of an Entity.
// //  CONCERN:  Is this derived?  WHY do we care about that specifically? Hmm...

// //  CONSIDER:  MAYBE this is MetaMesh, and there's NO need to store EVEYTHING here.. JUST things we WANT to represent.. hmm

// //  BUT, then again, there's the shit like ASSOCIATION, and what is it associating?  The idea is, we have SOME shared ontology.. MAYBE we have MULTIPLE though in various contexts, BUT I think global is OK for now.
// interface Entity {
//   id: string;
//   systemIds: string[];  //  CONCERN:  What about CHANGES to the entity? Hmm...  This will be in other systems.
// }
// const entities: Entity[] = [];

// export const makeEntity = (systemIds: string[]) => {
//   const entityId: string = uuid();
//   entities.push({ id: entityId, systemIds });
//   return entityId;
// }

// //  NOTE:  THIS doesn't have meaning outside of the SYSTEMS... which are the things which encode / decode.. SO, the IDEA is, as humans we OBSERVE this concept of existence, AND attributes, and we see that those are ASSOCIATED.  SO there's this OBSERVED pattern of ASSOCIATION that we can see? HM!
// // export const getEntity = (id: string) => {
// //   //  TODO:  Ping systems with a STAKE.
// //   entities[id];
// // }

// // export const deleteEntity = (id: string) => {
// //   //  TODO:  Ping systems with a STAKE.
// //   delete entities[id];
// // }

// // export const updateEntity = (id: string, thing: any) => {
// //   //  TODO:  Ping systems with a STAKE.
// //   entities[id] = thing;
// // }

// //
// //  Ownership System (encodes the owning system)
// //

// //  In the THING system... do we have explicit "relatioships" OR can those just be THINGS which REFER#NCE>. hmm... perhaps that's fine?

// //
// //  Stake System
// //  CONCERN:  Should we have explicit "stakes"?  OR, should systems register their own views and deal with this themselves? Hmm... Almost seems like a pre-atue optimization
// //  CONCERN:  Should we stake at the entity level?  MAybe moe like ABSTRACT PATTERNS? Hmm...
// //
// interface Stake {
//   systemIds: string[];
//   entityId: string;
// }
// const stakes: Stake[] = [];
// export const makeStake = (systemIds: string[], entityId: string) => {

//   //  NOTE:  We DON'T stake the stake, BECAUSE it's implied.  It's KNOWN to the system that a "Stake" is owned by the "Stake" system.
//   //  The idea is, this MAY be similar to the way the brain works.. identifying SYSTEMS which influence a thing? Hmmm..  Not sure though.  Seems more continuous?  hmm..

//   stakes.push({ systemIds, entityId });
// }

// //  NOTE:  FOR NOW, we're using a GLOBAL entity rregister.  BUT, we MAY want to make it contextual and have LOTS of ones... hmm

// //
// //  Association System
// //

// //  TODO:  This is OWNED by the Entity system... should it be DUPLICATED here?  I THINK it's ok to duplicate IDs.  It's a REFERENCE.  Should we include the SYSTEM? Hmm...

// interface Association extends Entity {
//   src: string;
//   dest: string;
//   directed: boolean;
// }

// const associations: Association[] = [];

// //  TODO:  Make a system to store the two-way association,

// export const associate = (src: string, dest: string, directed: boolean, systemIds: string[]) => {

//   //  Make the Entity
//   const associationId = makeEntity([...systemIds, "association"]);

//   //  Stake
//   makeStake([...systemIds, "association"], associationId);

//   associations.push({ id: associationId, src, dest, systemIds, directed });
// }

// export const getAssociations = ({ src, dest, directed }: { src?: string, dest?: string, directed?: boolean }) => {
//   return associations.filter(assoc => {
//     if (src && assoc.src != src) { return false; }
//     if (dest && assoc.dest != dest) { return false; }
//     if (directed && assoc.directed != directed) { return false; }
//     return true;
//   });
// }

// //  FOR NOW, let's make Task explicit.. hmm

// //  CONCERN:  I don't want to do ALL this for EACH "type" of thing... MAYBE I don't want to call it a TYPE though... maybe it's a group? hmm... the idea is, I create a GROUP to put objects in, and it's up to ME what that means.  I KINDA like that model.

// //  Again, it's up to EACH system how it wants to store data and stuff...  We CAN use a generic data storage system, BUT we don't HAVE to.  The idea is, it's up to the PLUGINS to inject meaning.  AND lots of them can be VERY similar, like "Tag" and "Group" and "Type".  MAYBE then we can build system TEMPLATES that we can use to build systems quickly.

// //  I DON'T want to make a bunch of systems like "NamedGroup" nad "NumberedGroup" or "DateGroup" and all that... INSTEAD, I want to be able to compose as needed with indepednent systems.  Hmm..  SO, I can build a TAG system which uses GROUPS, BUT it give it a NAME by associating it FIRST with a name.  THEN, I can make sure to only use THOSE groups.. OR perhaps a more generic system which ensures that the things that are created have a marked "Owner" system to make it easy to SCOPE? HM!

// //  We COULD make the system by building a GROUP and association the BLOBS with the group!@ HM!  THEN it's up to US what the blobs look like and conformance and stuff! HM!  Then we aren't pressuming any particular encoding to begin with, BUT even the blob is a JS object... which DOES seem to limit the endocing? Hmm..

// //  TODO:  Make a simple way to CRUD an encoding with a particular NAME, like Task... BUT maybe generalizE so it's not JUST a NAME.. it could be associated with ANY encoding, sound, image, etc? HM!  Or even a complex pattern? HMmmm  ... HERE, we do it wit hthe SYSTEM name.

// //  NOTE:  SCHEMAS are just GRAPHS with associations and stuff.. ENCODINGS.  SOMETIMES we don't NEED more encodings! HM!

// //
// //  Noun System
// //  NOTE:  Again, we DON'T add things like Name, Description, etc, BECAUSE those are all pieces we can add with OTHER systems? Hmm.. OR, like the Group thing we can store in the particular system? Hmm...  The idea is to only store the KEY thing.. in this case, we CALL it a "Noun"... hmm UGH.  That means it's going to be interpreted in a certain way.  BUT, we want to encode the CRUX of that and let the USERS add their pieces? Hmm....hmmmmm....  WHEN then, do we start actually adding things like NAMES... which DO matter to us.  MAYBE by the system which CARES about that? Hmm.. BTU if EVERY Noun has a name + description WHY defer storage of that to the PARENT? Hmm... Because it's up to that system what it wants to store AND other systems might want to store more? Hmmm... I feel like I'm coding in circles.
// //

// //  IN THIS CASE, like the others, we build a new system with Prrimitive code and a STRING... INSTEAD, let's contain that...

// //  NOTE:  AGAIN, we DON'T add a NAME here, BECAUSE we aren't specifying what a system IS... it's JUST a NODE used as a contextual space...  MAYBE we can do "Make Node" instead... well... we aleady have make Entity... so... yeah.  SHit... I feel like we went in another circle.  We COULD use the entity thing... Then the DEV user will "know" it's a SYSTEM .. hmm by their definition? Hmm...

// //  We have a PRIMITIVE Ontology of entities / associations that has MEANING and is INTERPRETED by this "Core" system!  PERHAPS it's possible to extend it, BUT the goal is to make it SO simple, that MOST extension are built WITH the system, not ON it? HM!  No matter WHAT system we build, it wouldn't make much sense to keep things SO undefined that it has no INTERPRETATION?? Hmm.. would it?  OR perhaps that IS how the brain worrks... it finds patterns and stuff, BUT the idea is only through CO-OCURRING networks in the brain? H!M  THIS way, patterns aren't associated with a matrix or anything, JUST co-EXPERRIENCES? HM!  Though, MAYBE there is a difference between left and right? hmmm...

// //  NOTE:  The "System" type is separate from "Entity" in the Core.  This makes it possible to distinguish between them.  The CORE is responsible for handling the Systems, WHILE the systems which are registered are responsible for handling and showing the Entities.  INCLUDING perhaps updates to themselves? HM!  PERHAPS building a "Named System" thing will make sense quickly? Hmm...
// export interface System extends Entity {
//   name: string;
//   elements: string[];
//   imports: string[];
//   exports: string[];
//   parentId?: string;  //  HM!!  This is neat!  It's like MODULE extension? HM!  MAYBE we can support MULTIPLE? Hmm...
// }

// const systems: { [name: string]: System } = {};

// //  OMG!  WOW, look how similar this looks to a NEURON.  The system has IMPORTS and EXPORTS... hmm... but it's INNTEPRRETED.. hmm... I guess the neuron is too kinda Hmm... all do the SAME thing though.. hmmm...right?  JUST the weights and inputs? Hmm... MAYBE it's similar with these? Hm...All the same entity type, BUT the associations that they have hmm.. idkk man.  Neurrons are like dot products right ?Hmm

// //  MAYBE to simulate the brain, we can use MULTIPLE, LOTS and maybe even an ENOURMOUS number of "interpreters" that draw their own "meaning" / "interprretation" and ultimately work together? hmm... idk... BUT those thigs change too? Hmm...

// //  NOTE:  ONE problem is dealing with this BETWEEN runs!  We want to be able to KEEP the systems we have defined.  MAYBE that's why we use a user-specified ID? hmm
// //         How do we deal with building complexity / context / generalization of the CORE? Hmm.. like USERS, and ORGANIZATIONS, etc... we CAN perhaps use CORE Plugins? Hmm... Not sure.
// //  CONSIDER:  MAYBE I can call these things with a "meta" object or perhaps a "context" which can be used to scope? hmm...
// //  NOTE:  FOR NOW, I guess we can use a custom provided ID.

// export const makeSystem = ({ name, parentId, systemIds }: { name: string, parentId?: string, systemIds: string[] }) => {
//   const systemId = makeEntity([]);
//   systems[name] = { id: systemId, name, systemIds, elements: [], imports: [], exports: [], parentId };
//   return systemId;
// }

// // export const addFunction = (name: string, func: any) => {
// //   systems[name].fun
// // }
// export const addElement = (name: string, elemId: string) => {
//   //  AGAIN, I COULD use the RELATION system for like EVERYTHING, and MAYBE I CAN / SHOULD do that? hmmm... that way, we can view the whole thing at this level if we want to.. BUT I still think it's within a system's right to have its OWN encoding ?HM!@
//   systems[name].elements.push(elemId);
// }

// export const importSystem = (name: string, dependencyId: string) => {
//   systems[name].imports.push(dependencyId);
// }

// export const exportElement = (name: string, elemId: string) => {
//   if (systems[name].elements.indexOf(elemId) == -1) { throw `Cannot export an external element '${ elemId }' from the '${ name }' system.` }
//   systems[name].exports.push(elemId);
// }

// export const coreSystemId = makeSystem({ name: "core", systemIds: []});

// //
// //  Group System
// //

// //  AH!  THIS way, I register a STAKE in the thing and THAT'S the relationship essentially? HM!  The idea is there's NO explicit link... but the GROUP system has the association between the thing and the group.. it's ENCODED that way, NOT using a relationship or anything, JUST encoded here as a group? Hmm.. BUT then how do I get access to the particular thing? hmm.. WHAT if I want to add to MORE groups? Hmm...  I THINK that can be possible with this then? Hmm...   Why not call it a TAG though? Hmm... the GENERALIZATION is perhaps just an association between things and anotherr thing? Hmm... maybe.  MAYBE.. I COULD make "Tags" and associate thing with those EXPLICITLY, BUT then that's just ONE encoding that's going to be INTERPRRETED... it STILL needs to be interpreted by something rright??? hmm...  MAYBE I should just make a thing, stake it with a name, and consider it a "Group"? hmm... OR just the name.. maybe that's enough.  Not so bad.  Then, the idea is it's just going to cache like that? Hmm... Like... whenever we make an association, we cache for the ends? Hmm... maybe.  I KINDA like that idea.. because then, it's NOT just separate entities with their own encodings, like Tag / Group.  Sure, we can build those systems, BUT they have a more fundamental representation that we can CAPTURE.  This way, it's not SO opaque.. it's MORE clear what's happening? Hmm ... by using a RELATION instead of just putting in an array, we can view it at that level, and there IS a pattern at that level... hmm... but we don't need to do this with everything...
// //  AGAIN, it's up to us.  We CAN do it WITHOUT also storing with that other encoding which itself HAS an interpretation.  Instead, we CAN just keep each system OPAQUE with its encoding, BUT there ARE associations that we might MISS if we do that? Hmm... Like.. imagine we have a thing in 5 "Groups" and several "Tags".  We would JUST have the interface shown by the OUTER system, which shows a thing in various groups, and PERHAPS those are even used to do something like Task.  Then, we JUST show it as a task and perhaps show it as a Calendar Item, then perhaps show the associated Tags from the Tag system.  BUT there's ALSO other information that we COULD use.. the MAIN reason is perhaps CACHING? HM!  The idea is, when we DON'T just use an opqaue representation, we can USE the systems we do have to optimize retrreival? Hmm... PLUS, or course it might help with BUILDING those systems and stuff too? Hmm... THEN, we cann wee that a thing had a BUNCH of associations.  Instead of building a separate list for EACH Group / Tag we use the existing logic to create a list for the relationship ends.  Then, we can skip that pieces of functionality? Hmm... The idea is, we COULD just get the relatios to a thing, BUT we MAY still want to type them, because I COULD make relations for the TAG system too.. hmm.. MAYBE this is where I want the relations made by THIS system. hm!  I think that makes some sense.  BUT it ALSO means an EXPLICIT encoding for each rrelation for the things in the group... MAYBE that's OK, BUT it's more PHYSICAL SPACE, when I COULD just add that to the "Group" list... hmmmmmm.... We CAN build Grroup out of these lower level concepts, but SHOULD we??? HM!  VERY cool!
// //  I CAN choose to keep a TEXT encoding in the system.
// //  In the current implementation, I DO have global IDs, BUT do we NEED to do that.. the idea is, we CAN do that to make associations across the system.. like adding a GROUP to a GROUP with another Plugin and stuff.. BUT even that, we COULD do just be injecting in to the Group system... idk man.
// //  SEEMS to be a battle between standardization / normalization and INDEPENDENCE and all that.. Hmmm... MAYBE that has to do with Order / Chaos and Order / Entropy? Hmmm
// //  MAYBE it should be up to the SYSTEM what EXTERNALS it exposes out? Hmm  THis way we CAN make additional associations if we want? Hmm.. BUT it's still up to the system.  I DON'T think we should FORCE all things to be externalized, BECUASE there are lots of intermediary encodings and stuff.. hmm... SOME things ONLY exist contextually, SO it wouldn't make sense to put it in a GLOBAL space, BUT it would make sense to make it available in the contextual space.. hm!  Like what?  Hmmm... is that even true?  hmm
// //  MAYBE the idea is we CAN register thigns with "MetaMesh"... but we don't HAVE to.  THen we have a change interfac ethere.

// //  IDEA:  We COULD use a Plugin to make group RULES and stuff too?  THEN the objects can be checked prior to association?   Agai.. I THINK I like the idea of the ore general cocept of association. hmm

// //  We COULD even let a system USING this system specify an additional encoding to give it some context.. THAT way we don't need to build entirely new BACKEND systems, JUST the frontend plugins! HM!  That makes sense!  MAYBE store that as "context" in a blob? Hmm... PLUS, consider INJECTIONS for required context and stuff?? Hmm... PLUS consider NAMING a group and perhaps storing a name? Hmm  MAYBE that's part of the data we pass down to the Association Hmm  It's KINDA like Metadata? Hmm BUT it has meaning in THIS system ?HM!  hmmm...

// // interface Group extends Entity {
// //   //  NOW, I'd like to potentially support UNLIMITED otther things herre... like SYSTEM and NOUN, etc.. a CONTEXT with meaning to OTHER systems.  HOW then can I do that? hmm  AND should this be outside of "code" written in this flat thing? Hmm...
// //   //  INSTEAD, I THINK the idea is to CONNECT it to another thing? HM!  Like a LABEL or the SYSTEM itself?? HM!  THIS way we can SCOPE the groups? HmM! I THINK I like that? Hmm...   MAYBE don't even give itt a NAME for now.
// // }

// //  OK... I GET wanting to put it in the system so we can do things like GROUP.  This way, things that we create can be PART of a system... BUT do we need that?  MAYBE we can just give the systems a representation with their NAME without the whole import / expor thing.  I THINK that's stioll cool and stuff, BUT mabye ANOTHER step for when we want to actually fuck witht he systems VISUALLY and PROGRAMATICALLY instead of statically? Hmmm...   That would mean that FOR NOW, I COULD just make a CLASS, which wires it up with the prototype chain and stuff.. hmm  AND even in the system, MAYBE I have an Object system which supports inheritance? Hmm...

// //  AH!  SO, one thing I wanted to do was make the "Task" system its own thing.  BTU what about when I want to give a PRIOTITY to a Task? Hmm... Then what ID do i use?  I COULD couple with the TASK system.. BUT do I REALLY want to do that... I COULD... that's a key point.  Now... what about PROJECT.  Let's say I want to add a task to a PROJECT.  Again, PERHAPS I could couple with the Task system, BUT... it gets tricky at that point... if the Task / Project have presence in a shared ontology, then we can use that.  OTHERWISE, we need to bridge with interpreters in BOTH sytems.. hmm... SO, we give them a shared space, THEN we integrate with ONE system for something like "Parent".  OR if it's the PROJECT sytem I want to be in, PERHAPS it can store the Association/ hmmm...  So, the idea is relatioinships between entities in multiple systems.  THEN using a single system to show them? hmm... like "parent".  This way, we DON'T need to build that for everything and even NESTING is sipmle.. JUST the one system for everything across the eco? HM!  THEn we can do simlar with other systems / relations? HM!

// //  OR we can keep support forr that in the Project system, and give it an ID with this system and stuff.. hmmmm... unless we have a simple object-like representaiton OR a representaiton that we "know" how to interpet what will we do?  NOW... it DOESN"T need to be ONE type... it can be a complex web AS LONG as we ca interpret it, we  can show it.  That's the idea.. IF NOT the idea is we can STILL show there's a thing and ideally show that there's a LINK to the otherr system AND auto-check for PLUGINS.. hm!  THIS way, IF we did want to show Projects in the Tasks it would be easy.  The POINT is, I DO want a shared ontology and some basic auto-types / template? hmm... THen I DON'T need a TON of this stuff..  BUT we DO want to fall-back if we need to.. ALL the way to just a deep link perhaps.. hmm.. Again, SMILAR to what Corey suggested, BUT even in my trackers I was doing that stuff, AND tcomb supports it, AND it's more general.

// //  So again, we COULD just do Task directly with NO "MetaMesh" thing.  To START, I think that's even fine.  BUT we'll want to be able to do the reverse associations and stuff.  We'll want to be able to connect this thing out.. hmm...MAYBE with coupling systems forr ALL of this stuff.. hmm... Even with MetaMesh, it's still nice to have the regular Plugin.  BUT that's why we can CHOOSE.  In MetaMesh, at least it's all available! ?HM!  Cool.

// //  NOTE:  The "systemIds" thing can happen INTERNALY? HM!
// //  INSTEAD of doing all this in the CODE, and building a CLASS that we USE, we build systems with an API that the code can interpret.. it COULD potentially introspect on the classes too, BUT this is with API usage as a first-class concern.. hm!  THEN it makes it easy to do exciting things like lotsss of stufff.. hm  WHY though, should I even do this like this?  WHY not just define the things in the primitive code with no interrface? Hmm the idea is to bring the systems to the interrface level so systems can OPERATE on them! HM!  THAT means we can do things like REFERENCE them and EXTEND, etc HM@  I THINK I like that, BUT we MAY also have PRIMITIVE systems as well.

// export const groupSystemId = makeSystem({ name: "group", systemIds: []});

// export const createGroup = () => {
//   //  Register the Entity -> WHAT is the point of this without the staking system? hmm... MAYBE there is none? Hmm... BUT I STILL might want to REFERENCE a specific thing.  Like the Group between apps and stuff... I THINK that's reasonable? Hmm...  MAYBE I should be doing it through a context chain though?
//   const groupId = makeEntity(["group"]);
//   return groupId;
// }

// //  CONSIDER:  Should "systemIds" be opt in?
// //  CONSIDER:  SHould ALL of this be represented with entities / associations and NOT text??? HM!
// //  TODO:  Inject "systemIds" with the Metadata system Hmm... NOT sure I like that though... PERHAPS we can ASSOCIATE instead? Hmm... Just like this, when we pass, we can store our OWN thing which has the group name and stuff? Hmmm...
// export const joinGroup = (groupId: string, elementId: string, systemIds: string[]) => {
//   associate(elementId, groupId, true, systemIds);
// }

// export const getElementIds = (groupId: string) => {
//   const associations = getAssociations({ dest: groupId, directed: true });
//   return associations.map(assoc => assoc.src);
// }

// //  NOTE:  Only returns the immediate parent.
// export const getGroupIds = (elementId: string) => {
//   const associations = getAssociations({ src: elementId, directed: true });
//   return associations.map(assoc => assoc.dest);
// }

// //  TODO:  Scope CREATE with a system so it's automaticlaly made in that context, AND consider this for the other entieis/ Hm!

// //
// //  Noun System
// //

// //  NOTE:  The idea is to build the SAME thing as Group... BUT with a different system name, and therefore a different interpretation / interface? Hmm...  MAYBE in the future we can EXTEND that SYSTEM.  MAYBE evenn now we can do that.. it JUST means it's going to have the SAME imports / exports, BUT it's going to "run" in the new context. hM!
// const nounSystemId = makeSystem("noun", "group", []);

// interface Noun extends Entity {
// }

// const nouns: Noun[] = [];
// export const makeNoun = () => {

// }

// //
// //  Task System
// //

// interface Task {
//   name: string;
//   description: string;
// }

// const tasks: Task[] = [];
// const createTask = (task: Task) => {

//   //  Make the Entity
//   const taskEntity = makeEntity(["task"]);

//   //  Stake
//   makeStake(["task"], taskEntity);

//   tasks.push(task);
// }

// export const getTasks = () => {

// }

// //
// //  Primitive System
// //

// const primitives = [];

// //
// //  Class System
// //

// const classEntity = makeEntity("class");

// const makeClass = () => {

// }
