import { useNavigation } from '@react-navigation/native';
import { CorePluginClass, Program } from 'halia';
import { HOCRegister, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import * as React from 'react';
import { Animated, ScrollView, View } from 'react-native';
import { SystemHeader } from '../../../packages/kelp-bar/system-header';
import { AaroContext, AaroPlugin, Widget } from '../aaro-core';
import { TextParagraph } from '../../../packages/kelp-bar/text';
import { Icon } from 'react-native-elements';
import { AaroHeader } from '../utils';

//  TODO:  Make the Widget System!
//  TODO:  Make the Dashboard System!
//  TODO:  NEED to be able to see the pieces added by a Plugin.  This MIGHT mean making it possible to see the function, but to START, we should be able to REGISTER when an ENTITY is marked as "Owned By" or "Created By" a plugin and then just show all of these.  We MAY be able to do it in a sandbox first and then look at the result hm!

export interface DashboardContext {
  registerDashboardWidget: (widget: DashboardWidget) => void;
  dashboardWidgets: DashboardWidget[];
}

export const DashboardContext = React.createContext<DashboardContext>({ dashboardWidgets: [], registerDashboardWidget: () => null });

export interface DashboardWidget extends Widget {

}

//  TODO:  Build the WIDGET editor.  Consider - VERY similar to blocks!!!  The idea is, a BLOCK is very similar to HaborComponent as well!  The KEY thing is that we can have "signals" modled that go between them.  Similar to how we had props on the habor component hmm....

export const DashboardPage = () => {

  const { dashboardWidgets } = React.useContext(DashboardContext);

  //  TODO:  make it possible to BIND to 'parent' state if something is availabnle, OR auto-query!  This way params are easy hm!  MAY even be defined separately and expanded hm!  NEED to be ok thinking differen!  The CURRENT solution is BROKEN in so many ways because people don't want to think and re-invent mm!!

  //  TODO:  Instead of using an 'if' statement, have an ENCODING associated with the 'loading' STATE.  THEN... have that code run and INJECT this mm!!  The BENEFIT, is that we can later VIEW the code from multuple ways mM!!!  WHY is code done like this?  I'm SOOOO happy I started down this Path BEFORE I started to have confidence issues mm!!!  The MAIN idea is, there are SOOOO many things that can be improved mm!  ABUNDANCE!  It's just... most of those things do not provide immediate BUSINESS  value mm!!

  const buttonStyle = { borderWidth: 2, borderColor: 'black', backgroundColor: '#DEF7FF' };


  //  TODO:  This should be injected.  Meaning, we use something like Halia to reference a symbol (the register) with "setX" along with a value to put in there.  THEN, the system reacts to that!  It's the same as having an instruction interpreter and then having one that says go here and follow these instructions to "setX".  Which, will affect the dependencies.
  //  CONSIDER:  Can we re-build Halia to use the React dependency system?
  //             COULD update it so EACH Halia Plugin has a way to register a CONTEXT which is ALWAYS above its descedents!  THIS way, we do DI in the context of React!  MIGHT be able to couple this with Halia React?  The IDEA is we can build systems which have a node in the React Component tree.  THIS way we can depend on the plugins through that.  Instead of the plugin interface being outside of the tree, MOST of the plugin can be defined within the React Conext WHICH just means we can DEPEND on it and automatically get triggered with updates.

  const navigation = useNavigation();

  const [isAtTop, setIsAtTop] = React.useState(true);

  const scrollY = new Animated.Value(0); // To store the current scroll position

  const opacity = scrollY.interpolate({
    inputRange: [0, 100],  // Adjust these values as per your need
    outputRange: [0, 1],  // Opacity goes from 1 to 0
    extrapolate: 'clamp', // Make sure opacity doesn't go outside [0,1] range
  });

  const [editMode, setEditMode] = React.useState(false);

  const WidgetWrapper = ({ editMode = false, widget }: { editMode: boolean, widget: Widget }) => {

    const [collapsed, setCollapsed] = React.useState(false);

    const { component: Widget, name } = widget;

    return editMode ? (
      <View style={{ backgroundColor: '#fcfcfc', borderRadius: 5, borderColor: '#eeeeee', borderWidth: 1 }}>
        <View style={{ borderBottomColor: '#eeeeee', borderBottomWidth: 1, justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', padding: 15 }}>
          <Icon name="drag-indicator" type="material" color="#999999" />
          <View style={{ width: 10 }} />
          <TextParagraph style={{ color: "#777777" }}>{name}</TextParagraph>
          <View style={{ flex: 1 }} />
          <Icon name={collapsed ? "chevron-back" : "chevron-down"} onPress={() => setCollapsed(!collapsed)} type="ionicon" color="#999999" />
        </View>
        {
          !collapsed && (
            <View style={{ padding: 15 }}>
              <Widget />
            </View>
          )
        }
      </View>
    ) : <Widget />
  }

  return (
    <>
      {/* TODO:  Add borderOpacity as animated to the system header!!! */}
      <AaroHeader title="Dashboard">
        <Icon name="edit" type="material" color={editMode ? "#777777" : "#aaaaaa"} onPress={() => setEditMode(!editMode)} />
      </AaroHeader>

      <ScrollView onScroll={Animated.event(
        [{ nativeEvent: { contentOffset: { y: scrollY } } }],
        { useNativeDriver: false }
      )} scrollEventThrottle={16} style={{ backgroundColor: 'white', paddingHorizontal: 15 }}>


        <View style={{ height: 20 }} />

        {/* TODO:  Remove the reverse hack.  This is just used to get Progress to come before Habits.  It SHOULD be resolvd with constraints or another widget set within the Habit Widget. */}
        {dashboardWidgets.reverse().map(widget => {

          return (
            <>
              <WidgetWrapper editMode={editMode} widget={widget} />
              <View style={{ height: 10 }} />
            </>

          );
        })}

        <View style={{ height: 20 }} />

      </ScrollView>
    </>

  );
}

export class DashboardPlugin extends CorePluginClass {


  public static details = {
    name: 'Dashboard',
    description: 'Display Widgets',
    dependencies: [AaroPlugin.details.id],
    id: 'dashboard',
    image: require("../../../assets/stickers/dashboard.png")
  }

  public registerHOC = (wrapper: any) => {
    registerHOC("dashboard", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("dashboard", wrapper);
  }

  public install = async (program: Program, { aaro }: { aaro: AaroPlugin }) => {

    registerHOCRegister("dashboard");

    aaro.registerHOC(({ children }) => {

      const [dashboardWidgets, setDashboardWidgets] = React.useState<Widget[]>([]);

      const registerDashboardWidget = (widget: DashboardWidget) => {
        setDashboardWidgets(prev => {
          const alreadyInstalled = !!dashboardWidgets.find(_widget => widget.name === _widget.name);
          if (!alreadyInstalled) {
            return [...prev, widget]
          }
          return prev;
        });
      }

      const aaroContext = React.useContext(AaroContext);

      React.useEffect(() => {

        console.log("Dashboard Extension:  Installling Routes");

        aaroContext.installRoute({
          name: 'Dashboard',
          icon: {
            name: "dashboard",
            type: "material"
          },
          emoji: "🎛️",
          component: DashboardPage,
          isInitial: true,
          logo: require("../../../assets/stickers/dashboard.png")
        });
      }, []);

      return (
        <DashboardContext.Provider value={{ registerDashboardWidget, dashboardWidgets }}>
          <HOCRegister id="habit">
            {children}
          </HOCRegister>
        </DashboardContext.Provider>
      );

    });
    return this;
  }
}


