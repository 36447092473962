import { HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { ViewStyle } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { renderHaborComponent } from '../../component-plugin/habor-react/habor-component-core';
import { HaborContainer, PrimitiveProps } from "../../component-plugin/habor-react/habor-component-lib";

//
//  Grid Component
//

export interface GridProps extends PrimitiveProps {
  userProps: {
    itemDimension: number;
    propsList: any[];
    componentId: string;
    style: ViewStyle;
    //  TODO:  In the future, support mapping of inputs to item props... Or, support a renderProp to actually render a component.
  }
}
export interface GridState {
  components: React.ReactElement<any>[];
}

export class HaborGridComponentPrimitive extends React.Component<GridProps, GridState> {

  constructor(props: GridProps) {
    super(props);
    this.state = {
      components: []
    }
  }
  public componentDidMount = async () => {

    //  Get the HaborComponent
    //  TODO:  Use the "getHaborComponent" function?
    const hessiaPlugin = this.props.frameworkProps.hessiaPlugin;
    const comp = hessiaPlugin.getHaborComponent(this.props.userProps.componentId);
    if (!comp) { throw new Error("Undefined Component"); }  //  TODO:  Build a custom error for this, AND have the Plugin which defines this error INSTALL it into the ERROR handling plugin!!!!!
    const components: React.ReactElement<any>[] = [];
    for (const props of this.props.userProps.propsList) {
      //  TODO:  Set componentId here?
      console.log(props);
      alert("MISSING IN GRID COMPONENT");
      const renderedComp = await renderHaborComponent(comp, { frameworkProps: { ...this.props.frameworkProps }, userProps: props}, hessiaPlugin)
      components.push(renderedComp);
    }
    this.setState({ components });
  }
 
  public render() {
    const { components } = this.state;
    const { frameworkProps, userProps: { style, itemDimension } } = this.props;
    return (
      <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, ...style }}>
        <FlatGrid itemDimension={ itemDimension } data={ components } renderItem={ (item) => item.item } />
      </HaborContainer>
    );
  }
};


//  TODO:  Consider ways to automatically pass props to the primitive component without explicitly mapping here?

export const HaborGridComponent: HaborComponent = {
  name: "HaborGridComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "HaborGridComponentPrimitive",
    props: {
      itemDimension: { type: "symbol", scopePath: ["props", "itemDimension"] },
      propsList: { type: "symbol", scopePath: ["props", "propsList"] },
      componentId: { type: "symbol", scopePath: ["props", "componentId"] },
      style: { type: "symbol", scopePath: ["props", "style"] },
    },
    children: []
  }
};
