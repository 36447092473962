import * as React from 'react';
import { ViewStyle } from 'react-native';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';

//
//  Row Component
//
export interface RowProps extends PrimitiveProps { userProps: { style: ViewStyle } }
export interface RowState {}

export const HaborRowPrimitive = ({ userProps: { style }, frameworkProps }: PrimitiveProps) => {
  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flexDirection: 'row', flex: 1, ...style }}>
      { frameworkProps.children }
    </HaborContainer>
  );
};
