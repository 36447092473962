import { HaborComponent, SDTObject } from 'habor-sdk';
import * as React from 'react';
import { Text, TextInput, View } from "react-native";
import { HaborContainer, PrimitiveProps } from "../../component-plugin/habor-react/habor-component-lib";

//
//  Slider React Component
//

export interface SliderProps { value: number, maximumValue: number, minimumValue: number, onChange: (value: number) => void };
export const SliderPropsSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: false,
  properties: {
    value: { type: "number", required: true },
    maximumValue: { type: "number", required: true },
    minimumValue: { type: "number", required: true },
    onChange: { type: "any", required: true }  //  TODO:  Figure out whether or not we SHOULD support functions passed to HaborComponents!  These CANNOT be serialized, but they can be passed WITHIN Habor?  Maybe we should expose a different mechanism like Inputs / Outpus / Signal, etc...  There's a note talking about all that somewhere.
  }
};

//  CONSIDER:  Make separate componets for Integer vs. Regular?  OR, just use the CONTEXT mask and PROPS... WHEN does it make sense to ENCAPSULATE something in ANOTHER component!?  While we CAN do this.. DOES it make SENSE!?  A component is JUST a "Responsiiblity Capsule"??  PERHAPS if I think of EVERYTHING as a SYSTEM, THEN these questions come to what WE want the thing to be AWARE / RESPONSIBLE of!?  Hmm!!!  PLUS, this may help reduce complexity.
export const HaborSlider = ({ value, maximumValue, minimumValue, onChange }: SliderProps) => {

  const [cachedValue, setCachedValue] = React.useState(value);
  const [isEditingText, setIsEditingText] = React.useState(false);

  //  Update All
  const updateAll = (newValue: number) => {
    setCachedValue(newValue);
    onChange(newValue);
  }

  //  NOTE:  We DON'T call the "onChange" handler until we STOP.  That's because it's signaling stuff OUTSIDE the component at that point.  INTERNALLY, we use state to update the immediate value.  However, we MAY want to update externally as we change.. hmmm.. this way, the PARENT can control things like the display.  Again.. just a tradeoff?  Hmm.. THEN in that WRAPPING component, we'd probsbly only call when the slider is released!?  Hmmm... MAYBE we can keep it as is but mke it posible to CHANGE the inner stuff?  THAT has overhead though by building this with Habor.  OK!  So, there are a FEW tradeoffs here!  Speed of the processing onChange, MAYBE we support another callback for onChange and onSlidingComplete, JUST like the original!  IF we make with Habor Component, that has its OWN overhead!  EITHER way, I think we DO want SOME primitives to work with!??  That makes some sense to me, THEN people can make more coplex Habor Components as they see fit!?  These can be changed with the CSS-like system!?  MAYBE I shoud come up with a name... Cascading Settings?  Hmmm  not really a "sheet"?  More like a GRAPH?  Hmm.. CSG?
  return (
    <View style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Text>{cachedValue}</Text>
      <TextInput autoCapitalize="none" style={{ alignSelf: 'flex-start' }} value={!isEditingText ? cachedValue.toString() : undefined} clearTextOnFocus={true} onFocus={() => setIsEditingText(true)} onEndEditing={(e) => updateAll(Number.parseInt(e.nativeEvent.text))} />
      <Text>{maximumValue}</Text>
      <Text>{minimumValue}</Text>
      <Text>SHOULD SHOW SLIDER!</Text>

      {/* <Slider step={1} value={cachedValue} maximumValue={maximumValue} minimumValue={minimumValue} onValueChange={setCachedValue} onSlidingComplete={updateAll} /> */}
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <Text style={{ alignSelf: 'flex-start' }}>{minimumValue}</Text>
        <Text style={{ alignSelf: 'flex-end' }}>{maximumValue}</Text>
      </View>

    </View>
  );
};

//
//  Slider Primitive Component
//
export interface SliderHaborPrimitiveProps extends PrimitiveProps {
  userProps: SliderProps;
}
export const SliderHaborPrimitive = ({ userProps, frameworkProps }: SliderHaborPrimitiveProps) => {
  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <HaborSlider {...userProps} />
    </HaborContainer>
  );
};

//
//  Slider Habor Component
//
export const SliderHaborComponent: HaborComponent = {
  name: "SliderHaborComponent",
  propsSchema: SliderPropsSchema,
  element: {
    name: "SliderHaborPrimitive",
    props: {
      value: { type: "symbol", scopePath: ["props", "value"] },
      maximumValue: { type: "symbol", scopePath: ["props", "maximumValue"] },
      minimumValue: { type: "symbol", scopePath: ["props", "minimumValue"] },
      onChange: { type: "symbol", scopePath: ["props", "onChange"] }
    },
    children: []
  }
};
