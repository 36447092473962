import Lottie from 'react-lottie';
// import Lottie from 'lottie-react-native';
import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { Image, Modal, Platform, View } from 'react-native';
import { Text } from 'react-native-paper';
import animationData from '../../../assets/stickers/sparkle_animation.json';
import { GalleryButton } from '../../gallery/components/common';
import { AaroPlugin, Extension } from "../aaro-core";
import { HabitPlugin } from './habits.extension';
import { ProgressContext, ProgressPlugin } from './progress.extension';

//  Rank Images
const rank1 = require('../../../assets/stickers/rank_1.png');
const rank2 = require('../../../assets/stickers/rank_2.png');
const rank3 = require('../../../assets/stickers/rank_3.png');
const rank4 = require('../../../assets/stickers/rank_4.png');
const rank5 = require('../../../assets/stickers/rank_5.png');
const rank6 = require('../../../assets/stickers/rank_6.png');
const rank7 = require('../../../assets/stickers/rank_7.png');

const rankCount = 7;

export interface IRankingContext {
  rank: number;
  prevRank: number;
  rankTitles: string[];
  rankImages: any[];
  rankTitle: string;
  rankImage: any;
}

//  CONSIDER:  SHOULD be able to see the component hierarchy in the app itself!  THe dependency hierarchy!
export const RankingContext = React.createContext<IRankingContext>({ prevRank: 0, rank: 0, rankTitles: [], rankImages: [], rankTitle: '', rankImage: undefined });

const rankingPluginId = "rank";

const RankingExtension: Extension = {
  id: rankingPluginId,
  name: "Ranking",
  description: "Progressive Rank",
  color: '#6FE3D4',
  image: require("../../../assets/stickers/RankSystem.png")
};

const HabitRank = () => {

  //  Rank Emojis
  // const rank1Emoji = "🥉";
  // const rank2Emoji = "🥈";
  // const rank3Emoji = "🥇";
  // const rank4Emoji = "🏆";
  // const rank5Emoji = "🌟";
  // const rank6Emoji = "💎";
  // const rank7Emoji = "🦄";



  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const isIos = Platform.OS === 'ios';

  const [showReward, setShowReward] = React.useState(false);

  const { rank, rankTitle, rankImage, prevRank } = React.useContext(RankingContext);

  const [initialRank, setInitialRank] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (!initialRank) { setInitialRank(rank); return; }
    // if (rank == initialRank) { return; }
    if (rank > prevRank) {
      setShowReward(true);
    }
  }, [rank, prevRank]);

  React.useEffect(() => {
    setInitialRank(rank);
  }, []);

  return (
    <>
      {showReward && (
        <Modal transparent={true} style={{ alignItems: 'center', justifyContent: 'center' }}>

          <View style={{ backgroundColor: 'black', opacity: 0.2, position: 'absolute', width: '100%', height: '100%' }} pointerEvents='box-none' />

          <View style={{ margin: 100, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', shadowOffset: { width: 5, height: 5 }, shadowColor: 'black', shadowOpacity: 0.2, shadowRadius: 20, paddingVertical: 30 }}>
            <Text style={{ fontFamily: 'Inter-Bold', fontSize: 30, color: '#333333' }}>New Rank</Text>
            <Image source={rankImage} resizeMode='contain' style={{ height: 100, width: 100, marginVertical: 20 }} />
            <Text style={{ fontFamily: 'Inter-Bold', fontSize: 20, marginVertical: 20, color: '#333333' }}>{rankTitle}</Text>
            <GalleryButton title="Close" onPress={() => setShowReward(false)} />
          </View>

          <View pointerEvents='none' style={{ position: 'absolute', width: '100%', height: '100%' }}>
            {
              !isIos && (
                <Lottie options={defaultLottieOptions}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              )
            }

          </View>


        </Modal>

      )}
      <View style={{ backgroundColor: '#fbfbfb', borderRadius: 15, borderWidth: 2, borderColor: '#eeeeee', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 50, paddingHorizontal: 20 }}>
        <Image source={rankImage} resizeMode='contain' style={{ height: 35, width: 35 }} />
        <Text style={{ fontSize: 16, fontFamily: 'Inter-SemiBold', marginLeft: 5, color: '#333333' }}>{rankTitle}</Text>
      </View>
    </>

  );

}

//  CONSIDER:  Need to be able to see how the things the plugins INSTALL are related? hmm... 

//  CONSIDER:  Build the system myself!  It's just a way to have functions that "react" to others.

export class RankingPlugin extends CorePluginClass {

  public static details = {
    name: 'Rank',
    description: 'Rank Plugin',
    dependencies: [HabitPlugin.details.id, ProgressPlugin.details.id, AaroPlugin.details.id],
    id: rankingPluginId,
    image: require("../../../assets/stickers/RankSystem.png")
  }

  public install = (program: Program, { progress, habit, aaro }: { aaro: AaroPlugin, habit: HabitPlugin, progress: ProgressPlugin }) => {

    // const { ProgressContext } = progress;

    habit.registerHOC(({ children }) => {

      const { AaroContext } = aaro;
      const { installHeaderWidget } = React.useContext(AaroContext);

      const [rank, setRank] = React.useState(0);
      const [prevRank, setPrevRank] = React.useState(0);

      const { progress } = React.useContext(ProgressContext);

      const rankImages = [rank1, rank2, rank3, rank4, rank5, rank6, rank7];
      // const rankEmojis = [rank1Emoji, rank2Emoji, rank3Emoji, rank4Emoji, rank5Emoji, rank6Emoji, rank7Emoji];
      const rankTitles = ["Bronze", "Silver", "Gold", "Emerald", "Diamond", "Mystic", "Sage"];

      const rankImage = rankImages[rank];
      // const rankEmoji = rankEmojis[rank];
      const rankTitle = rankTitles[rank];



      React.useEffect(() => {

        installHeaderWidget({
          name: "Habit Rank",
          description: "Habit Rank",
          component: HabitRank,
          pluginId: RankingPlugin.details.id
        });
      }, []);

      const getRank = () => {
        const rankAmount = 1 / (rankCount - 1);  //  Assume 0 is the first, so we need (rankCount - 1) increases.
        const newRank = Math.floor(progress / rankAmount);
        return newRank;
      };

      React.useEffect(() => {
        const newRank = getRank();
        setPrevRank(rank);
        setRank(newRank);
      }, [progress]);

      return (
        <RankingContext.Provider value={{ rank, rankImages, rankTitles, rankImage, rankTitle, prevRank }}>
          {children}
        </RankingContext.Provider>
      );
    });


    return this;
  }
}