/* eslint-disable import/first */
//  TODO:  This should be part of the Plugin Manager UI Library!? HM!  Should be customizbal eand MAYBEEE PLUGGABLE? HM!!!
// import { enablePlugin } from './plugin-service';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CorePluginClass, CorePluginDetails } from 'halia';
import * as React from 'react';
import { Button, Image, ScrollView, ViewStyle } from 'react-native';
import { Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { HaliaPluginContext } from '.';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { Window } from '../../elements/splitter/splitter';
import { HaliaStudioPlugin } from './halia-studio-plugin';
import { StackNavigationProp, createStackNavigator } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { SystemHeader } from '../../../packages/kelp-bar/system-header';

const Tab = createMaterialTopTabNavigator();

const shadowStyle = {
  shadowColor: 'black',
  shadowRadius: 2,
  shadowOpacity: 0.04,
  shadowOffset: {
    width: 3,
    height: 3
  },
};

export interface CircleIconProps {
  icon: { name: string, type: string };
  backgroundColor: string;
  iconColor: string;
}

export const CircleIcon = ({ icon, backgroundColor, iconColor }: CircleIconProps) => {
  return (
    <View style={{ height: 60, width: 60, borderRadius: 30, backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <Icon name={icon.name} type={icon.type} color={iconColor} size={30} />
    </View>
  );
}

export const SquareIcon = ({ icon, backgroundColor, iconColor }: CircleIconProps) => {
  return (
    <View style={{ height: 70, width: 70, borderRadius: 15, backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <Icon name={icon.name} type={icon.type} color={iconColor} size={30} />
    </View>
  );
}


//  CONSIDER:  I like the idea of a whole DB implementation using Async.. hmm... idk man.  That's interesting.  MAYBE just work on the API for it and go from there?  Again, let's just let each service do its thing for now HOWEVER it wants and expand out.  This way, we HAVE a product which can be sold! HM!  NO backend to start! hm!

//  NOTE:  We don't NEED Halia to enable / disable Plugins... BUT it helps keep things organized? Hmmm... maybe, we re-initialize whe we change? Hmmm... Otherwise, we might try injecting BEFORE dependenices were met!? HM!

//  NOTE:  MAYBE we can ADD this to the Alert system?? HM!  NOT just use the alerts here?? hmm...

//  CONSIDER:  NORMALLY how does this worrk with a backend?  We use websockets and Apollo / GraphQL?  Something like that? hmm..


// registerPlugin({
//   name: "Alert",
//   icon: "sun",
//   color: '#772200',
//   func: () => {
//     alert("Pushed to top");
//     //  TODO:  Get this out of here! HM!
//     topRegister.push(<Text>Test!</Text>)
//   }
// });

// let component = null;

// let text = undefined;
// let plugin;

// const api = {
//   setText: (_text) => {
//     text = _text;
//   },
//   setComponent: (_component) => {
//     component = _component;
//   }
// }



// // console.log(PluginTools)

// //  Fetch the Resource
// //  REFERENCE:  https://stackoverflow.com/questions/39892641/es6-javascript-import-external-js-file
// //  Ok.. perhaps we CAN pull the file in to the local directory structure!  HOW does that work on mobile though? hmm
// //  BUT, even with expo it looks like the Expo file system thing isn't availble on web yet... So, I'm thinking, why not have users minify and shit to a single file, then we can grab that?  Hm!
// //  NOTE:  FOR NOW, we'll just assume it's a single file? Hmm.  BUT even in the futurre, pehaps it can be minified! Hm!

// // NOW we want a PLUGIN MANAGER and something that works like Halia with dependencies / injections and shithm!

//   //  TODO:  Reconcile this with Halia and AMD / CommonJS / etc... can we re-use existing stuff, what's the value?  
//   //         For this, I believe it's support for REMOTE modules? HM!

//   //  NOTE:  I think this IS a Halia Plugin! HM!
//   //  NOTE:  We MAY create MULTIPLE Halia Contexts! HM!
//   interface Halia {
//     dependencies: 
//     name: string;
//     description?: string;  //  TODO:  SHOULD be able to use the HALIA system concept to add-on these things?
//     build: () => {},
//     inst: any;
//   }

//   class PluginManager {

//     private plugins: Plugin[] = [];

//     constructor() {

//     }

//     public installPlugin = (plugin: Plugin) => {
//       this.plugins.push(plugin);
//       plugin.inst.install()
//     }
//   }

//   const _require = require;
//   // const _dirname = __dirname;
//   fetch('http://localhost:3004/my_module.js')
//     .then(response => {
//       return response.text();
//     })
//     .then(js => {

//       //  NOTE:  Because TS will complain if we return without a wrapping function, I'm going to let the Plugin writer REGISTER their module function.  PLUS perhaps do other things with that high level interface.  THEN it will be invoked at the appropriate time.  Perhaps with DEPENDNEICS and stuff too?? HM!
//       // Function(js)();
//       // _require('react-native');
//       const require = (libName: string) => _require("../../node_modules/react-native");
//       // const geval = eval;
//       // const __dirname = _dirname;
//       const PluginManager = {
//         registerPlugin: (_plugin) => {
//           plugin = _plugin;
//         }
//       };

//       eval(js);

//       plugin({ api, React, ReactNative });

//       alert(component);

//     })
//     .catch(error => {
//       throw error;
//     });

//  INSECURITY:  This is a way to MIRROR.. ughh....... WHY should I or like ANYONE like me feel capable???  We have like.. IQ stuff and idk ughhh... SO many people are successful anyways though ugh!
//  NOTE:  This is used to MIRROR the state between Halia Component and the React world hm!  Halia is RUNTIME HM!
export const useInstalledPlugins = () => {
  const { haliaPlugin } = React.useContext(HaliaPluginContext);
  const [installedPlugins, setInstalledPlugins] = React.useState(haliaPlugin.getInstalledPlugins());

  const handler = () => {
    const installedPlugins = haliaPlugin.getInstalledPlugins();
    setInstalledPlugins(installedPlugins);
  };
  React.useEffect(() => {
    haliaPlugin.onPluginsUpdated(handler)
    return () => {
      haliaPlugin.unregisterOnPluginsUpdated(handler);
    }
  }, [])
  return installedPlugins;
}

export const useKnownPlugins = () => {
  const { haliaPlugin } = React.useContext(HaliaPluginContext);
  const [knownPlugins, setKnownPlugins] = React.useState(haliaPlugin.getKnownPlugins());

  const handler = () => {
    const _knownPlugins = haliaPlugin.getInstalledPlugins();
    setKnownPlugins(_knownPlugins);
  };

  React.useEffect(() => {
    haliaPlugin.onPluginsUpdated(handler)
    return () => {
      haliaPlugin.unregisterOnPluginsUpdated(handler);
    }
  }, [])
  return knownPlugins;
}

//  TODO:  Use the ENTITY system for this.  Perhaps in its OWN Plugin context??
//  TODO:  Build this the the SAME FILTER RULES system as the Entity explorer!  Then make the entities have custom cards for this mm!!!
//  TODO:  These parameters are TOO specific!  Instead, we can display with the ENTITY view, and then add these are RULES!  We can even have an imperitive (or declarative) way to ADD them to the view!  Imagine a way to express Rules as children!  <ImmediateOnly /> or something like that.

//  CONSIDER:  Want to be able to add arbitrary info, like an image...  Instead of just using props, consider pulling from context with AI ALL the time time.  This way, when we pass the image and stuff, it just generates a representation of what it gets? ughh... idk.  I DON'T want to break the fundamtnals.  I but also want to be able to extend... the idea is... we can change the original, build a COMPILER which perhaps includes PIECES of the original (similar to AI!) OR we can build an INTERPRETER within it (to choose based on context, etc).

// export interface PluginDetails {
//   image?: string;
//   raing?: number;
// }

//  NOTE:  Do we want to couple Halia with Hessia?  Instead, I think we can inject that functionality from Hessia...  but it COULD be beneficial EVEN in Halia to use Hessia INTERNALLY.  In which case it could be fine.
export interface CorePluginDetailsExtended extends CorePluginDetails {
  image: number;
  detailView: any; //  Extra Component with info about the plugin
}

const PluginCard = ({ plugin, containerStyle = {} }: { plugin: typeof CorePluginClass, containerStyle?: ViewStyle }) => {

  const { haliaPlugin } = React.useContext(HaliaPluginContext);

  const { isInstalled, areDependenciesInstalled, getMissingDependencies } = haliaPlugin;

  const { selectPluginId } = React.useContext(ExtensionContext);

  if (!plugin) {
    console.warn("Missing Halia Plugin");
    return <Text>Missing Halia Plugin</Text>
  }

  if (!plugin.details) {
    console.warn("Missing Halia Plugin Details");
    return <Text>Missing Details</Text>
  }

  const details = plugin.details as CorePluginDetailsExtended;

  const { name: pluginName, description, icon: { name, type } = { name: "dot", type: "ionicon" }, color, backgroundColor, image } = plugin.details as any;

  const Status = ({ color, status }: { color: string, status: string }) => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {/* <View style={{ height: 16, width: 16, borderRadius: 10, backgroundColor: color }} /> */}
        <Icon size={25} color={status === "Installed" ? "#aaaaaa" : status === "Install" ? "green" : "orange"} name={status === "Installed" ? "checkmark-circle-outline" : status === "Install" ? "cloud-download-outline" : "alert-circle-outline"} type="ionicon" />
        <Text style={{ lineHeight: 25, marginLeft: 7, color: '#777777', fontSize: 13, fontFamily: "Inter-Bold" }}>{status}</Text>
      </View>
    );

  }
  return (
    <TouchableOpacity style={{ height: 150, display: 'flex', flexDirection: 'column', backgroundColor: "white", borderRadius: 10, padding: 30, ...shadowStyle, ...containerStyle }} onPress={() => selectPluginId(plugin.details.id)}>
      <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          {/* shadowColor: '#eeeeee', shadowRadius: 7, shadowOffset: { width: 3, height: 3 }, shadowOpacity: 0.6 */}
          <View style={{ height: 90, width: 90, borderRadius: 45, overflow: 'hidden' }}>
            {
              details.image ?
                <Image source={details.image} style={{ flex: 1 }} resizeMode="contain" /> :
                <Icon name={name} type={type} color={color} size={50} />
            }
          </View>
          <View style={{ flex: 1, alignItems: 'flex-start', marginLeft: 15, marginTop: 5 }}>
            <Text style={{ color: color || '#666666', fontSize: 16, fontFamily: "Poppins-Bold" }}>{pluginName}</Text>
            <Text style={{ color: color || '#aaaaaa', fontSize: 13, fontFamily: "Poppins-Bold" }}>{description}</Text>
            <View style={{ height: 10 }} />
            {
              isInstalled(plugin.details.id) ?
                <Status color="blue" status="Installed" /> :
                areDependenciesInstalled(plugin.details.id) ?
                  <Status color="green" status="Install" /> :
                  <Status color="red" status="Unavailable" />
              // getMissingDependencies(plugin.details.id).map(missingId => <View style={{ padding: 5, borderRadius: 10, backgroundColor: '#eeeeee' }}><Text>{missingId}</Text></View>)
            }
          </View>



        </View>
      </View>
      <View>

      </View>
    </TouchableOpacity>
  );
};

//  CONSIDER:  We MAY also want to show which dependencies are required for the sub-dependencies. 
//  CONSIDER:  MAY want to auto-install ALL of them.
//  CONSIDER:  MAY want SYSTEMS which can associate information with Plugins and then let us USE that for things like "Concepts", etc.
//  CONSIDER:  We MAY even depend upon a Plugin we aren't aware of.  MAYBE we can SCOPE Plugins to certain systems?? Hmm... and SOURCES and stuff??? Hmmm!
//  TODO:  Consider treating these as entities and displaying them like that.  Then, their inclusion is something we can still do with the primitive system, but we can display them as entities.
//  CONSIDER:  We can also use the SDT / Object system for this.  Basically we need a way to mark a primitive as conforming to an existing type (SDT Instance).  Same way we have types to select from.  Basically the same as making a new "type".  We just allow the user to specify the SDT.  CAN do this for Strings too? hmm...  it's just about having an encoding that conforms to criteria (interpreted by another encoding)
//  TODO:  Make it SUPER simple.  Just use the existing Entity fields for the new type.  Then, we can do it from that.  This is an example of system composition.  Then, when we build an instance we could ideally still select it from the type list? Hmm... instead though, we may want to mark it as "Insance" and then select a "Template".  This is what SDT is about.  This is basically a template.  I think that's fine.  SO instead of registering it in the primitie system and having that "pollute" the name space, we're ESSENTAILLY adding another piece of differentation mm!!!  The trick is.. in "real life" we don't ned that hmm.
//         COULD make it a part of the primitive system where we're allowed to SELECT an existing SDT?  This way, when we buld a "keyword" or "object", we're taking from an exisitng hmmm....  The idea is, curently we make the SDT right there... that's not that helpful.  It would be great to be able to select an existing.. ugh. I guess we can do that.  Still distinct frm other type systems, lke NOMINATIVE vs SRUCTURAL etc.
//  TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm!
//  TODO:  User SHOULD be able to add EXTERNAL Plugins / Providers .. at least on Web, maybe ANdroid, AND perhaps iOS as well if we do in-app purchase?? 
//  TODO:  Abstract with Hessia? 

export interface IStoreOptions {
  basePluginId?: string;
  immediateOnly?: boolean
}

/**
 * Displays the Plugin Explorer
 * 
 * @param {string} basePluginId - The Halia Plugin ID to use as the exploration base.
 * @param {boolean} immediateOnly - Limits the displayed plugins to immediate only.
 * @returns {ReactElement} - The React Explorer
 */

export const PluginExplorer = ({ options: { basePluginId, immediateOnly } = {} }: { options: IStoreOptions }) => {

  const { haliaPlugin } = React.useContext(HaliaPluginContext);
  const { getDependents, getDirectDependents } = haliaPlugin;

  const knownDependentPlugins = basePluginId ?
    immediateOnly ?
      getDirectDependents(basePluginId) :
      getDependents(basePluginId) :
    useKnownPlugins();

  const filteredPlugins = knownDependentPlugins.filter(plugin => !!plugin) as (typeof CorePluginClass)[];

  return (
    <ScrollView>
      <FlatGrid
        style={{ overflow: 'visible' }}
        data={filteredPlugins}
        itemDimension={300}
        renderItem={({ item }) => <PluginCard plugin={item} />}
      />
    </ScrollView>
  );
}



export const PluginDetails = ({ haliaPlugin, pluginOptions, haliaApp }) => {

}

export interface IExtensionContext {
  selectedPluginId?: string;
  selectPluginId: (id: string) => void;
}
export const ExtensionContext = React.createContext<IExtensionContext>({ selectedPluginId: "", selectPluginId: (id: string) => undefined });


//
//  Extensions
//

//  CONSIDER:  Instead of "navigating" when we press something and having THAT be the state... instead of having "navigation state", have SELECTION state.. .like if we have a selected plugin, THEN we must be in that state.  That can be tricky if we can select and have MULTIPLE screens though.  But I guess that can be managed with additional state in the context. hmm... 

const StackNav = createStackNavigator();

export const PluginStore = ({ options = {} }: { options?: IStoreOptions }) => {

  const [selectedPluginId, selectPluginId] = React.useState<string>();
  const navigation = useNavigation<StackNavigationProp<any>>();

  React.useEffect(() => {
    if (selectedPluginId) {
      navigation.navigate('Extension Detail');
    } else {
      navigation.navigate('Library');
    }
  }, [selectedPluginId, navigation]);


  return (
    <ExtensionContext.Provider value={{ selectedPluginId, selectPluginId }}>
      <StackNav.Navigator screenOptions={{ headerShown: false }} initialRouteName={selectedPluginId ? "Extension Detail" : "Library"}>
        <StackNav.Screen
          name="Library"
          component={() => (
            <View style={{ flex: 1, backgroundColor: 'white' }}>
              <SystemHeader system={{ name: "Extensions", icon: { name: "puzzle-outline", type: "material-community" }, id: "extensions" } as any} />
              <PluginExplorer options={options} />
            </View>
          )}
        />
        <StackNav.Screen
          name="Extension Detail"
          component={() => (
            <View style={{ flex: 1, backgroundColor: 'white' }}>
              <SystemHeader system={{ name: "Extension Detail", icon: { name: "puzzle-outline", type: "material-community" }, id: "extension-detail" } as any} />
              <ExtensionDetail />
            </View>
          )}
        />
      </StackNav.Navigator>
    </ExtensionContext.Provider>
  );
}


export const ConfigurePlugins = () => {

  const { haliaPlugin } = React.useContext(HaliaPluginContext);
  // alert(haliaPlugin);

  const installedPlugins = useInstalledPlugins();

  //  TODO:  Make it update to the fucking chagne without reload..

  //  NOTE:  I'm going to COUPLE this in the primtiive encoding BECUASE it "feels" like more fundeamtnal thing.. hm
  const [selectedPlugin, selectPlugin] = React.useState<any>({ plugin: undefined });

  //  TODO-NEXT:  Install / Uninstall Plugins
  //              Configuration
  //              Multiple Apps
  const OptionsComponent = selectedPlugin.plugin?.optionsComponent;
  return (
    <Page style={{ paddingHorizontal: 20, backgroundColor: '#fafafa' }}>

      <View style={{ height: 20 }} />

      <ScrollView style={{ flex: 1, overflow: 'visible' }}>
        <FlatGrid
          style={{ overflow: 'visible' }}
          data={installedPlugins}
          itemDimension={300}
          renderItem={({ item }) =>
            // TODO:  Enable the Plugin with a "Plugin Manager" AND show the STATUS of plugis!? HM!  MAYBE show nested, and ALSO other thigns.. MAYBE support plugins FOR the plugin system.. hmm.. too meta for now I thin haha, BUT maybe!  ALSO, show which are enabled / disabled and stuff...
            <TouchableOpacity style={{ display: 'flex', flexDirection: 'column', height: 300, backgroundColor: 'white', borderRadius: 10, padding: 30, ...shadowStyle }} onPress={() => selectPlugin({ plugin: item })}>
              <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Text style={{ color: '#666666', fontSize: 16, fontFamily: "Poppins-Bold" }}>{item?.details?.name}</Text>
                  <Text style={{ color: '#aaaaaa', fontSize: 13, fontFamily: "Poppins-Bold" }}>{item?.details?.description}</Text>
                </View>
              </View>
              <Button title="Uninstall" onPress={() => haliaPlugin.uninstallPlugin(item)} />
            </TouchableOpacity>}
        />
        <View>
          <Text>{selectedPlugin.plugin?.details.id}</Text>
          {OptionsComponent ? <OptionsComponent /> : null}
        </View>
      </ScrollView>
    </Page>
  )
}

export const ExtensionDetail = () => {

  const { selectedPluginId } = React.useContext(ExtensionContext);
  if (!selectedPluginId) { return <Text>Could not display detail.  Not Plugin was selected.</Text> }
  const { haliaPlugin } = React.useContext(HaliaPluginContext);
  const { getPluginById } = haliaPlugin;
  const plugin = getPluginById(selectedPluginId);
  if (!plugin) { return <Text>Could not display detail.  Selected plugin not found.</Text> }

  const details = plugin?.details as CorePluginDetailsExtended;
  const { name, image, description, detailView: PluginDetailView } = details;

  return (
    <View style={{ flex: 1 }}>
      <PluginCard plugin={plugin} containerStyle={{ borderBottomWidth: 2, borderBottomColor: "#eeeeee", borderRadius: 0 }} />
      {PluginDetailView && <PluginDetailView />}
    </View>
  );
}

//  MAYBE the idea is we LISTEN to events, like a new plugin.  LOL... it's the SAME thing.. we STILL just registerr a function externally that can be called and interact with the component haha.  BUT, mayeb the idea is we can have MANY.  NOT just the one in the outer scope when it's instantiated? HM!
export const pluginListExports: any = {};

//  NOTE:  MAYBE Weave just HOSTS the pluginsa and it's still the user's responsibiltiiy to log which ones are enabled and stuff? hmm.. maybee..  this way we don't have lots of users on Weave hmm.. maybe maybe maybe... 
class PluginListBase extends React.Component<{}, any> {


  constructor(props: any) {
    super(props);
    this.state = {
      //  TODO:  Somehow, MAYBE not here, support dynamic registration!
      // registeredPlugins: [],
      installedPlugins: [],
      plugins: [],
      text: ""
    };
  }

  //  TODO:  Build a "Plugin Provider" component, or maybe just a generic system to de-couple the service interaction.
  // componentDidMount = async () => {

  //   //  Export
  //   //  TODO:  PERHAPS this should be "injected" into the componet?  BUT why not couple it and use exports?? HM!  JUST a choice!  A TRADEOFF? HM!
  //   // pluginListExports.refresh = () => {
  //   //   this.refreshPlugins();
  //   // }

  //   // pluginListExports.registerPlugin = () => {

  //   // }

  //   //  Get Plugins
  //   const plugins = await (await fetch("http://localhost:3000/plugins")).json();

  //   this.setState({ plugins });

  //   // onEnablement(this.reloadPlugins);
  //   // // initPluginService();

  //   // this.reloadPlugins();
  // }

  // reloadPlugins = async () => {
  //   const enablements = await enablementService.retrieveAll();
  //   this.setState({ enabledPlugins: enablements.map(enablement => getPlugin(enablement.id)), plugins: registeredPlugins });
  // }

  // private isEnabled = (plugin: Plugin) => {
  //   const res = this.state.plugins.find(installedPlugin => installedPlugin === plugin);
  //   return res ? true : false;
  // }


  render() {

    return (
      <HaliaPluginContext.Consumer>
        {context => (
          <PageLoader loading={false}>
            {/* <Splitter extraChildren={ context.windows } horizontal={ false }> */}
            <Window>
              <Tab.Navigator>
                {/* <Tab.Screen name="All" component={ListView} />
                <Tab.Screen name="Installed" component={ InstalledView } /> */}
              </Tab.Navigator>
            </Window>
            {/* </Splitter> */}
            {/* TODO:  Shold be able to install GENERALLY and still need to like... WIRE them... hmm... MAY even want to pop in-out of Plugins to see the view of the thing hklmsadf !  POSSBIILY in the RUNTIME context and shit/? Hklsdf  */}
          </PageLoader>
        )}
      </HaliaPluginContext.Consumer>
    )
  }
}

//  THe whole idea here is to make the simple "Object" system... hmm.. BUT we want the ENTITY system too.. the WHOLE pointt is, these are JUST encodings that have MEANING to US and an interpeter!? HJM!! EVEN calling it an "Entity", "SYstem" is JUST ENCODINGS@!!!!

const mapStateToProps = (state: any) => ({
  nav: state.nav
});

// const PluginListRedux: any = connect(mapStateToProps, { startBot, selectBot, stopBot })(PluginListBase);

// const PluginsQuery = gql`
//   query Plugins {
//     plugins{
//       id, state, name, description, strategyState, createdTimestamp
//     }
//   }
// `;

// const PluginListGraphQL: any = graphql(PluginsQuery, { props: (props: any) => {
//   const data: any = props.data;
//   const { plugins: bots = [] } = data;
//   return { ...props, bots, refetch: data.refetch }
// } })(PluginListRedux);

export const PluginList = PluginListBase;
