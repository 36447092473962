
import * as React from 'react';
import { Text } from 'react-native-paper';
import { System2 } from '../../hessia2/hessia2-plugin';
import { TagSetPlugin } from './tag.template';

export class Routine2Plugin extends TagSetPlugin {

  public PluginContext = React.createContext<Routine2Plugin | undefined>(undefined);

  public static details = {
    ...TagSetPlugin.details,
    name: 'Routine',
    description: 'Introduces Routines',
    id: "routine"
  }

  public tagSetId = "routine-type";

  public system: System2 = {
    name: "Routine",
    description: "Adds a Routine System",
    id: "routine-system",
    icon: { name: "replay", type: "material" },
    emoji: "",
    color: "#eeeeee",
    primaryColor: "#aaaaaa",
    component: () => <Text>Routine System</Text>,
    backgroundColor: "#555555"
  }
}