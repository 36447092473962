import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation, useNavigationState, useRoute } from "@react-navigation/native";
import * as React from 'react';
import { TouchableOpacity, View, Text, ViewStyle, TextStyle } from "react-native";
import { Icon } from "react-native-elements";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { capitalizeAllWords } from '../davel-ui/davel-utils';
const uuid = require('uuid/v4');

export type Icon = { name: string, type: string };

/**
 * Displays React Navigation Breadcrumbs
 */
export const Breadcrumbs = ({ containerStyle = {}, textStyle = {} }: { containerStyle?: ViewStyle, textStyle?: TextStyle }) => {

  const routes = useNavigationState(state => state.routes);
  const currentRoute = useRoute();
  const navigation = useNavigation();

  console.log("ROUTES: " + JSON.stringify(routes));

  // const isStack = !!(navigation as any).pop;
  // const isDrawer = !!(navigation as any).openDrawer;
  // const isTab = !(navigation as any).pop && !(navigation as any).openDrawer;

  //  CONSIDER:  Do we still need this?  
  // If it's a tab navigator, modify the routes array to show only the current route's name
  // let displayedRoutes = isTab ? [{ name: currentRoute.name } as any] : routes;

  const displayedRoutes = routes;

  // console.log("Is Tab: " + isTab);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', ...containerStyle }}>
      {displayedRoutes.map((route, index) => (
        <React.Fragment key={route.name}>
          <Text onPress={() => navigation.navigate(route.name as never)} style={{ fontFamily: 'Outfit-SemiBold', fontSize: 16, color: '#555555', ...textStyle }}>
            {capitalizeAllWords(route.name)}
          </Text>
          {index !== displayedRoutes.length - 1 && (
            <Text style={{ fontFamily: 'Outfit-SemiBold', fontSize: 16, color: '#aaaaaa', marginHorizontal: 10, ...textStyle }}>/</Text>
          )}
        </React.Fragment>
      ))}
    </View>
  );
};

export const SystemIcon = ({ icon }: { icon: Icon }) => {

  return (
    <View style={{ padding: 10, borderRadius: 5, height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }}>
      <Icon size={25} type={icon?.type || "ionicon"} name={icon?.name || "info"} color="#555555" />
    </View>
  );

}
export const IconTitle = ({ name, icon, onPress }: { name: string, icon: Icon, onPress?: () => void }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity onPress={() => onPress ? onPress : alert("No onPress handler for IconTitle!")} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "flex-start" }}>
      <Icon size={30} type={icon?.type || "ionicon"} name={icon?.name || "info"} color="#555555" />
      <Text style={{ fontFamily: 'Outfit-SemiBold', fontSize: 20, color: '#555555', paddingLeft: 7 }}>{name}</Text>
    </TouchableOpacity>
  );
}

export const SystemHeader = ({ system, children, breadcrumbs = true }: { breadcrumbs?: boolean, system: { name: string, icon: any }, children?: React.ReactElement[] | React.ReactElement }) => {

  type MyScreenNavigationProp = DrawerNavigationProp<any>;
  const navigation = useNavigation<MyScreenNavigationProp>();
  const { isDesktop } = useSizes();
  return (
    <View style={{ backgroundColor: 'white', height: 60, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 15, borderBottomColor: '#f8f8f8', borderBottomWidth: 1 }}>
      <IconTitle icon={system.icon} name={breadcrumbs ? "" : system.name} />
      {breadcrumbs && <Breadcrumbs />}
      <View style={{ flex: 1 }} />
      {children}
      {
        !isDesktop && (
          <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => navigation.toggleDrawer()}>
            <Icon name="ellipsis-vertical-outline" type="ionicon" size={25} />
          </TouchableOpacity>
        )
      }

    </View>
  );
}