import { Color, HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { Text, View, ViewStyle } from 'react-native';
import { getRaisedStyle, RaisedHeight } from '../../../../packages/kelp-bar/styles';
import { ActionSelection, HaborContainer, PrimitiveProps } from "../../component-plugin/habor-react/habor-component-lib";
// import { performAction } from '../habor-component-core';

//
//  Circle Component
//

export interface CircleProps extends PrimitiveProps {
  userProps: {
    color: string,
    style: ViewStyle;
    title: string;
    onPress: ActionSelection;  //  TODO:  Should app-level "Types" and their "Values" like "Action" and "ActionSelection" be explicitly encoded???
  }
}
export interface CircleState {}

export const HaborCircleComponentPrimitive = ({ userProps, frameworkProps }: PrimitiveProps) => {
  const { context } = frameworkProps;
  const { style, color = Color.primary, onPress } = userProps;
  return (
    // () => performAction(onPress, context)
    <HaborContainer onPress={ () => alert("pressed") } frameworkProps={ frameworkProps } style={{ flex: 0, width: 100, height: 100, borderRadius: 50, backgroundColor: color, ...getRaisedStyle({ raisedHeight: RaisedHeight.low }), ...style }}>
      <View pointerEvents='none' style={{ overflow: 'hidden' }}>
        <Text>CIRCLE</Text>
      </View>
    </HaborContainer>
  );
};


export const HaborCircleComponent: HaborComponent = {
  name: "HaborCircleComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "HaborCircleComponentPrimitive",
    props: {
      color: { type: "symbol", scopePath: ["props", "color"] },
      onPress: { type: "symbol", scopePath: ["props", "onPress"] },
      title: { type: "symbol", scopePath: ["props", "title"] }
    },
    children: []
  }
};
