
import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { GroupCard } from '../../../packages/kelp-bar/group-card';
import { Picker } from '../../../packages/kelp-bar/picker';
import { SystemHeader } from '../../../packages/kelp-bar/system-header';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { GalleryButton } from '../../gallery/components/common';
import { Edge2Plugin } from '../../hessia2/edge2-plugin';
import { Entity2Plugin, EntityContext, EntityTable } from '../../hessia2/entity-plugin';
import { Entity } from '../../hessia2/entity-service';
import { Hessia2Plugin, System2 } from '../../hessia2/hessia2-plugin';
import { Type2Plugin } from '../../hessia2/type-system';
import { HabitContext, HabitEntity, HabitPlugin } from './habits.extension';
import { HierarchyPlugin } from './hierarchy.extension';
import { AaroPlugin } from '../aaro-core';
import { Icon } from 'react-native-elements';
import { TextSubTitle } from '../../../packages/kelp-bar/text';
const uuidv4 = require('uuid/v4');

//  IDEA:  Use COMPOSITION to COMPOSE a PLUGIN.  This way we can mix and match.

export const TagPluginContext = React.createContext<TagSetPlugin | undefined>(undefined);

//  CONSIDER:  We CAN have a "Tag" system which is USED to build other specific tag system???  The idea is we register them as a "Tag Type"??? Hm!  Perhaps!  FOR NOW, let's just subclass.

export class TagSetPlugin extends CorePluginClass {

  public PluginContext = React.createContext<TagSetPlugin | undefined>(undefined);

  public system: System2 = {
    name: "Tag Set",
    description: "Adds a Tag Set",
    id: "tag-entity-extension",
    icon: { name: "albums-outline", type: "ionicon" },
    emoji: "",
    color: "#eeeeee",
    primaryColor: "#aaaaaa",
    component: () => <Text>System</Text>,
    backgroundColor: "#555555"
  }

  public static details = {
    name: 'Tag',
    description: 'Introduces Tags',
    dependencies: [HabitPlugin.details.id, Hessia2Plugin.details.id, Edge2Plugin.details.id, HierarchyPlugin.details.id, Type2Plugin.details.id, Entity2Plugin.details.id, AaroPlugin.details.id],
    id: "tag",
    // CONSIDER:  There are MULTIPLE ways to LOOK at this!  Thus, we may want to use "Blocks" (similar to Notion) where we can make pages / info about thigns... Ugh... The thing is, I'd like to have it be one-way... Notion is pretty limited.  This will open it up.  FOR NOW, we can program it to show things like which things we are adding.  We can finesse it later.
    // TODO:  Be able to TAP on these pieces and see them!
    // TODO:  Show the name of the SPECIFIC "Tag" system.  We call them "Tag" systems, because they are simple associations.  We COULD have also made an "Option" set with multiple or whatever.  That's basically what this is, a Selection / Options Set.
    detailView: () => 
      <View style={{ flex: 1 }}>
        <TextSubTitle>Tag Set</TextSubTitle>
        <Text>This is a Tag Set Plugin!</Text>
        <Text>-  Extension Class</Text>
        <Text>-  Custom Tag Set Entity</Text>
        <Text>-  Entity Extension</Text>
        <Text>-  Aaro Route</Text>
        <Text>-  Habit Filter</Text>
      </View>,
  }

  public tagSetId = "tag-type";

  public getTags = async () => {
    const tags = await this.type2.getInstancesForEntity(this.tagSetId);
    return tags;
  }

  public getEntityTags = async (entityId: string) => {
    const entityParents = await this.hierarchy.getParents(entityId);
    const cats = await this.getTags();
    const catIds = cats.map(cat => cat.payload.id);
    const entityCats = entityParents.filter(parent => catIds.includes(parent.payload.id));
    return entityCats;
  }

  public putTagOnEntity = async (entityId: string, tagEntityId: string) => {
    await this.hierarchy.setEntityParent(entityId, tagEntityId);
  }

  public tagifyEntity = async (entityId: string) => {
    await this.type2.registerInstance(entityId, this.tagSetId, []);
  }

  public createTag = async () => {
    const newTag = await this.entity2.entityService.createEntity({ owners: [], id: uuidv4() });
    await this.tagifyEntity(newTag.payload.id);
  }

  public useEntityTags = (entityId: string) => {
    const [tags, setTags] = React.useState<NounServiceInstanceInternal<Entity<any>>[]>([]);
    const load = async () => {
      const _tags = await this.getEntityTags(entityId);
      setTags(_tags);
    }
    React.useEffect(() => {
      load();
    }, []);
    return tags;
  }

  public useTags = () => {
    const { entities } = React.useContext(EntityContext);
    const [tags, setTags] = React.useState<NounServiceInstanceInternal<Entity<any>>[]>([]);
    const load = async () => {
      const _tags = await this.getTags();
      setTags(_tags);
    }
    React.useEffect(() => {
      load();
    }, [entities]);

    return tags;
  }

  public habit!: HabitPlugin;
  public hessia2!: Hessia2Plugin;
  public graph2!: Edge2Plugin;
  public hierarchy!: HierarchyPlugin;
  public type2!: Type2Plugin;
  public aaro!: AaroPlugin;
  public entity2!: Entity2Plugin;

  public install = async (program: Program, { habit, hessia2, graph2, hierarchy, type2, entity2, aaro }: { aaro: AaroPlugin, habit: HabitPlugin, hessia2: Hessia2Plugin, graph2: Edge2Plugin, hierarchy: HierarchyPlugin, type2: Type2Plugin, entity2: Entity2Plugin }) => {

    this.habit = habit; this.graph2 = graph2;
    this.hessia2 = hessia2;
    this.graph2 = graph2;
    this.hierarchy = hierarchy;
    this.type2 = type2;
    this.aaro = aaro;
    this.entity2 = entity2;

    //  Install the "Tag" Node (if needed)
    const existingTag = await entity2.entityService.getEntityById(this.tagSetId);
    if (!existingTag) {
      await entity2.entityService.createEntity({ exported: true, id: this.tagSetId, name: "Tag", description: "A 'Tag' Type", owners: [] })
    }

    entity2.registerEntityExtension({
      name: this.system.name,
      description: this.system.description,
      id: this.system.id,
      DetailComponent: ({ entity }: { entity?: NounServiceInstanceInternal<Entity> }) => {

        if (!entity) { return <Text>No Entity</Text>; }

        const [selectedId, setSelectedId] = React.useState<string | undefined>(undefined);
        const [tags, setTags] = React.useState<NounServiceInstanceInternal<Entity<any>>[]>([]);
        const [entityTags, setEntityTags] = React.useState<NounServiceInstanceInternal<Entity<any>>[]>([]);

        const loadData = async () => {
          const _tags = await this.getTags();
          if (_tags) { setTags(_tags); }
          const _entityTags = await this.getEntityTags(entity.payload.id);
          if (_entityTags) { setEntityTags(_entityTags); }
        }

        React.useEffect(() => {
          loadData();
        }, []);

        const addTag = async () => {
          if (!selectedId) { alert("Please select a Tag"); return; }
          const res = await this.putTagOnEntity(entity.payload.id, selectedId);
          await loadData();
          alert("Added Tag")
        }

        return <View style={{ backgroundColor: 'white', padding: 30, flex: 1 }}>

          <SystemHeader breadcrumbs={false} system={this.system} />
          <EntityTable entities={entityTags} />
          <GroupCard style={{ padding: 30 }}>
            <Text style={{ fontFamily: "Outfit-SemiBold" }}>Select a Tag</Text>
            <Picker grow={true} onChange={cat => setSelectedId(cat.payload.id)} value={tags.find(cat => cat.payload.id === selectedId)} items={tags.map(cat => ({ label: cat.payload.name || "Unknown", value: cat }))} />
            <GalleryButton title='Add Tag' onPress={addTag} />
          </GroupCard>
        </View>
      },
      systemId: this.system.id,
      icon: this.system.icon
    });

    //  Register a Tag Block 
    //  TODO:  Seprate the BLOCK definition from the Aaro Registration
    this.aaro.registerHOC(({ children }) => {
      const { installRoute } = React.useContext(aaro.AaroContext);
      React.useEffect(() => {
        installRoute({
          name: this.system.name,
          icon: this.system.icon,
          emoji: this.system.emoji,
          component: () => {
            const tags = this.useTags();
            const { loadEntities } = React.useContext(EntityContext);
            return (
              <View style={{ flex: 1, backgroundColor: 'white' }}>
                <SystemHeader breadcrumbs={false} system={this.system}>
                  <Icon name="add" type="material" onPress={async () => {
                    await this.createTag();
                    loadEntities();
                  }} />
                </SystemHeader>
                <EntityTable entities={tags} />
              </View>
            );
          }
        });
      }, []);

      return (
        <>
          {children}
        </>
      );

    });

    habit.registerHOC(({ children }) => {
      const { installHabitFilter } = React.useContext(HabitContext);
      React.useEffect(() => {
        installHabitFilter({
          name: this.system.name,
          component: ({ updateFilteredHabits }: { updateFilteredHabits: (allowedHabits: NounServiceInstanceInternal<HabitEntity>[]) => void }) => {

            const { habits } = React.useContext(HabitContext);
            const { PluginContext } = this;
            const catPlugin = React.useContext(PluginContext);
            if (!catPlugin) { return <Text>Missing Tag Plugin</Text>; }
            const { useTags } = catPlugin;

            const tags = useTags();
            const [selectedCat, selectCat] = React.useState<NounServiceInstanceInternal<Entity<any>>>();

            const filter = async () => {
              if (!selectedCat) { updateFilteredHabits(habits); return; }
              const allowedHabits: NounServiceInstanceInternal<HabitEntity>[] = [];
              for (const habit of habits) {
                const habitCats = await this.getEntityTags(habit.payload.id);
                const hasCat = habitCats.find(cat => cat.payload.id === selectedCat?.payload.id);
                if (hasCat) {
                  allowedHabits.push(habit);
                }
              }
              updateFilteredHabits(allowedHabits);
            }

            React.useEffect(() => {
              filter();
            }, [selectedCat, habits]);

            React.useEffect(() => {
              filter();
            }, []);

            return (
              <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Icon name={ this.system.icon.name } type={ this.system.icon.type } style={{ marginRight: 5 }} size={ 15 } />
                  <Text style={{ fontFamily: "Outfit-SemiBold" }}>{ this.system.name }</Text>
                </View>
                <Picker includeNone={true} style={{ width: 150 }} grow={true} value={selectedCat} items={tags.map(cat => ({ value: cat, label: cat.payload.name || "Unknown" }))} onChange={selectCat} />
              </View>

            );
          }
        });

      }, []);
      return (
        <>
          {children}
        </>
      );
    });

    hessia2.registerHOC(({ children }) => {
      const { PluginContext } = this;
      return (
        <PluginContext.Provider value={this}>
          {children}
        </PluginContext.Provider>
      );
    });


    return this;
  }
}