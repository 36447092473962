import * as React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';

interface TestSubViewProps extends PrimitiveProps {
  userProps: {
    propTest: string
  }
}

//  TODO:  What do we really want to return?  The Habor Primitive name OR the Component OR something else, like the ID of the actual react element?  I think we want a unique ID which identifies this thing in the app... the goal is to be able to MODIFY it.  NOTE, that ALL modification is done to HaborComponents, NOT Primitives... So, what we really care about is updating the HaborComponent in the JSon structure, meaning the props, and potentially replacing the element and / or adding children.  To do that, we need to identify WHICH component it is in the tree!  We do this by padding down the name of the parent, and the child index, and continue doing that.
export const TestSubView = ({ userProps: { propTest }, frameworkProps: { children, config, componentId } }: TestSubViewProps) => {
  return (
    <TouchableOpacity onPress={ config.editMode ? () => config.onPress(componentId) : () => null }>
      <Text>--- SUB VIEW!</Text>
      <Text>--- { propTest }</Text>
    </TouchableOpacity>
  );
};
