import { HaborComponent } from "habor-sdk";

//  TODO:  All "Routes" should accept a "metadata" prop for passed values... But, should it be strongly typed?  As soon as we support an oen object, we sacrifice the strongly typed interface...  FOR NOW, I' just going to make Routes accept any number of params with 'extensible: true'


export const EventsPage: HaborComponent = {
  name: "EventsPage",
  propsSchema: { type: "object", extensible: true },
  query: { type: "noun", search: { match: { inherits: 'event' } } },
  //  DECISION:  I'm going to use a custom "State Variable" format to avoid explicit Functions... Here, we define a State Variable and process with Chaining.
  //  NEW DECISION:  I think we can display this as inputs / outputs and DAW like editor in the frontend, but maybe the backend should store this stuff as functions... Why not just use Typescript functions?  I like having control over which functions are available? 
  //  TODO:  Similar to "Hooks" in React, add "State Variables" which are resolved and then the state is updated to update the component tree.  Support various functions?
  //  NOTE:  I'm trying to avoid forcing the user to do routine things... Like defining inputs and outputs or a function?
  //         I almost prefer the idea of "State Variables", so the user doesn't have to manually define them??  Ideally, we'll keep this mostly functional... I want to avoid things like stateful for-loops.
  element: {
    name: "HaborGridComponent",
    props: {
      itemDimension: { type: "primitive", value: { value: 100, type: { type: "number" } } },
      propsList: {
        type: "chain", 
        links: [
          {
            name: "map",
            params: {
              list: { type: "symbol", scopePath: ["state", "queryResult"] },
              chain: {
                type: "chain",
                links: [
                  {
                    name: "reselect",
                    params: {
                      reselections: [
                        {
                          outputPath: ["title"],
                          value: { type: "link-input", inputPath: ["name"] }
                        },
                        {
                          outputPath: ["onPress"],
                          //  TODO:  Should references to app-level Types like "Action" be explicitly registered?
                          //  TODO:  Instead of "Reselecting" in this confusing format, pre-process the input and resolve variables up-front!  We shouldn't be resolving variables within a Chain (except Chain input)?  How will we handle that?  SAME THING,  the chain is just responsible for iterating the objects I suppose.  Instead of iterating EACH time, we can store a Symbol, and a Symbol lookup table.  Then, when it comes time to realize a value, it happens in some abstracted function?
                          value: {
                            type: "chain",
                            links: [
                              {
                                name: "reselect",
                                params: {
                                  reselections: [
                                    {
                                      outputPath: ["actionId"],
                                      value: { type: "primitive", value: { value: "navigate", type: { type: "keyword" } } }
                                    },
                                    {
                                      outputPath: ["actionParams", "routeId"],
                                      value: { type: "primitive", value: { value: "create-instance", type: { type: "keyword" } } }
                                    },
                                    {
                                      outputPath: ["actionParams", "metadata", "instance"],
                                      value: { type: "link-input" }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            }
          }
        ]
      },
      // propsList: { type: "symbol", scopePath: ["state", "queryResult"] },
      componentId: { type: "primitive", value: { value: "HaborCircleComponent", type: { type: "keyword" } } },
      style: { type: "primitive", value: { value: { padding: 30 }, type: { type: "object", extensible: true } } }
    },
    children: []
  }
};
