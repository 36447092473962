import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { NamedObject, SDTObject } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import { HOCRegister, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { Animated, Button, FlatList, ScrollView, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { IconButton, MD3Colors, Text, Title } from 'react-native-paper';
import { FlatGrid } from 'react-native-super-grid';
import { DavelForm } from '../../../packages/davel-ui/davel-ui';
import { capitalizeAllWords } from '../../../packages/davel-ui/davel-utils';
import { Card } from '../../../packages/kelp-bar/card';
import { KelpIcon } from '../../../packages/kelp-bar/kelp-icon';
import { Period, Selector, SingleDatePicker } from '../../../packages/kelp-bar/single-date-picker';
import { RaisedHeight, primaryFontFamilyHeavy } from '../../../packages/kelp-bar/styles';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { GalleryButton } from '../../gallery/components/common';
import { useAppType, useSizes } from '../../gallery/sizes-helper';
import { Entity2Plugin, EntityContext, EntityPluginContext, EntityTable } from '../../hessia2/entity-plugin';
import { Entity } from '../../hessia2/entity-service';
import { Primitive2Plugin, PrimitivePluginContext, TypeSelection } from '../../hessia2/object-system';
import { AaroContext, AaroPlugin, AaroSystem, AaroTheme } from '../aaro-core';
import { DashboardContext, DashboardPlugin } from './dashboard.extension';
const uuidv4 = require('uuid/v4');
import { EventEmitter } from "events";

//  SDT Schemas
const HabitSchema: SDTObject = {
  type: "object",
  properties: {

    //  TODO:  I REALLY want these properties to be first-class items.  Should be able to describe them, etc.  MUCH more than just add the properties.  It's JUST about association.  It's about how we want to associate.

    //  Habit Core
    //  TODO:  Use Entity for some of these... this is a way to be consistent.
    name: { type: 'keyword', required: true },
    description: { type: 'text', required: false },
    icon: { type: "icon", required: false },
    color: { type: "hex-color", required: false },  //  TODO:  instead of a separate type, use an OPTION.
    "period": { type: 'keyword' },  //  TODO:  Switch to "Option"

    //  Category
    category: { type: "keyword" },

    //  Sub-Category
    subCategory: { type: "keyword" },

    //  Duration
    duration: { type: "number" },

    //  Points
    points: { type: "number" },

    //  Recurrence
    recurrence: { type: "keyword" },

    //  Due Date
    due: { type: "date" },

    //  Keystone
    keystone: { type: "boolean" },

    //  Block
    block: { type: "keyword" },

    // Routine
    //  Link to Routine
    //  CONSIDER:  We can have MANY variations of a "Routine"... the idea is to be able to link to ANY of them???  TO START, it's just the specific though ugh!!!
    routine: { type: "keyword" },

    //  Goals
    goals: { type: "array", itemType: { type: "keyword" } },
    //  TODO:  Should be able to LINK to an existing goal.  This is a new Davel Field SPECIFIC to Hessia??


    //  NOTE:  We CAN do this either with this system OR with another.  It's just about how that's going to be interpreted.  When many systems use the same pattern, then it's a thing.
  }
};

const HabitObservationSchema: SDTObject = {
  type: "object",
  properties: {
    startTime: { type: "keyword" },
    isPending: { type: "boolean" },
    habits: { type: "array", itemType: "keyword" },
    missedHabits: { type: "array", itemType: "keyword" },
    period: { type: "keyword" },
    locked: { type: "boolean" }
  }
};

//  TS Schemas
export interface Habit extends NamedObject {
  "period": string;  //  NOTE:  This (and any field) should be EXTENSIBLE by Plugins!  This means that a type can change!  BUT it doesn't remove things... stable foundation.
  disabled: boolean;
}

export interface HabitObservation {
  startTime: string;
  isPending: boolean;
  habits: string[];
  missedHabits: string[];
  "period": string;
  locked?: boolean;
}

export interface HabitEntity extends Entity {
  value: Habit;
}

export interface ObservationEntity extends Entity {
  value: HabitObservation;
}

//  React Context (for this Extension)
export interface IHabitContext {
  "period": Period;
  setPeriod: any;
  habits: NounServiceInstanceInternal<HabitEntity>[];
  observation?: NounServiceInstanceInternal<ObservationEntity>;
  loading: boolean;
  refreshData: any;
  selectedDate: Moment;
  setSelectedDate: any;
  habitFilters: HabitFilter[];
  installHabitFilter: (filter: HabitFilter) => void;
  habitPlugin?: HabitPlugin;
}
export const HabitContext = React.createContext<IHabitContext>({ habitPlugin: undefined, habitFilters: [], installHabitFilter: (filter: HabitFilter) => undefined, "period": 'day', setPeriod: undefined, habits: [], observation: undefined, loading: true, refreshData: undefined, selectedDate: moment(), setSelectedDate: undefined });


//
//  HabitsBubbleViewItem
//
export interface HabitsBubbleViewItemProps {
  habit: NounServiceInstanceInternal<HabitEntity>;
  refreshData: () => void;
  currentObservation?: NounServiceInstanceInternal<ObservationEntity>;
  selectedDate: moment.Moment;
  "period": Period;
}

const HabitsBubbleViewItem = (props: HabitsBubbleViewItemProps) => {

  const entity2 = React.useContext(EntityPluginContext);
  const primitivePlugin = React.useContext(PrimitivePluginContext);
  const { habitPlugin } = React.useContext(HabitContext);

  //  TODO:  This should IMMEDIATELY update the UI and pass to the server in the background!  FOR NOW, we can rely on the server for our data.  We want to make sure we have a robust caching pattern.
  //  TODO:  This is SUPER similar to the Activty bubble widget... consider combining??  THEN migrate to a HaborComponent for re-use / re-purposing by the user?  Ah... re-purposing is ONE reason we might want to support duck-type props comparison instead of class based when determining the set of suitable components?  ALSO, we COULD let the user MAP between things?
  //  TODO:  GROUP this code in a 'concept' which operates as a wrapper to say what it's doing.  Treat code like an IMPLEMENTATION of a pattern!!!
  //  Update the UI
  //  TODO:  The UI SHOULD eventually be auto-updated with a local cache OR a subscription or something!
  //  TODO:  For this system, use a type that DOEN'T have such options, thus limiting the scope of possible interpretation???
  //  CONSIDER:  'isPending' refers to the status of the endTime.  In this case, we KNOW the time of the event (a period), but the event is still open to be updated.  We MAY want to restrict that as well? 
  //  Update the UI
  //  TODO:  The UI SHOULD eventually be auto-updated with a local cache OR a subscription or something!
  //  CONSIDER:  MAYBE start by parsing each 'Query' to get the associated objects and 'Subscribing' to changes to those and checking if it matches the query on the CLIENT-SIDE!  THAT makes a LOT of sense to me.  THEN, we just pass the changed data (if they have access) to the client, which will then re-evaluate the query based on that thing... That COULD be tricky though, if the query involves multiple objects?? BUT, maybe not... maybe we cache them all, and then when a nested object changes, we just update our cached value.  If a NEW object is provided, that's a CHILD of one of our queries, then we SHOULD be able to get the reference of parent from that child!  Ok... BUT what about when a link changes?  Idk... Hmmm... We COULD let the client send in GUIDS so it knows the name??? THEN, we just make sure that GUID doesn't exist (client could have selected it manually) and if not, then we go ahead and allow it!  Hmm...OR we namespace all names?
  //  TODO:  Instead of searching for the 'Current Observation' build a SCOPE system that we can ask for one based on the DATE!  Or an Ownership system etc.  This way any *match* can be used to indicate association, like the date.  It's an IMPILIED associaiton .. kind of, becaus eit matches the day pattern hm!  SHOULD be able to see EVERYTHING in a SCOPE!


  const habitPressed = async (selectedDate: moment.Moment, completed: boolean) => {

    //  TODO:  Check existing!

    //  Unpack
    const { habit, refreshData, currentObservation } = props;

    if (!habitPlugin) {
      console.error("No Habit Plugin provided to Habits Extension");
      alert("No Habit Plugin");
      return;
    }

    //  Update
    if (currentObservation) {

      //  ABSTRACTION:  Build a PATTERN available in the app to encapsuate this logic declaratively.

      //  Get the Habit List
      const completedHabits: any[] = [...currentObservation.payload.value.habits];  //  CLEANUP:  TYPE THIS
      const missedHabits: any[] = [...(currentObservation.payload.value.missedHabits || [])];  //  CLEANUP:  TYPE THIS

      //  Check Existing Completed
      const existingCompletedHabit = completedHabits.find(_habit => _habit === habit.id);
      const existingCompletedIndex = completedHabits.findIndex(_habit => _habit === habit.id);

      //  Check Existing Missed
      const existingMissedHabit = missedHabits.find(_habit => _habit === habit.id);
      const existingMssedIndex = missedHabits.findIndex(_habit => _habit === habit.id);

      //  Remove Existing (if it exists)
      if (existingCompletedHabit) {
        completedHabits.splice(existingCompletedIndex, 1);
      }
      if (existingMissedHabit) {
        missedHabits.splice(existingMssedIndex, 1);
      }

      //  Create New (if it didn't exist)
      if (!existingCompletedHabit && !existingMissedHabit) {
        if (completed) {
          completedHabits.push(habit.id);
        } else {
          missedHabits.push(habit.id);
        }
      }

      // Update the Observation
      const updatedObservation: HabitObservation = { ...currentObservation.payload.value, habits: completedHabits, missedHabits: missedHabits };
      await primitivePlugin?.updateEntityPrimitive(currentObservation.payload.id, { type: "reference", entityId: habitPlugin?.habitObservationModelId }, updatedObservation);
      await habitPlugin.habitObservationEmitter.emit("UPDATE", { prev: currentObservation, next: updatedObservation });
      await refreshData();
    }

    //  Create
    else {

      //  Create the Habit List
      const habits: string[] = completed ? [habit.id] : [];
      const missedHabits: string[] = !completed ? [habit.id] : [];

      const observationEntity: Entity = {
        name: "Habit Observation",
        description: "Habit Observation",
        id: uuidv4(),
        owners: [AaroSystem]
      };

      const observation: HabitObservation = {
        startTime: selectedDate.toDate().toISOString(),
        isPending: false,
        habits,
        missedHabits,
        "period": props.period
      };

      await primitivePlugin?.createObject(observationEntity, { type: "reference", entityId: habitPlugin?.habitObservationModelId }, observation);
      await habitPlugin.habitObservationEmitter.emit("CREATE", { observation });
      await refreshData();
    }

  }

  const navigation = useNavigation();

  //  Unpack
  const { currentObservation, habit, habit: { id, payload: { name, value: { icon = { name: 'run', type: 'material-community' } } } } } = props;

  //  Determine Checkin Status
  //  NOTE:  This is JUST an example of a pattern where we havve a THING.. BOOLEAN thing LINKED to another thing hM!  We implement it with this particular pattern, BUT it's a more generic thing and I'd like to encode it as such hm!
  const observation: HabitObservation | undefined = currentObservation?.payload.value;
  const checkedIn = observation ? observation.habits.find((habitId: string) => habitId == id) : false;
  const missed = observation ? (observation.missedHabits || []).find((habitId: string) => habitId == id) : false;

  const backgroundColor = checkedIn ? AaroTheme.primaryColor : missed ? MD3Colors.error95 : '#fafafa';
  const foregroundColor = checkedIn ? 'white' : missed ? MD3Colors.error60 : '#aaaaaa'
  const borderColor = checkedIn ? AaroTheme.primaryColorBorder : missed ? MD3Colors.error60 : '#eeeeee';
  const buttonBorderColor = checkedIn ? 'white' : missed ? MD3Colors.error60 : '#eeeeee';

  const { openEditor, selectEntity } = React.useContext(EntityContext);

  return (

    //  TODO:  Instead of BORDER COLOR, consider registering a GROUP for this to be GROUPED by!?  I would MUCH prefer that I think?  BUT it's COOl that this type of STYLE change is an option!??? SOME other systems MIGHT want to take advantage of that!?  MAYBE we should expose the ENTIRE interface or redefine some smaller, reasonable subsets for injection / overrides by systems???
    // <Card raisedHeight={ RaisedHeight.none } outerStyle={{ backgroundColor: Color.offWhite, borderColor: borderColor || undefined, borderWidth: borderColor ? 1 : 0 }} innerStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 90, padding: 10 }} onPress={ this.habitPressed }>

    // @ts-ignore  - TODO:  Fixme!
    <Card raisedHeight={RaisedHeight.none} outerStyle={{ backgroundColor, borderRadius: 10, borderWidth: 2, borderColor }} innerStyle={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 130, padding: 10 }} onPress={() => { selectEntity(habit); openEditor(); }}>
      <KelpIcon name={icon.name} type={icon.type} color={foregroundColor} size={30} />
      <View style={{ height: 10 }} />
      <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 10, color: foregroundColor, textAlign: 'center' }}>{name}</Text>

      <View style={{ flex: 1 }} />
      <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'stretch' }}>
        <Icon color={foregroundColor} style={{ borderWidth: 2, borderColor: buttonBorderColor, width: 30, height: 30, borderRadius: 15, alignItems: 'center', justifyContent: 'center' }} size={20} name="check" type="material" onPress={() => habitPressed(props.selectedDate, true)} />
        <View style={{ flex: 1 }} />
        <Icon color={foregroundColor} style={{ borderWidth: 2, borderColor: buttonBorderColor, width: 30, height: 30, borderRadius: 15, alignItems: 'center', justifyContent: 'center' }} size={20} name="close" type="material" onPress={() => habitPressed(props.selectedDate, false)} />
      </View>
    </Card>
  );
}

//
//  HabitsBubbleView
//

//  TODO:  In the NEAR future, we want to remove ALL these hard-coded views and let them be built by the user in a reasonable way... Mixing and matching components!  MAYBE we can have several PATTERNS BETWEEN components where several elements of the overall pattern are swappable, like the 'Filter' bar, BUT although the interface is changing, the purpose of the piece within the pattern / system remains the SAME at the abstraction of the system.
//  IDEA:  Consider making several versions of a view based on the incoming item type??  For 'Events', it's possible to support more component types??  FOR NOW, let's build out the app as if we were in a native context.  This is ESSENTIALLY acting as a prototype, BUT it's going to be functional!
//  NOTE:  This is just a 'Quick Entry' form, we have another, more generic 'Trackers' / 'Event' page to upload other ones?
export interface HabitsBubbleViewProps {
  habits: NounServiceInstanceInternal<HabitEntity>[];
  refreshData: () => void;
  currentObservation?: NounServiceInstanceInternal<ObservationEntity>;
  selectedDate: moment.Moment;
  "period": Period;
}

interface HabitsBubbleViewState { }

class HabitsBubbleViewBase extends React.Component<HabitsBubbleViewProps, HabitsBubbleViewState> {
  constructor(props: HabitsBubbleViewProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { habits = [], refreshData, currentObservation } = this.props;

    //  Generate UIs
    //  TODO-CRITICAL:  It's up to us how we want this to work... we COULD have the 'Group' Plugin 'Hook' into the list views and automatially add Component Settings to support grouping... OR, we can just wrap that view and make a new GroupedListView?  Both seem like validish options... the first seems more maneagable thuogh... Otherwise we'll end up with an explosion of names... Yes I DO like that approach better I think.  However, that doesn't stop us from using the ListComponent WITHOUT grouping and making our OWN grouping code kind of like we're doing here. 
    return (
      <FlatGrid
        itemDimension={100}
        data={habits}
        renderItem={({ item }) => <HabitsBubbleViewItem period={this.props.period} selectedDate={this.props.selectedDate} currentObservation={currentObservation} refreshData={refreshData} habit={item} />}
        spacing={10}
        style={{ margin: -10, padding: 0, overflow: 'visible', minHeight: 500 }}
      />
    );

  }
}
export const HabitsBubbleView = HabitsBubbleViewBase

const HabitStackNav = createStackNavigator<any>();

export const HabitHome = () => {
  return (
    <HabitStackNav.Navigator initialRouteName='Habits' screenOptions={{ headerShown: false }}>
      <HabitStackNav.Screen name="EditHabit" component={({ route }) => <EditHabit habitEntity={route.params.habit} />} />
      <HabitStackNav.Screen name="Habits" component={() => <HabitList />} />
    </HabitStackNav.Navigator>
  );

}
export const HabitList = () => {

  const entity2 = React.useContext(EntityPluginContext);
  const { habits, habitPlugin } = React.useContext(HabitContext);
  const navigation = useNavigation<any>();

  const habitList = [
    { "name": "Monthly Finances", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Process your monthly finances.  Pay bills, distribute funds, etd.", "duration": "1 Hour", "points": 800, "recurrence": "2nd Sunday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "period": "day" },
    { "name": "Water Goal", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "12 Cups (Hydropeak is 4 cups)", "duration": "N/A", "points": 50, "recurrence": "Daily", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Morning Wash Face", "type": "Habit", "category": "Personal", "subCategory": "Skincare", "duration": "30 Seconds", "points": 25, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Morning Moisturize", "type": "Habit", "category": "Personal", "subCategory": "Skincare", "duration": "30 Seconds", "points": 25, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Morning Brush", "type": "Habit", "category": "Personal", "subCategory": "Dental", "duration": "1 Minutes", "points": 25, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Fill Heaters", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "If Home obviously", "duration": "3 Minutes", "points": 25, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Open Shades", "type": "Habit", "category": "Personal", "subCategory": "-", "duration": "30 Seconds", "points": 25, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Make Bed", "type": "Habit", "category": "Personal", "subCategory": "-", "duration": "5 Minutes", "points": 100, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Weigh In", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Weigh myself.", "duration": "1 Minute", "points": 25, "recurrence": "Daily", "due": "10:30 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Log Entire Diet", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "", "duration": "5 Minutes", "points": 50, "recurrence": "Daily", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Journal", "type": "Habit", "category": "System", "subCategory": "-", "duration": "5 Minutes", "points": 50, "recurrence": "Daily", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Tether", "type": "Habit", "category": "System", "subCategory": "-", "duration": "5 Minutes", "points": 100, "recurrence": "Daily", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "REMOVED", "type": "Habit", "category": "", "subCategory": "", "duration": "", "points": "", "recurrence": "", "due": "", "keystone": "", "block": "", "routine": "", "goals": [], "period": "day" },
    { "name": "Personal I", "type": "Habit", "category": "Personal", "subCategory": "-", "duration": "25 Minutes", "points": 200, "recurrence": "Daily", "due": "4:45 PM", "keystone": "Yes", "block": "Yes", "routine": "2", "goals": [], "period": "day" },
    { "name": "Daily Finances", "type": "Habit", "category": "System", "subCategory": "-", "description": "Record spending for the week (consider daily as well)", "duration": "10 Minutes", "points": 100, "recurrence": "Sunday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Backups", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Backup Phone, Mac, Watch, and iPad", "duration": "1 Hour", "points": 400, "recurrence": "Sunday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Chores", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Process chores.  Get 200 additional once completed.", "duration": "1 Hour", "points": 200, "recurrence": "Sunday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Water Plants", "type": "Habit", "category": "Personal", "subCategory": "-", "duration": "5 Minutes", "points": 25, "recurrence": "Sunday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Calorie Limit", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "1850 cals (+/- 10 cals)", "duration": "N/A", "points": 150, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Protein Min", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "140g (+/- 5g)", "duration": "N/A", "points": 150, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Sodium Limit", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "2300mg", "duration": "N/A", "points": 25, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Fat. Limit", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "35% Max", "duration": "N/A", "points": 25, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Sat..Fat Limit", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "10% Max", "duration": "N/A", "points": 25, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Added Sugar Limit", "type": "Habit", "category": "Diet", "subCategory": "-", "description": "36g (AHA Ceiling)", "duration": "N/A", "points": 25, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Lights Out", "type": "Habit", "category": "Sleep", "subCategory": "-", "description": "Bedtime at 4:30am (+/- 5 mins)", "duration": "N/A", "points": 100, "recurrence": "Sunday - Thursday", "due": "T 2:30:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Magnesium", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "L-Theanine", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Wind Down", "type": "Habit", "category": "Sleep", "subCategory": "-", "description": "In bed by 4:00am (+/- 5 mins)", "duration": "1 Hour", "points": 100, "recurrence": "Sunday - Thursday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Skin Pics", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Regular pictures of skin for review.", "duration": "2 Minutes", "points": 25, "recurrence": "Wednesday", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Venture II", "type": "Habit", "category": "Work", "subCategory": "-", "description": "30 Min / 100 Point Tasks x 2 for Importance", "duration": "30 Minutes", "points": 200, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "2", "goals": [], "period": "day" },
    { "name": "Log Block II Diet", "type": "Habit", "category": "Diet", "subCategory": "-", "duration": "5 Minutes", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "3", "goals": [], "period": "day" },
    { "name": "Tracked Activity (REMOVED)", "type": "Removed", "category": "", "subCategory": "", "duration": "", "points": "", "recurrence": "", "due": "", "keystone": "", "block": "", "routine": "", "goals": [], "period": "day" },
    { "name": "Collagen", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Multivitamin", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "2", "goals": [], "period": "day" },
    { "name": "Creatine", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Workout", "type": "Habit", "category": "Exercise", "subCategory": "-", "description": "Workout (4 exercises)", "duration": "1.5 Hours", "points": 800, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Shower", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "30 Min Shower + Personal", "duration": "30 Minutes", "points": 100, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Ridholia", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "2", "goals": [], "period": "day" },
    { "name": "Early Bird", "type": "Habit", "category": "Sleep", "subCategory": "-", "description": "Wake by 10:45am (+/- 5 mins)", "duration": "N/A", "points": 500, "recurrence": "Weekday", "due": "11:30 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "5HTP", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Weekday", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Vitamin D", "type": "Habit", "category": "Diet", "subCategory": "Supplements", "duration": "Instant", "points": 25, "recurrence": "Weekday", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Log Block I Diet", "type": "Habit", "category": "Diet", "subCategory": "-", "duration": "5 Minutes", "points": 25, "recurrence": "Weekday", "due": "10:30 AM", "keystone": "Yes", "block": "No", "routine": "2", "goals": [], "period": "day" },
    { "name": "Log Night Diet", "type": "Habit", "category": "Diet", "subCategory": "-", "duration": "5 Minutes", "points": 25, "recurrence": "Weekday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Client II", "type": "Habit", "category": "Work", "subCategory": "-", "description": "30 Min / 100 Point Tasks x 2 for Importance", "duration": "30 Minutes", "points": 200, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "2", "goals": [], "period": "day" },
    { "name": "Client III (REMOVED)", "type": "Removed", "category": "", "subCategory": "", "duration": "", "points": "", "recurrence": "", "due": "", "keystone": "", "block": "", "routine": "", "goals": [], "period": "day" },
    { "name": "Client IV (REMOVED)", "type": "Removed", "category": "", "subCategory": "", "duration": "", "points": "", "recurrence": "", "due": "", "keystone": "", "block": "", "routine": "", "goals": [], "period": "day" },
    { "name": "Venture III (REMOVED)", "type": "Removed", "category": "", "subCategory": "", "duration": "", "points": "", "recurrence": "", "due": "", "keystone": "", "block": "", "routine": "", "goals": [], "period": "day" },
    { "name": "Venture (IV REMOVED)", "type": "Removed", "category": "", "subCategory": "", "duration": "", "points": "", "recurrence": "", "due": "", "keystone": "", "block": "", "routine": "", "goals": [], "period": "day" },
    { "name": "Client I", "type": "Habit", "category": "Work", "subCategory": "-", "description": "30 Min / 100 Point Tasks x 2 for Importance", "duration": "30 Minutes", "points": 200, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Venture I", "type": "Habit", "category": "Work", "subCategory": "-", "description": "30 Min / 100 Point Tasks x 2 for Importance", "duration": "30 Minutes", "points": 200, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Night Stats", "type": "Habit", "category": "System", "subCategory": "-", "duration": "1 Minute", "points": 25, "recurrence": "Daily", "due": "T 1:30 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Start Stats", "type": "Habit", "category": "System", "subCategory": "-", "duration": "1 Minute", "points": 25, "recurrence": "Daily", "due": "10:00 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Checkin 1", "type": "Habit", "category": "System", "subCategory": "Block 1 Checkin", "duration": "10 Mins", "points": 50, "recurrence": "Weekday", "due": "9:00 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Block 1 Schedule", "type": "Habit", "category": "System", "subCategory": "Was the Block I Schedule Followed?", "duration": "N/A", "points": 100, "recurrence": "Weekday", "due": "12:45 PM", "keystone": "Yes", "block": "No", "routine": "2", "goals": [], "period": "day" },
    { "name": "Block 2 Schedule", "type": "Habit", "category": "System", "subCategory": "Was the Block II Schedule Followed?", "duration": "N/A", "points": 100, "recurrence": "Weekday", "due": "4:45 PM", "keystone": "Yes", "block": "No", "routine": "3", "goals": [], "period": "day" },
    { "name": "Morning Routine", "type": "Habit", "category": "Personal", "subCategory": "", "duration": "30 Mins", "points": 100, "recurrence": "Daily", "due": "9:00 AM", "keystone": "Yes", "block": "Yes", "routine": "1", "goals": [], "period": "day" },
    { "name": "Checkin 2", "type": "Habit", "category": "System", "subCategory": "Block 2 Checkin", "duration": "10 Mins", "points": 50, "recurrence": "Weekday", "due": "12:45 PM", "keystone": "Yes", "block": "Yes", "routine": "2", "goals": [], "period": "day" },
    { "name": "Checkin 3", "type": "Habit", "category": "System", "subCategory": "Block 3 Checkin", "duration": "10 Mins", "points": 50, "recurrence": "Weekday", "due": "4:45 PM", "keystone": "Yes", "block": "Yes", "routine": "3", "goals": [], "period": "day" },
    { "name": "Checkin 4", "type": "Habit", "category": "System", "subCategory": "Block 4 Checkin", "duration": "10 Mins", "points": 50, "recurrence": "Weekday", "due": "T 1:30 AM", "keystone": "Yes", "block": "Yes", "routine": "4", "goals": [], "period": "day" },
    { "name": "Nails", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Clip finger nails and toe nails", "duration": "10 Mins", "points": 50, "recurrence": "2nd and 4th Wednesday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "1", "goals": [], "period": "day" },
    { "name": "Beard Penning", "type": "Habit", "category": "Personal", "subCategory": "-", "description": "Dermapen my beard", "duration": "0:10:00", "points": 100, "recurrence": "Wednesday", "due": "T 1:50:00 AM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Log Block 3 Diet", "type": "Habit", "category": "Diet", "subCategory": "-", "duration": "5 Minutes", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "4", "goals": [], "period": "day" },
    { "name": "Log Block 4 Diet", "type": "Habit", "category": "Diet", "subCategory": "-", "duration": "5 Minutes", "points": 25, "recurrence": "Weekday", "due": "4:00 PM", "keystone": "Yes", "block": "No", "routine": "5", "goals": [], "period": "day" }
  ];


  const textStyle: TextStyle = { fontFamily: "Inter-Bold", color: '#555555' };

  const propOptions: PropOptionsObject = {
    "period": { excluded: true },
    category: { excluded: true },
    color: { excluded: true },
    subCategory: { excluded: true },
    duration: { excluded: true },
    points: { excluded: true },
    recurrence: { excluded: true },
    due: { excluded: true },
    keystone: { excluded: true },
    block: { excluded: true },
    routine: { excluded: true },
    goals: { excluded: true },
    icon: {
      component: ({ value: { type = 'material', name = 'dot' } = { type: "material", name: "dot" } }: { value: { type: string, name: string } }) => {
        return <KelpIcon name={name} type={type} size={20} />
      }
    },
    id: {
      component: ({ value }: { value: any }) => {
        const habit = habits.find(habit => habit.id === value);
        if (!habit) { return null; }
        const navigate = navigation.navigate as any;
        return (
          <>
            <Text>{value}</Text>
            <Button title='Edit' onPress={() => navigate("EditHabit", { habit })} />
          </>
        )
      }
    }
  };

  // const loadData = async () => {
  //   if (!habitPlugin) {
  //     console.error("No Habit Plugin!");
  //     return;
  //   }
  //   const existingNames = habits.map(habit => habit.payload.name);
  //   for (const habit of habitList) {
  //     if (!existingNames.includes(habit.name)) {
  //       console.log("Attempting!");
  //       try {

  //         //  Create the Habit Entity
  //         //  CONSIDER:  It's still REALLY bothersome that I put EXPLICIT text in things like "name".  Instead I'd like to be able to PROJECT... adapt I guess, but what should the MAIN context be? hmm...
  //         const _habit: any = { ...habit };
  //         delete _habit["type"];
  //         const __habit: Habit = { ..._habit };
  //         const habitEntity = habitPlugin.habitToEntity(__habit);
  //         const internalHabit = await entity2?.entityService.createEntity(habitEntity);
  //         console.log("Created Habit: " + JSON.stringify(internalHabit));
  //       } catch (err) {
  //         alert(err);
  //       }
  //     }
  //   }
  // }
  React.useEffect(() => {
    // loadData();
  }, []);

  return (
    <>
      <HabitsHeader title="Habits" navigation={navigation} />
      <ScrollView>
        <EntityTable entities={habits} onPress={entity => navigation.navigate("EditHabit", { habit: entity })} />
      </ScrollView>
    </>

  );
}

export interface PropOptionsObject { [propName: string]: PropOptions };

export interface PropOptions {
  component?: any;
  excluded?: boolean;
}

export interface DavelTableProps {
  objects: any[];
  schema: SDTObject;
  order: string[];  //  List of property names for column ordering
  propOptions: { [propName: string]: PropOptions };
};

export const DavelTable = ({ objects, schema, order, propOptions }: DavelTableProps) => {

  const properties = schema.properties || {};

  const columns = Object.keys(properties).map(propName => {

    if (!!propOptions[propName]?.excluded) {
      return undefined;
    }

    return (

      // Column View
      <View style={{ flex: 1, flexDirection: 'column' }}>

        {/* Header Cell */}
        <View style={{ flex: 1, height: 50, alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#f8f8f8', padding: 15 }}>
          <Text style={{ fontFamily: 'Outfit-Regular', fontSize: 16, fontWeight: '700' }}>{capitalizeAllWords(propName)}</Text>
        </View>

        {
          objects.map(obj => {
            const value = obj[propName];
            console.log(value);

            const Component = propOptions[propName]?.component;

            return (
              <View style={{ flex: 1, height: 50, alignItems: 'flex-start', justifyContent: 'center', padding: 15 }}>
                {Component ? <Component value={value} /> : <Text style={{ fontFamily: 'Outfit-Regular', fontSize: 16 }}>{`${value}`}</Text>}
              </View>
            )
          })
        }
      </View>
    );
  }).filter(comp => comp != undefined);

  return (
    <View style={{ flexDirection: 'row', backgroundColor: 'white' }}>
      {columns}
    </View>
  );
};

export const EditHabit = ({ habitEntity }: { habitEntity?: NounServiceInstanceInternal<Entity> }) => {

  const entity2 = React.useContext(EntityPluginContext);

  const { detailWidgets } = React.useContext(AaroContext);
  if (!habitEntity) { return <Text>No Habit Selected</Text> }
  const navigation = useNavigation();


  const saveHabit = async (value: Habit) => {
    const newHabitEntity = await entity2?.entityService.entityNounService.update(habitEntity.id, {
      ...habitEntity.payload,
      value
    });
    console.log("Updated Habit: " + JSON.stringify(newHabitEntity));
    navigation.navigate("Habits" as never);
  }

  return (
    <ScrollView style={{ flex: 1, backgroundColor: 'white', padding: 30 }}>
      <DavelForm value={habitEntity?.payload} schema={HabitSchema} onSubmit={saveHabit} />
      <Title>Extensions</Title>
      {detailWidgets.map(WidgetComponent => <WidgetComponent.component habit={habitEntity} />)}
      <GalleryButton title="Delete" onPress={() => entity2?.entityService.entityNounService.delete(habitEntity?.id)} />
    </ScrollView>
  );
}

const periodButtons = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'fortnight', label: 'Fort' },
  { value: 'month', label: 'Month' },
  { value: 'quarter', label: 'Quater' },
];

const HabitFilterBar = ({ updateFilteredHabits }: { updateFilteredHabits: (allowedHabits: NounServiceInstanceInternal<HabitEntity>[]) => void }) => {

  const { habitFilters = [] } = React.useContext(HabitContext);

  return (
    <View style={{ flexDirection: 'row', backgroundColor: "#fafafa", padding: 15, borderRadius: 10, marginVertical: 20, borderWidth: 2, borderColor: "#eeeeee", alignItems: 'center' }}>
      <FlatList horizontal={ true } data={ habitFilters } ItemSeparatorComponent={() => <View style={{ width: 10 }} />} renderItem={(filter) => <filter.item.component updateFilteredHabits={updateFilteredHabits} />} />
    </View>
  );

};

export const HabitDashboardWidget = () => {

  const primitive2 = React.useContext(PrimitivePluginContext);
  const { habitPlugin } = React.useContext(HabitContext);
  const { habits = [], observation, refreshData, period, selectedDate, setPeriod, setSelectedDate } = React.useContext(HabitContext);

  const toggleLock = async () => {

    if (observation) {
      const updatedObservation: HabitObservation = { ...observation.payload.value, locked: !observation.payload.value.locked };
      primitive2?.updateEntityPrimitive(observation.id, undefined, updatedObservation);
      refreshData();
    }
    else {

      const newObservation: HabitObservation = {
        startTime: selectedDate.toDate().toISOString(),
        isPending: false,
        habits: [],
        missedHabits: [],
        period,
        locked: true
      };

      if (!habitPlugin) {
        console.error("No Habit Plugin provided to Habits Dashboard");
        alert("No Habits Plugin provided to Habits Dashboard");
        return;
      }

      const newObservationEntity: Entity = {
        id: uuidv4(),
        name: "Habit Observation",
        description: "Habit Observation",
        owners: [AaroSystem]
      };

      primitive2?.createObject(newObservationEntity, { type: "reference", entityId: habitPlugin?.habitObservationModelId }, newObservation)
      refreshData();
    }

  }

  React.useEffect(() => {
    refreshData();
  }, []);

  const [filteredHabits, setFilteredHabits] = React.useState<NounServiceInstanceInternal<HabitEntity>[]>([...habits]);

  React.useEffect(() => {
    setFilteredHabits([...habits]);
  }, [habits]);

  //  CONSIDER:  It may make more sense to indicate which to block?
  const updateFilteredHabits = (allowedHabits: NounServiceInstanceInternal<HabitEntity>[] = []) => {
    const _allowedHabits = [...habits];
    const updatedFilteredHabits = _allowedHabits.filter(habit => allowedHabits.includes(habit));
    setFilteredHabits(updatedFilteredHabits);
  }

  return (
    <>

      {/* Period Selection View */}
      {/* THOUGHTS:  HATE that I need to encode this expliclty  HAte tht I don't have a way to REFERENCE this as a stable thing without polluting the codebase.  CAN use a separate file... ugh.  SO many people become COMPLACENT!!!  The key is to CONTINUE nothing things that don't need to be the way they are!  SO much opportunity!!! */}

      <Selector buttons={periodButtons} value={period} onValueChange={setPeriod} />

      <View style={{ height: 10 }} />

      <SingleDatePicker period={period} selected={selectedDate} onSelected={setSelectedDate} />

      <View style={{ height: 20 }} />

      {/* CONSIDER:  I ALREADY did the SAME thing with entities!  Should be able to deploy this SAME pattern!!!   */}
      <HabitFilterBar updateFilteredHabits={updateFilteredHabits} />

      <HabitsBubbleView period={period} selectedDate={selectedDate} currentObservation={observation} refreshData={refreshData} habits={filteredHabits} />

      <IconButton
        icon='lock'
        iconColor={observation?.payload?.value.locked ? MD3Colors.primary60 : MD3Colors.neutral90}
        size={40}
        onPress={() => toggleLock()}
      />
    </>

  );
}

export const StickersPluginContext = React.createContext<HabitPlugin>({} as any);
export const useStickersPlugin = () => {
  return React.useContext(StickersPluginContext);
}

export interface HabitsHeaderProps {
  borderOpacity?: any, title: any, navigation: any, showFilter?: any, style?: any
}

const HabitsHeader = ({ borderOpacity, title, navigation, showFilter, style = {} }: HabitsHeaderProps) => {

  const { headerWidgets, installedList } = React.useContext(AaroContext);

  const additional = headerWidgets.map(widget => {
    if (installedList.includes(widget.pluginId)) {
      const Widget = widget.component;
      return <Widget />;
    }
    return null;
  })

  // const { setShowDrawer, showDrawer } = React.useContext(HabitContext);

  // TODO:  Only check 54pt for native!
  // TODO:  SHOULD be able to AUTOMATICALLY add thigns like this for mobile and web and mm!  Be able to use AI to updazte my systems!

  // const isIos = Platform.OS === 'ios';

  const primitivePlugin = React.useContext(PrimitivePluginContext);
  const { habitPlugin } = React.useContext(HabitContext);

  const { isDesktop } = useSizes();

  const createHabit = async () => {
    const habit: Habit = { name: "New Habit", "period": "day", disabled: false };
    const habitEntity: Entity = { id: uuidv4(), name: "New Habit", description: "New Habit", owners: [AaroSystem] };
    if (!habitPlugin) {
      console.error("No Habit Plugin provided to the Habits Header");
      alert("No Habit Plugin provided to the Habits Header");
      return;
    }
    const habitType: TypeSelection = { type: "reference", entityId: habitPlugin?.habitModelId }
    const newHabit = await primitivePlugin?.createObject(habitEntity, habitType, habit);
    navigation.navigate("EditHabit", { habit: newHabit });
  };

  return (

    <View style={{ backgroundColor: 'white', justifyContent: 'flex-start', ...style }}>
      <>
        <View style={[styles.menuContainer, { paddingVertical: 15 }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>

            {
              !isDesktop && (
                <TouchableOpacity style={{ marginRight: 15, alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.toggleDrawer()}>
                  <Icon name="menu" type="entypo" size={30} color="#555555" />
                </TouchableOpacity>
              )
            }

            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ fontFamily: 'Inter-Bold', fontSize: 25, color: "#555555", letterSpacing: -0.5 }}>{title}</Text>
            </View>
          </View>




          <View style={styles.titleContainer}>

            {
              additional.map(_additional => (
                <View style={{ marginHorizontal: 5 }}>
                  {_additional}
                </View>
              ))
            }

            <TouchableOpacity style={{ backgroundColor: '#fbfbfb', borderRadius: 25, width: 50, height: 50, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }} onPress={createHabit}>
              <Icon color="#888888" name="plus" type="font-awesome" size={19} />
            </TouchableOpacity>

          </View>
        </View>
        <Animated.View style={{ height: 2, backgroundColor: '#eeeeee', opacity: borderOpacity }} />
      </>
    </View >


  );
};

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    width: '100%'
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end'
  },
  title: {
    fontSize: 18,
    fontFamily: 'Inter-SemiBold'
  },
});




const DrawerNav = createDrawerNavigator();


// const LeftMenu = () => {

//   const { isMobile } = useSizes();
//   const { showDrawer } = React.useContext(HabitContext);

//   const routeNames = useNavigationState(state => {
//     return state?.routes?.map(route => route.name)
//   });

//   const navigation = useNavigation();

//   if (isMobile && !showDrawer) { return null; }
//   return (
//     <View style={{ width: 200, position: isMobile ? 'absolute' : 'relative', zIndex: 1000 }}>
//       {
//         routeNames?.map((name, index) => <Text onPress={() => navigation.navigate(name as never)} key={index}>{name}</Text>)
//       }
//     </View>
//   );
// }

//  TODO-FUNDAMENTAL:  Multiple components for multiple contexts.  The IDEA is that we should be able to treat a component as ANY other part of the network... so it's JUST an enity.  FOR NOW, we'll define this in the primitive system.
export interface Widget extends NamedObject {
  component: any;
  pluginId: string;
}

export interface DetailWidget extends NamedObject {
  component: any;  //  TODO:  Type with a prop for habit
  pluginId: string;
}

export interface HabitFilter extends NamedObject {
  // filter: (habit: NounServiceInstanceInternal<Habit>, state: S) => boolean;
  component: any;
}

//  CONSIDER:  Nested Platforms - Make it EASY to build a new "platform" to which we can nest.  Not JUST with react navigation etc hmm...

export class HabitPlugin extends CorePluginClass {

  public habitObservationEmitter = new EventEmitter();
  public habitEmitter = new EventEmitter();

  public static details = {
    name: 'Habits',
    description: 'Manage your recurring habits',
    dependencies: [Entity2Plugin.details.id, AaroPlugin.details.id, Primitive2Plugin.details.id, DashboardPlugin.details.id],
    id: 'habit',
    image: require("../../../assets/stickers/habits.png")
  }

  public registerHOC = (wrapper: any) => {
    registerHOC("habit", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("habit", wrapper);
  }

  //  ID for the Habit Model Primitive
  public habitModelId = "habit-model-new";

  //  IF for the HabitObservation Model Primitive
  public habitObservationModelId = "habit-observation-model-new";

  public habitToEntity = (habit: Habit): HabitEntity => {
    return {
      name: habit.name,
      description: habit.description,
      id: uuidv4(),
      owners: [AaroSystem],
      type: { type: "reference", entityId: this.habitModelId },
      value: habit
    };
  }

  public observationToEntity = (observation: HabitObservation): ObservationEntity => {
    return {
      name: "Habit Observation",
      description: "Observes a Habit",
      id: uuidv4(),
      owners: [AaroSystem],
      type: { type: "reference", entityId: this.habitObservationModelId },
      value: observation
    };
  }

  //  NOTE:  We explictly depend on "Dashboard" to ENSURE that it's installed BEFORE we run our script!  This helps to arrange the contexts in the proper tree... but it seems redundant ugh!!!

  public install = async (program: Program, { entity2, aaro, primitive2, dashboard }: { dashboard: DashboardPlugin, primitive2: Primitive2Plugin, entity2: Entity2Plugin, aaro: AaroPlugin }) => {

    //  Build the Habit Model
    const habitModelEntity = await entity2.entityService.getEntityById(this.habitModelId);
    if (!habitModelEntity) {
      console.log("Building Habit Model");
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = HabitSchema;
      const entity = { id: this.habitModelId, name: "Habit Model", description: "Habit Model Entity", owners: [AaroSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("Habit Model Exists!");
    }

    //  Build the Observation Model
    const habitObservationModelEntity = await entity2.entityService.getEntityById(this.habitObservationModelId);
    if (!habitObservationModelEntity) {
      console.log("Building Habit Observation Model");
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = HabitObservationSchema;
      const entity = { id: this.habitObservationModelId, name: "Habit Observation Model", description: "Habit Observation Model Entity", owners: [AaroSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("Habit Observation Model Exists!");
    }

    registerHOCRegister("habit");

    aaro.registerHOC(({ children }) => {

      const [habitFilters, setHabitFilters] = React.useState<HabitFilter[]>([]);
      const [period, setPeriod] = React.useState<Period>('day');
      const [habits, setHabits] = React.useState<any>();
      const [observation, setObservation] = React.useState<any>();
      const [loading, setLoading] = React.useState(true);
      const [selectedDate, setSelectedDate] = React.useState(moment());
      const { registerDashboardWidget } = React.useContext(DashboardContext);

      const installHabitFilter = (filter: HabitFilter) => {
        setHabitFilters(prev => {
          const existing = prev.find(_prevFilter => _prevFilter.name === filter.name);
          if (!existing) {
            return [...prev, filter];
          }
          return prev;
        });
      }

      React.useEffect(() => {
        refreshData();
      }, [period, selectedDate]);

      const refreshData = async () => {

        setLoading(true);

        //  Get the Current Date
        const currentDayStr = selectedDate.format('dddd').toLowerCase();

        const isHabitEntity = (entity: NounServiceInstanceInternal<Entity>) => {
          return (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === this.habitModelId);
        };

        const isObservationEntity = (entity: NounServiceInstanceInternal<Entity>) => {
          return (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === this.habitObservationModelId);
        };

        //  Get Habits
        //  TODO:  Actually use a query so we don't process EVERYTHING.
        const entities = await entity2.entityService.entityNounService.search({});
        const habits = entities.filter(entity => isHabitEntity(entity)) as NounServiceInstanceInternal<HabitEntity>[];

        //  Filter Disabled
        //  TODO:  NEED to be able to INJECT this feature from a thing mm!!  It should define the change to the Habit Model (perhaps add it's own) and freaking... inject the necessary logic.  Instructions for the person to change things in the other house!
        //  TODO:  Feature to 'Show Disabled'.  I DO want to be able to make this ENTIRE feature on the fly.  Like... say 'if a habit is disabled, then don't show it.'  The SYSTEM should be able to interpet that there's a PATTERN for 'disabled', and it should be able to PROJECT its knowkee of that pattern and then be bale to HIDE it by either disableing in the UI or fitering etc hmm..
        const enabledHabits = habits; //habits.filter(habit => !habit.payload.disabled);

        console.log("Habit Length: " + enabledHabits.length);
        console.log("Habit JSON: " + JSON.stringify(enabledHabits));

        //  Filter by Period
        //  TODO:  Do as part of the search, AND do it with PROJECTION!  This is an EXPLICIT mapping from which ENGLISH has a freakign realization!  Shouldn't need to do it so expliclty!
        const periodHabits = enabledHabits.filter(habit => {

          //  NOTE:  The 'Current Date' will represent the START of the period.  But, we don't want to select a period like 'Monday'.  We COULD do that, bu it's a weird UI.  Instead, we'll just pick the day, and if it IS that period, then we show.  Because, we treat it as a SUBSET of the 'Day' selection.  Effectively it's a SCOPED day selection.  One that is sensitive to the INSTANCE, where others are not!
          //  NOTE:  Basically we're selecting a DAY.  The idea is... if the selection matches the habit period, then we show!

          //  Habit Period 
          //  Selected Period

          //  Handle Habits with a SPECIFIC Day (subset of 'day' period selection)
          if (period === 'day') {
            const habitPeriod = habit.payload.value.period;

            //  Daily Habit
            if (habitPeriod === 'day') { return true; }

            //  Specific Date Habit
            return selectedDate.format('dddd').toLowerCase() === habit.payload.value.period;
          }

          //  Return Habits which match the selected period
          return habit.payload.value.period === period
        });

        //  Get the Current Observation
        //  FOR NOW:  Get ALL the HabitObservations and sort to get the most recent.  That's the one we'll use.  In the future, we should let the dev-user specify which observation to pull?  MAYBE this component is not knowledgeable of how it's getting its HabitObservation?
        //  TODO-SOON:  We REALLY just need to query for the observation in the given period (only one will be permitted).
        const habitObservations = entities.filter(entity => isObservationEntity(entity)) as NounServiceInstanceInternal<ObservationEntity>[];

        //  Sort the Observations
        //  TODO-SOON:  Check the ordering of this sort.
        //  NOTE:  If it's less than 0, A goes down in index.  If it's greater than 0, A goes up in index.
        const observations = habitObservations.sort((a, b) => new Date(b.payload.value.startTime).getTime() - new Date(a.payload.value.startTime).getTime());

        //  Check Current Period
        //  FOR NOW:  Assume the current period is the DAY.
        //  TODO:  SHOULD have an easy way to manipulate this list of 'Dated Things' and filter to 'Today', which is PERHAPS defiend elsewhere and brought in hm!
        //  NOW, all we need to do is get the current time.  If it's within the buffer range, we still consider it yesturday hm!

        // const hour = currentTime.hours();
        //  TODO:  Inject a way to SELECT a date.  PERHAPS be able to select a time period too, THOUGH that may require aditional ENCODING DIFFERENTIAOTOIN HM!
        // const selectedDateString = currentTime.toDate().toDateString();

        const isObsInPeriod = (observation: NounServiceInstanceInternal<ObservationEntity>) => {

          if (observation.payload.value.period !== period) { return false; }
          const observationDate = moment(observation.payload.value.startTime);

          //  Handle Fornights (Period of 2 Weeks)
          if (period === 'fortnight') {
            const observationFortnight = observationDate.week() / 2;
            const currentFortnight = selectedDate.week() / 2;
            if (observationFortnight === currentFortnight) {
              return true;
            } else {
              return false;
            }
          }

          //  Handle Specific Dates
          if (period === 'monday' || period === 'tuesday' || period === 'wednesday' || period === 'thursday' || period === 'friday' || period === 'saturday' || period === 'sunday') {
            const isMatch = selectedDate.format('dddd').toLowerCase() === period;
            return isMatch;
          }


          //  Handle Weekdays
          if (period === 'weekday') {
            const isWeekday = currentDayStr === 'monday' || currentDayStr === 'tuesday' || currentDayStr === 'wednesday' || currentDayStr === 'thursday' || currentDayStr === 'friday';
            return isWeekday;
          }

          //  Handle Weekends
          if (period === 'weekend') {
            const isWeekend = currentDayStr === 'saturday' || currentDayStr === 'sunday';
            return isWeekend;
          }


          const isMatch = selectedDate.isSame(observationDate, period);
          return isMatch;
        }

        const matching: any[] = [];
        observations.forEach(obs => {
          if (isObsInPeriod(obs)) { matching.push(obs); }
        })
        console.log("Total Observations for period: " + matching.length);

        //  Find the Matching Observation
        const currentObservation = observations.find(observation => {
          return isObsInPeriod(observation);
        });

        console.log("Updated Current Observation: " + JSON.stringify(currentObservation));

        setHabits(periodHabits);
        setObservation(currentObservation);
        setSelectedDate(selectedDate);
        setLoading(false);
      }

      const aaroContext = React.useContext(AaroContext);

      React.useEffect(() => {

        console.log("Habit Extension:  Installling Extension");

        registerDashboardWidget({
          name: "Habit Dashboard Widget",
          description: "Shows Habit Info",
          component: HabitDashboardWidget,
          pluginId: HabitPlugin.details.id
        });

        aaroContext.installRoute({
          name: 'Habits',
          icon: {
            name: "clipboard",
            type: "feather"
          },
          emoji: "📋",
          logo: require("../../../assets/stickers/habits.png"),
          component: HabitHome
        });

        refreshData();

      }, []);

      return (
        <HabitContext.Provider value={{ habitPlugin: this, habitFilters, installHabitFilter, period, setPeriod, habits, observation, loading, refreshData, selectedDate, setSelectedDate }}>
          <HOCRegister id="habit">
            {children}
          </HOCRegister>
        </HabitContext.Provider>
      );

    });
    return this;
  }
}


