import { mockComponent } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import { HaborCircleComponent, HaborCircleComponentPrimitive } from './habor-components/circle-component';
import { HaborColumnPrimitive } from './habor-components/column-component';
import { ChickletHaborComponent, ChickletHaborPrimitive, HaborEmbeddedNamedObjectView, HaborEmbeddedStatusView } from './habor-components/embedded-views/chicklet-component';
import { EventsPage } from './habor-components/events-page';
import { HaborGridComponent, HaborGridComponentPrimitive } from './habor-components/grid-component';
import { HaborCreateInstance, HaborCreateInstancePrimitive } from './habor-components/habor-create-instance';
import { HaborIconButtonPrimitive } from './habor-components/icon-button-component';
import { HaborRowPrimitive } from './habor-components/row-component';
import { SliderHaborComponent, SliderHaborPrimitive } from './habor-components/slider-view';
import { TestSubView } from './habor-components/test-sub-view';
import { TestView } from './habor-components/test-view';

/**
 * The "Primitives" Plugin is a library of primitive Habor Components.
 */
export class PrimitivesPlugin extends CorePluginClass {
  public static details = {
    name: "Primitives",
    description: "Primitives Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "primitives"
  }

  public install = (program: Program, { hessia }: { hessia: HessiaPlugin }) => {

    //  Register Primitive Components
    hessia.registerPrimitiveComponent('TestView', TestView);
    hessia.registerPrimitiveComponent('TestSubView', TestSubView);
    hessia.registerPrimitiveComponent('HaborColumnPrimitive', HaborColumnPrimitive);
    hessia.registerPrimitiveComponent('HaborRowPrimitive', HaborRowPrimitive);
    hessia.registerPrimitiveComponent('HaborIconButtonPrimitive', HaborIconButtonPrimitive);
    hessia.registerPrimitiveComponent('ChickletHaborPrimitive', ChickletHaborPrimitive);
    hessia.registerPrimitiveComponent('HaborGridComponentPrimitive', HaborGridComponentPrimitive);
    hessia.registerPrimitiveComponent('HaborCircleComponentPrimitive', HaborCircleComponentPrimitive);
    hessia.registerPrimitiveComponent('HaborCreateInstancePrimitive', HaborCreateInstancePrimitive);
    hessia.registerPrimitiveComponent('SliderHaborPrimitive', SliderHaborPrimitive);


    //  Register Habor Components
    hessia.registerComponent(mockComponent);
    hessia.registerComponent(HaborEmbeddedStatusView);
    hessia.registerComponent(HaborEmbeddedNamedObjectView);
    hessia.registerComponent(ChickletHaborComponent);
    hessia.registerComponent(HaborGridComponent);
    hessia.registerComponent(HaborCircleComponent);
    hessia.registerComponent(EventsPage);
    hessia.registerComponent(HaborCreateInstance);
    hessia.registerComponent(SliderHaborComponent);
  }
}
