import * as React from 'react';
import { HaborContainer, PrimitiveProps } from "../../component-plugin/habor-react/habor-component-lib";
import { ViewStyle } from "react-native";

//
//  Column Component
//

export interface ColumnProps extends PrimitiveProps { userProps: { style: ViewStyle } }
export interface ColumnState {}

export const HaborColumnPrimitive = ({ userProps: { style }, frameworkProps }: PrimitiveProps) => {
  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flexDirection: 'column', flex: 1, ...style }}>
      { frameworkProps.children }
    </HaborContainer>
  );
};
